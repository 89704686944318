

















































import { defineComponent, ref, watch, SetupContext } from "@vue/composition-api";
import Axios from "@/plugins/axios";

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  name: "DiffStocks",
  setup(props, context: SetupContext) {
    const axios = Axios;
    const hashParams = /#(\d+),(\d+)/.exec(context.root.$route.hash);

    const inputPharmacyId = ref(hashParams ? hashParams[1] : "");
    const pharmacy = ref<any>(null);
    const inputStockableItemId = ref(hashParams ? hashParams[2] : "");
    const stockableItem = ref<any>(null);
    const stockV2 = ref<any>(null);
    const stockV1 = ref<any>(null);
    const stockLogDiffs = ref<any[]>([]);

    async function fetchStockLogs() {
      if (!pharmacy.value || !stockableItem.value) {
        return [];
      }
      const res = await axios.get(`/api/v2/medorder/pharmacy/pharmacies/${pharmacy.value.id}/stocks/${stockableItem.value.id}/logs`);
      const stockLogs: any[] = res.data;
      const resStock = await axios.get(`/api/v2/medorder/pharmacy/pharmacies/${pharmacy.value.id}/stocks/${stockableItem.value.id}`);
      stockV2.value = resStock.data;

      const reqParams = {
        pharmacyId: pharmacy.value.v1_id,
        yjCode: stockableItem.value.yj_code,
      };
      const responseV1 = await axios.get("/api/v1/medorder/fifo-racks", { params: reqParams });
      let fifoRacks: any[] = responseV1.data.fifoRacks;
      let pageToken = responseV1.data.nextPageToken;
      while (pageToken) {
        const reqParamsMore = {
          pharmacyId: pharmacy.value.v1_id,
          yjCode: stockableItem.value.yj_code,
          pageToken,
        };
        const resV1More = await axios.get("/api/v1/medorder/fifo-racks", { params: reqParamsMore });
        pageToken = resV1More.data.nextPageToken;
        Array.prototype.push.apply(fifoRacks, resV1More.data.fifoRacks);
      }
      let currentQuantity = 0;
      fifoRacks = fifoRacks
        .reverse()
        .map((fifoRack: any) => {
          if (fifoRack.type === "8") {
            currentQuantity = fifoRack.quantity;
          } else {
            currentQuantity += fifoRack.quantity;
          }
          return {
            ...fifoRack,
            currentQuantity,
            jstExecutedAt: new Date(fifoRack.executedAt + "Z"),
          };
        })
        .reverse();

      const reqStockParams = {
        pharmacyId: pharmacy.value.v1_id,
        yjCodes: stockableItem.value.yj_code,
      };
      const resStockV1 = await axios.get("/api/v1/medorder/stocks", { params: reqStockParams });
      if (resStockV1.data.stocks.length > 0) {
        stockV1.value = resStockV1.data.stocks[0];
      }

      stockLogDiffs.value = [];
      while (stockLogs.length > 0 || fifoRacks.length > 0) {
        if (stockLogs.length === 0) {
          stockLogDiffs.value.push({
            v2: null,
            v1: fifoRacks.shift(),
          });
          continue;
        }
        if (fifoRacks.length === 0) {
          stockLogDiffs.value.push({
            v2: stockLogs.shift(),
            v1: null,
          });
          continue;
        }

        const loggedAt = Date.parse(stockLogs[0].logged_at);
        const executedAt = Date.parse(fifoRacks[0].executedAt + "Z");
        if (loggedAt === executedAt) {
          stockLogDiffs.value.push({
            v2: stockLogs.shift(),
            v1: fifoRacks.shift(),
          });
        } else if (loggedAt > executedAt) {
          stockLogDiffs.value.push({
            v2: stockLogs.shift(),
            v1: null,
          });
        } else {
          stockLogDiffs.value.push({
            v2: null,
            v1: fifoRacks.shift(),
          });
        }
      }
    }

    async function fetchPharmacy() {
      const pharmacyId = parseInt(inputPharmacyId.value, 10);
      if (!pharmacyId) {
        return null;
      }
      const response = await axios.get(`/api/v2/core/pharmacy/pharmacies/${pharmacyId}`);
      pharmacy.value = response.data;

      fetchStockLogs();
    }

    async function fetchStockableItem() {
      const stockableItemId = parseInt(inputStockableItemId.value, 10);
      if (!stockableItemId) {
        return null;
      }
      const response = await axios.get(`/api/v2/medorder/master/stockable_items/${stockableItemId}`);
      stockableItem.value = response.data;

      fetchStockLogs();
    }

    watch(inputPharmacyId, () => {
      fetchPharmacy();
    });
    watch(inputStockableItemId, () => {
      fetchStockableItem();
    });

    function displayAction(actionOrType: any) {
      switch (actionOrType) {
        case "dispense":
        case "1":
          return "調剤";
        case "purchase":
        case "2":
          return "購入";
        case "subdivision_buy":
        case "subdivision_sell":
        case "6":
          return "小分け";
        case "stocktaking":
        case "8":
          return "棚卸し";
        case "fix_quantity":
        case "3":
          return "在庫調整";
        case "discard":
        case "4":
          return "廃棄";
        default:
          return actionOrType;
      }
    }

    async function addFixQuantity(fifoRack: any) {
      const data = {
        logged_at: fifoRack.jstExecutedAt.toISOString(),
        stock_action: "fix_quantity",
        delta_quantity: String(fifoRack.currentQuantity),
      };
      await axios.post(`/api/v2/medorder/pharmacy/pharmacies/${inputPharmacyId.value}/stocks/${inputStockableItemId.value}/logs`, data);
      alert("追加しました");
    }

    return {
      inputPharmacyId,
      pharmacy,
      inputStockableItemId,
      stockableItem,
      stockV2,
      stockV1,
      stockLogDiffs,
      displayAction,
      addFixQuantity,
    };
  },
});
