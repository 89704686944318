


























import axios from "@/plugins/axios";
import { readFiles } from "@/utils/file";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  name: "PharmacyLicenseUploadModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    pharmacyId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref<boolean>(false);
    const errors = ref<Array<string>>([]);
    const number = ref<string>(null);
    const file = ref<File | undefined>(null);
    const expires_on = ref<Date>(null);
    const today = DateTime.local().toSQLDate();

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const shouldDisableSubmitButton = computed(() => isLoading.value || !number.value || !file.value || !expires_on.value);

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function changeFile(e: Event) {
      file.value = (await readFiles(e))?.item(0);
    }

    async function submit() {
      errors.value = [];

      try {
        isLoading.value = true;
        if (!file.value) return;

        const res = await axios.get(`/api/v2/core/admin/pharmacies/${props.pharmacyId}/licenses/presigned_url`);
        const { presigned_url, object_name } = res.data;
        await axios.put(presigned_url, file.value, { headers: { "content-type": file.value.type } });

        const url = `/api/v2/core/admin/pharmacies/${props.pharmacyId}/licenses`;
        await axios.post(url, {
          number: number.value,
          expires_on: expires_on.value,
          filename_s3: object_name,
        });
        hideModal();
      } catch (e) {
        errors.value = ["メッセージ送信でエラーが発生しました。"];
        throw e;
      } finally {
        isLoading.value = false;
      }
    }

    return {
      isLoading,
      errors,
      number,
      file,
      expires_on,
      today,
      propsOpenModal,
      shouldDisableSubmitButton,

      changeFile,
      submit,
      hideModal,
    };
  },
});
