import { SetupContext, computed } from "@vue/composition-api";
import { AxiosResponse } from "axios";

import { StoreItem, toStoreItems } from "@/store/utils";
import { StoreActionFindListState, actionFindListCommitAddItem } from "@/store/actions/createActionFindList";

interface StoreActionCreateState<T extends StoreItem> {
  isCreating: boolean;
}

interface RequstFunc<P> {
  (context: SetupContext, params: P): Promise<AxiosResponse>;
}

interface Options<T extends StoreItem, P> {
  findListState?: StoreActionFindListState<T>;
  afterEndCreate?: (item: T, params: P) => void;
}

export function initStoreActionCreateState<T extends StoreItem>() {
  return {
    isCreating: false,
  };
}

function commitStartCreate<T extends StoreItem>(state: StoreActionCreateState<T>) {
  state.isCreating = true;
}
function commitEndCreate<T extends StoreItem>(state: StoreActionCreateState<T>) {
  state.isCreating = false;
}

async function createBase<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionCreateState<T>,
  params: P,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  // TODO: 二重リクエスト防止
  commitStartCreate(state);
  let response = null;
  try {
    response = await requestFunc(context, params);
  } finally {
    commitEndCreate(state);
  }
  const item = toStoreItems(response.data) as T;

  if (options) {
    if (options.findListState) {
      actionFindListCommitAddItem(options.findListState, item);
    }
    if (options.afterEndCreate) {
      options.afterEndCreate(item, params);
    }
  }

  return item;
}

export function createActionCreate<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionCreateState<T>,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  async function create(params: P) {
    return createBase(context, state, params, requestFunc, options);
  }

  const isCreating = computed(() => {
    return state.isCreating;
  });

  return {
    create,
    isCreating,
  };
}
