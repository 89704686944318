

























import { CTextarea } from "@coreui/vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import axios from "@/plugins/axios";
import { ChatMessageForm } from "@/models/lib/vue-advanced-chat/chatMessageForm";

export default defineComponent({
  name: "InquiryMessageModal",
  props: {
    message: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "send", // possibly ["send", "edit"]
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const contentRef = ref<InstanceType<typeof CTextarea>>();

    const content = ref<string | null | undefined>(null);
    const isLoading = ref<boolean>(false);
    const errorMessages = ref<Array<string>>([]);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const shouldDisableSubmitButton = computed(() => !content.value || isLoading.value);

    watch(content, () => {
      // 入力しているメッセージが変更されたら、エラーメッセージはクリアする
      errorMessages.value = [];
    });
    watch(propsOpenModal, (value: boolean) => {
      if (value) {
        // 開く時に props で上書きする
        content.value = props.message?.content || props.message?.newContent;
        setTimeout(() => contentRef.value?.$el.getElementsByTagName("textarea")[0].focus(), 100);
      } else {
        // 閉じるときに初期化する
        isLoading.value = false;
        errorMessages.value = [];
      }
    });

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function sendMessage() {
      if (!props.roomId || !content) return;
      const url = `/api/v2/core/admin/inquiry_rooms/${props.roomId}/messages`;
      const data: ChatMessageForm = { content: content.value, reply_message_id: props.message?.replyMessage?.id };

      const file = props.message?.file;
      if (file && file.blob) {
        const res = await axios.get("/api/v2/core/admin/inquiry_messages/file_presigned_url");
        const { presigned_url, object_name } = res.data;
        await axios.put(presigned_url, file.blob, { headers: { "content-type": file.type } });

        data.file = { file_name_s3: object_name, name: file.name, size: file.size, file_type: file.type, extension: file.extension };
      }

      await axios.post(url, data);
      hideModal();
    }

    async function editMessage() {
      if (!props.message?.messageId || !content) return;
      const url = `/api/v2/core/admin/inquiry_messages/${props.message?.messageId}`;
      const data: ChatMessageForm = { content: content.value };

      await axios.patch(url, data);
      hideModal();
    }

    async function submitMessage() {
      errorMessages.value = [];

      try {
        isLoading.value = true;
        switch (props.mode) {
          case "send":
            await sendMessage();
            break;
          case "edit":
            await editMessage();
            break;
        }
      } catch (e) {
        console.log(e);
        errorMessages.value = ["メッセージ送信でエラーが発生しました。"];
      } finally {
        isLoading.value = false;
      }
    }

    return {
      contentRef,

      content,
      isLoading,
      errorMessages,
      propsOpenModal,
      shouldDisableSubmitButton,

      submitMessage,
      hideModal,
    };
  },
});
