



































import { PharmacyLicense } from "@/models/PharmacyLicens";
import PharmacyLicenseUploadModal from "@/views/v2/core/pharmacies/PharmacyLicenseUploadModal.vue";
import { defineComponent, ref, watch } from "@vue/composition-api";
import DeleteConfirmationModal from "@/views/shared/DeleteConfirmationModal.vue";
import axios from "@/plugins/axios";

export default defineComponent({
  name: "PharmacyLicenses",
  components: { DeleteConfirmationModal, PharmacyLicenseUploadModal },
  setup(props, context) {
    const isLoading = ref<boolean>(true);
    const isOpenUploadModal = ref<boolean>(false);
    const isOpenDeleteModal = ref<boolean>(false);
    const licenses = ref<Array<PharmacyLicense>>([]);
    let deletingLicenseId: number | null = null;

    const fields = ["id", "active", "number", "expires_on", "created_at", "actions"];
    const pharmacyId = context.root.$route.params.id;

    async function fetchLicenses() {
      if (!pharmacyId) return;
      try {
        isLoading.value = true;
        licenses.value = (await axios.get(`/api/v2/core/admin/pharmacies/${pharmacyId}/licenses`)).data;
      } finally {
        isLoading.value = false;
      }
    }

    function openUploadModal() {
      isOpenUploadModal.value = true;
    }

    function openDeleteModal(licenseId: number) {
      deletingLicenseId = licenseId;
      isOpenDeleteModal.value = true;
    }

    async function doDelete() {
      try {
        isLoading.value = true;

        await axios.delete(`/api/v2/core/admin/pharmacies/${pharmacyId}/licenses/${deletingLicenseId}`);
        await fetchLicenses();
        isOpenDeleteModal.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    watch(isOpenUploadModal, (value: boolean) => {
      // 画面を開いた時と、モーダルを閉じた時に更新
      if (!value) fetchLicenses();
    });

    return {
      isLoading,
      isOpenUploadModal,
      isOpenDeleteModal,
      licenses,
      fields,
      pharmacyId,
      openUploadModal,
      openDeleteModal,
      doDelete,
    };
  },
});
