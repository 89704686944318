import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface MedisOptionRow {
  id: number;
  hotCode: string;
  registerdOn: DateTime;
}

interface MedisOptionRowsFindListParams {
  medisOptionId?: number;
  page?: number;
}

interface MedisOptionRowsFindListRequestParams {
  medis_option_id?: number;
  page?: number;
}

function createMedisOptionRowsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisOptionRow>(),
    ...initStoreActionFindListState<MedisOptionRow>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_option_rows`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisOptionRow, MedisOptionRowsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_option_rows`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisOptionRowsFindListRequestParams = {};
        if (params.medisOptionId) {
          requestParams.medis_option_id = params.medisOptionId;
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisOptionRowsStore, "medisOptionRowsStore");

export const provideMedisOptionRowsStore = provider.provideStore;
export const useMedisOptionRowsStore = provider.useStore;

export function useMedisOptionRow(medisOptionRowId: number, withFetch?: boolean, noCache?: boolean) {
  const medisOptionRowsStore = useMedisOptionRowsStore();
  if (withFetch) {
    medisOptionRowsStore.fetch(medisOptionRowId, noCache);
  }
  let medisOptionRow: MedisOptionRow | null = null;

  return computed(() => {
    if (medisOptionRow && !noCache) {
      return medisOptionRow;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisOptionRowsStore.isFetching.value;
    medisOptionRow = medisOptionRowsStore.get(medisOptionRowId);
    return medisOptionRow;
  });
}
