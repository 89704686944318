














import { defineComponent } from "@vue/composition-api";

import { MedhotRow } from "@/store/medhotRowsStore";

interface Props {
  medhotRows: MedhotRow[];
  loading: boolean;
}

export default defineComponent<Props>({
  name: "MedhotRowDataTable",
  props: {
    medhotRows: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const fields = [
      { key: "id", label: "ID" },
      { key: "registerType", label: "更新区分" },
      { key: "registeredOn", label: "更新年月日" },
      { key: "brandName", label: "販売名" },
      { key: "publicNotice", label: "告示分類" },
      { key: "mhlwCode", label: "薬価コード" },
      { key: "officialRegisteredDate", label: "告示日" },
      { key: "interimPeriodOn", label: "経過措置日" },
      { key: "unitType", label: "剤形" },
      { key: "biotic", label: "生物由来製品区分" },
      { key: "drugAndOthers", label: "麻薬製剤等" },
      { key: "packetForm", label: "包装形態" },
      { key: "packetVolume", label: "包装単位数" },
      { key: "unit", label: "包装単位数単位" },
      { key: "packageVolume", label: "１連の包装数" },
      { key: "packetPerSheet", label: "包装形態" },
      { key: "packetGs1Code", label: "調剤包装単位コード" },
      { key: "packetFormSubdivision", label: "調剤包装単位細区分" },
      { key: "packageGs1Code", label: "販売包装単位コード" },
      { key: "supplyGs1Code", label: "元梱包装単位コード" },
      { key: "janCode", label: "物流用ＪＡＮコード" },
      { key: "discontinuedOn", label: "販売中止年月日" },
    ];

    return {
      fields,
    };
  },
});
