<template>
  <div>
    <CCard>
      <CCardBody>
        <SearchPharmacy :selected.sync="selectedPharmacy" :api-fields="apiFields" :filter="filterOutBelongingCompany" />

        <CDataTable hover bordered fixed :items="listingPharmacies" :fields="fields" :loading="isLoading">
          <template #name="{item : pharmacy}">
            <td>
              <CLink :to="{ name: '薬局情報', params: { id: pharmacy.id } }">{{ pharmacy.name }}</CLink>
            </td>
          </template>
          <template #status="{item : pharmacy}">
            <td><span v-if="pharmacy.company_id">所属中</span></td>
          </template>
          <template #actions="{item : pharmacy}">
            <td>
              <CButton v-if="pharmacy.company_id" color="danger" class="mr-1 mb-1" size="sm" @click="removePharmacy(pharmacy.id)">
                削除
              </CButton>
              <CButton v-else color="primary" class="mr-1 mb-1" size="sm" @click="addPharmacy(pharmacy.id)">
                追加
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SearchPharmacy from "src/components/shared/search/SearchPharmacy";

export default {
  name: "CompanyPharmacies",
  components: { SearchPharmacy },
  data() {
    return {
      id: null,
      company: {},
      listingPharmacies: [], // テーブル表示用の一時的な薬局一覧
      selectedPharmacy: null,
      apiFields: ["id", "name", "micode", "address1", "created_at"],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      return this.apiFields.concat(["status", "actions"]);
    },
  },
  watch: {
    selectedPharmacy() {
      this.listingPharmacies = [this.selectedPharmacy].concat(this.listingPharmacies);
      this.listingPharmacies = this.listingPharmacies.filter((p, i, array) => array.map((x) => x.id).indexOf(p.id) === i);
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchCompany();
    this.listingPharmacies = this.company.pharmacies;
  },
  methods: {
    async fetchCompany() {
      if (this.isLoading) return;

      this.isLoading = true;
      if (this.id) this.company = (await this.$axios.get(`/api/v2/core/admin/companies/${this.id}`)).data;
      this.isLoading = false;
    },
    async addPharmacy(pharmacyId) {
      const req_params = { pharmacy_ids: [pharmacyId] };
      await this.$axios.patch(`/api/v2/core/admin/companies/${this.id}/pharmacies`, req_params);
      this.listingPharmacies.find((p) => p.id === pharmacyId).company_id = this.id;
      await this.fetchCompany();
    },
    async removePharmacy(pharmacyId) {
      const req_params = { pharmacy_ids: [pharmacyId] };
      await this.$axios.delete(`/api/v2/core/admin/companies/${this.id}/pharmacies`, { data: req_params });
      this.listingPharmacies.find((p) => p.id === pharmacyId).company_id = null;
      await this.fetchCompany();
    },
    filterOutBelongingCompany(pharmacy) {
      return pharmacy.company_id === null;
    },
  },
};
</script>
