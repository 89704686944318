export class Manual {
  id!: number;
  title!: string;
  title_short!: string;
  content!: string;
  manual_category_id?: number;
  impressions_count?: number;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }
}
