<template>
  <MenuLink :menus="['薬局一覧', 'ユーザ一覧', '薬局会社一覧', '近隣グループ一覧', '卸会社一覧', '医療用医薬品一覧']" />
</template>

<script>
import MenuLink from "src/components/shared/menu/MenuLink";

export default {
  name: "Core",
  components: { MenuLink },
};
</script>
