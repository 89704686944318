




























































































import { computed, defineComponent } from "@vue/composition-api";

import { useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";
import { useOrderableItemImportsStore } from "@/store/orderableItemImportsStore";

import OrderableItemImport from "@/components/masterSources/OrderableItemImport.vue";

export default defineComponent({
  name: "OrderableItemFixtureDetails",
  components: {
    OrderableItemImport,
  },
  setup(props, context) {
    const orderableItemFixtureId = parseInt(context.root.$route.params.id, 10);

    const orderableItemFixturesStore = useOrderableItemFixturesStore();
    const orderableItemImportsStore = useOrderableItemImportsStore();

    orderableItemFixturesStore.fetch(orderableItemFixtureId);
    orderableItemImportsStore.fetchByOrderableItemFixtureId(orderableItemFixtureId);

    const orderableItemFixture = computed(() => {
      orderableItemFixturesStore.isFetching.value;
      return orderableItemFixturesStore.get(orderableItemFixtureId);
    });

    const orderableItemImport = computed(() => {
      orderableItemImportsStore.isFetching.value;
      return orderableItemImportsStore.getByOrderableItemFixtureId(orderableItemFixtureId);
    });

    async function importOrderableItem() {
      orderableItemImportsStore.create({
        orderableItemFixtureId,
      });
      context.root.$router.push({ name: "発注医薬品マスター手動登録" });
    }

    const hasDiff = computed(() => {
      if (!orderableItemImport.value) {
        return false;
      }
      return orderableItemImport.value.operation !== "none";
    });

    return {
      orderableItemFixture,
      orderableItemImport,
      importOrderableItem,
      hasDiff,
    };
  },
});
