<template>
  <CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <CButton :color="buttonColor">{{ currentEnv }}</CButton>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>環境を切り替える</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="env in environments" :key="env" :href="csPortalUrl(env)">
      {{ envNameJp(env) }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import envNameJp from "@/utility/envNameJp";

export default {
  name: "TheHeaderDropdownEnvironments",
  data() {
    return {
      currentEnv: process.env.VUE_APP_ENV,
      environments: ["production", "demo"],
    };
  },
  computed: {
    buttonColor() {
      switch (this.currentEnv) {
        case "production":
          return "danger";
        default:
          return "primary";
      }
    },
  },
  methods: {
    csPortalUrl(env) {
      switch (env) {
        case "production":
          return "https://admin.core.pharmacloud.jp/";
        default:
          return `https://admin.core.${env}.pharmacloud.tk/`;
      }
    },
    envNameJp(env) {
      return envNameJp(env);
    },
  },
};
</script>
