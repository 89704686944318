import { computed, onMounted, onUnmounted } from "@vue/composition-api";
import { AxiosStatic, AxiosRequestConfig } from "axios";

import { equalsParams } from "@/utils";
import { toStoreItems, StoreItem } from "@/store/utils";

const NextPageEventType = "scroll";

interface FilterItem<T extends StoreItem> {
  (item: T): boolean;
}

interface CompareFunction<T extends StoreItem> {
  (a: T, b: T): number;
}

interface AfterReciveList<T extends StoreItem, P> {
  (list: T[], params: P): Promise<void>;
}

export interface StoreActionFindListState<T extends StoreItem> {
  list: T[];
  isFindingList: boolean;
  filterItem: FilterItem<T> | null;
  currentPage: number;
  totalCount: number;
  totalPages: number;
  findRequestNumber: 0;
  compareFunction: CompareFunction<T> | null;
}

interface Options<T extends StoreItem, P> {
  afterReciveList?: (list: T[], params: P) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createRequestParams?: (params: P) => any;
  createFilterItem?: (params: P) => FilterItem<T>;
}

interface FindListOptions<T extends StoreItem, P> {
  beforeRequest?: () => void;
  afterReciveList?: AfterReciveList<T, P>;
  useCache?: boolean;
  page?: number;
}

export function initStoreActionFindListState<T extends StoreItem>(compareFunction?: CompareFunction<T>): StoreActionFindListState<T> {
  return {
    list: [] as T[],
    filterItem: null,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
    isFindingList: false,
    findRequestNumber: 0,
    compareFunction: compareFunction || null,
  };
}

function commitStartFindList<T extends StoreItem>(state: StoreActionFindListState<T>, isNextPage: boolean) {
  if (!isNextPage) {
    state.list = [];
  }
  state.isFindingList = true;
  ++state.findRequestNumber;
  return state.findRequestNumber;
}

function commitEndFindList<T extends StoreItem>(
  requestNumber: number,
  state: StoreActionFindListState<T>,
  items: T[],
  currentPage: number,
  totalCount: number,
  totalPages: number,
  isNextPage: boolean
) {
  if (requestNumber !== state.findRequestNumber) {
    return;
  }
  if (isNextPage) {
    state.list = state.list.concat(items);
  } else {
    state.list = items;
  }
  state.currentPage = currentPage;
  state.totalCount = totalCount;
  state.totalPages = totalPages;
  state.isFindingList = false;
}

export function commitReplaceItems<T extends StoreItem>(state: StoreActionFindListState<T>, items: T[]) {
  for (const replaceItem of items) {
    const index = state.list.findIndex((item) => {
      return item.id === replaceItem.id;
    });
    if (index !== -1) {
      state.list.splice(index, 1, replaceItem);
    }
  }
  if (state.compareFunction) {
    state.list.sort(state.compareFunction);
  }
}

export function actionFindListCommitAddItem<T extends StoreItem>(state: StoreActionFindListState<T>, item: T) {
  state.list.unshift(item);
  if (state.compareFunction) {
    state.list.sort(state.compareFunction);
  }
}

export function actionFindListCommitUpdateItems<T extends StoreItem>(state: StoreActionFindListState<T>, items: T[]) {
  for (const replaceItem of items) {
    const index = state.list.findIndex((item) => {
      return item.id === replaceItem.id;
    });
    if (index === -1) {
      continue;
    }

    if (state.filterItem && !state.filterItem(replaceItem)) {
      state.list.splice(index, 1);
    } else {
      state.list.splice(index, 1, replaceItem);
    }
  }
  if (state.compareFunction) {
    state.list.sort(state.compareFunction);
  }
}

export function actionFindListCommitDeleteItem<T extends StoreItem>(state: StoreActionFindListState<T>, id: number) {
  const index = state.list.findIndex((item) => {
    return item.id === id;
  });
  if (index === -1) {
    return;
  }
  state.list.splice(index, 1);
}

async function findListWithoutStoreBase<T extends StoreItem, P>(
  params: P,
  httpClient: AxiosStatic,
  createUrl: (params: P) => string,
  page: number | null,
  options?: Options<T, P>
) {
  // パラメータから URL を生成する
  const url = createUrl(params);

  // パラメータからクエリーパラメータを生成する
  const config: AxiosRequestConfig = {};
  if (options?.createRequestParams) {
    config.params = options.createRequestParams(params);
  }

  // 次のページ読み込みの時はページングのパラメータを追加する。
  if (page !== null) {
    if (!config.params) {
      config.params = {};
    }
    config.params.page = page;
  }

  // リクエストを送信する。
  const response = await httpClient.get(url, config);
  const items = toStoreItems(response.data);

  const resCurrentPage = parseInt(response.headers["x-current-page"], 10) || 0;
  const totalCount = parseInt(response.headers["x-total-count"], 10) || 0;
  const totalPages = parseInt(response.headers["x-total-pages"], 10) || 0;

  return {
    items,
    currentPage: resCurrentPage,
    totalCount,
    totalPages,
  };
}

async function findListBase<T extends StoreItem, P>(
  state: StoreActionFindListState<T>,
  params: P,
  httpClient: AxiosStatic,
  createUrl: (params: P) => string,
  options?: Options<T, P>,
  isNextPage = false,
  afterReciveList?: AfterReciveList<T, P>
) {
  const requestNumber = commitStartFindList(state, isNextPage);
  let items = [];
  try {
    const page = isNextPage ? state.currentPage + 1 : null;
    const result = await findListWithoutStoreBase(params, httpClient, createUrl, page, options);
    items = result.items;

    if (options && options.afterReciveList) {
      await options.afterReciveList(items, params);
    }
    if (afterReciveList) {
      await afterReciveList(items, params);
    }

    commitEndFindList(requestNumber, state, items, result.currentPage, result.totalCount, result.totalPages, isNextPage);
  } catch (e) {
    commitEndFindList(requestNumber, state, [], 0, 0, 0, false);
    throw e;
  }
  return items;
}

function waitMoment() {
  return new Promise((resolve) => {
    setTimeout(resolve, 1);
  });
}

async function useCurrentList<T extends StoreItem>(state: StoreActionFindListState<T>) {
  const list = state.list;
  const currentPage = state.currentPage;
  const totalCount = state.totalCount;
  const totalPages = state.totalPages;

  // 多数のアイテムを保持したまま、画面遷移をさせようとすると。
  // 画面の描画終わるまでページの遷移が行われず、クリックしかわからなくなるので。
  // 一旦空のリストをセットして非同期にすることで、ページの描画の時に
  // ページの遷移だけ行うようにする
  const requestNumber = commitStartFindList(state, false);
  await waitMoment();
  commitEndFindList(requestNumber, state, list, currentPage, totalCount, totalPages, false);
}

function getScrollElement() {
  const elems = document.getElementsByClassName("c-body");
  if (elems.length === 0) {
    return null;
  }
  return elems[0];
}

function addNextPageListener(scrollingElem: Element | null, listener: () => void) {
  if (!scrollingElem) {
    return null;
  }

  let reached = false;
  let beforeScrollTop = 0;
  const eventListener = () => {
    const scrollTop = scrollingElem.scrollTop;
    const isDown = scrollTop > beforeScrollTop;
    beforeScrollTop = scrollTop;
    if (!isDown) {
      reached = false;
      return;
    }
    const bottomBuffer = scrollingElem.clientHeight * 3; // 残り画面の高さ3つ分になったら、event を発火する。
    const newReached = scrollTop + bottomBuffer > scrollingElem.scrollHeight;
    if (reached === newReached) {
      return;
    }
    reached = newReached;
    if (!reached) {
      return;
    }

    listener();
  };

  scrollingElem.addEventListener(NextPageEventType, eventListener);
  return eventListener;
}

function removeNextPageListener(scrollingElem: Element | null, listener: (() => void) | null) {
  if (!scrollingElem || !listener) {
    return;
  }
  scrollingElem.removeEventListener(NextPageEventType, listener);
}

export function createActionFindList<T extends StoreItem, P>(
  state: StoreActionFindListState<T>,
  httpClient: AxiosStatic,
  createUrl: (params: P) => string,
  options?: Options<T, P>
) {
  let findParams: P;
  let findListAfterReciveList: AfterReciveList<T, P> | undefined;

  async function findListMore(): Promise<T[]> {
    return findListBase(state, findParams, httpClient, createUrl, options, true, findListAfterReciveList);
  }

  async function findList(params: P, findListOptions?: FindListOptions<T, P>): Promise<T[]> {
    let changeParams = false;
    if (!equalsParams(findParams, params)) {
      findParams = params;
      changeParams = true;
    }

    if (options?.createFilterItem) {
      state.filterItem = options?.createFilterItem(findParams);
    }
    findListAfterReciveList = findListOptions && findListOptions.afterReciveList ? findListOptions.afterReciveList : undefined;

    const retItems: T[] = [];
    if (findListOptions?.useCache && !changeParams) {
      // useCache が true で、パラメータが変更されていなかったら、現在の値を使用する。
      await useCurrentList(state);
    } else {
      if (findListOptions?.beforeRequest) {
        findListOptions.beforeRequest();
      }
      const items = await findListBase(state, params, httpClient, createUrl, options, false, findListAfterReciveList);
      Array.prototype.push.apply(retItems, items);
    }

    if (findListOptions?.useCache && findListOptions?.page) {
      while (state.currentPage < findListOptions.page) {
        const items = await findListMore();
        Array.prototype.push.apply(retItems, items);
      }
    }

    return retItems;
  }

  async function findListWithoutStore(params: P): Promise<T[]> {
    let result = await findListWithoutStoreBase(params, httpClient, createUrl, null, options);
    const items = result.items;
    while (result.currentPage < result.totalPages) {
      const page = result.currentPage + 1;
      result = await findListWithoutStoreBase(params, httpClient, createUrl, page, options);
      Array.prototype.push.apply(items, result.items);
    }
    return items;
  }
  function clearList() {
    const requestNumber = commitStartFindList(state, false);
    commitEndFindList(requestNumber, state, [], 0, 0, 0, false);
  }
  const list = computed(() => {
    return state.list;
  });

  const currentPage = computed(() => {
    return state.currentPage;
  });

  const totalPages = computed(() => {
    return state.totalPages;
  });

  const totalCount = computed(() => {
    return state.totalCount;
  });

  const hasMore = computed(() => {
    return state.currentPage < state.totalPages;
  });

  const isFindingList = computed(() => {
    return state.isFindingList;
  });

  /**
   * スクロールによるページ読み込みのためのイベントを設定する
   */
  function setupScrolling() {
    let scrollElement: Element | null = null;
    let listener: (() => void) | null = null;
    onMounted(() => {
      scrollElement = getScrollElement();
      listener = addNextPageListener(scrollElement, () => {
        if (state.isFindingList) {
          return;
        }
        if (state.currentPage >= state.totalPages) {
          return;
        }
        findListMore();
      });
    });
    onUnmounted(() => {
      removeNextPageListener(scrollElement, listener);
    });
  }

  return {
    list,
    findList,
    findListMore,
    findListWithoutStore,
    clearList,
    currentPage,
    totalPages,
    totalCount,
    hasMore,
    isFindingList,
    setupScrolling,
  };
}
