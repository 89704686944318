import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import {
  initStoreActionFetchItemState,
  ActionFetchItemBuilder,
  actionFetchItemCommitReplaceItem,
} from "@/store/actions/createActionFetchItem";
import { fetchBase } from "./actions/createActionFetchBase";
import { initStoreActionFindListState, createActionFindList } from "./actions/createActionFindList";
import { createActionCreate, initStoreActionCreateState } from "./actions/createActionCreate";
import { createActionPatch, initStoreActionPatchState } from "./actions/createActionPatch";

export interface OrderableItemFixture {
  id: number;
  name: string | null;
  nameAppend: string | null;
  gs1Code: string | null;
  janCode: string | null;
  stockableItemFixtureId: number | null;
  quantity: number | null;
  makerName: string | null;
  sellerName: string | null;
  packetForm: string | null;
  packetVolume: number | null;
  packagePacketCount: number | null;
  inactiveOn: DateTime | null;
  hotCode: string | null;
  note: string | null;
}

export interface OrderableItemFixturesFindListParams {
  gs1Code?: string;
  janCode?: string;
  page?: number;
}

interface OrderableItemFixturesFindListRequestParams {
  gs1_code?: string;
  jan_code?: string;
  page?: number;
}

export interface OrderableItemFixturesCreateParams {
  name?: string;
  nameAppend?: string;
  gs1Code?: string;
  janCode?: string;
  stockableItemFixtureId?: number;
  quantity?: number;
  makerName?: string;
  sellerName?: string;
  packetForm?: string;
  packetVolume?: number;
  packagePacketCount?: number;
  inactiveOn?: string;
  hotCode?: string;
  note?: string;
}

interface OrderableItemFixturesCreateRequestData {
  name?: string;
  name_append?: string;
  gs1_code?: string;
  jan_code?: string;
  stockable_item_fixture_id?: number;
  quantity?: number;
  maker_name?: string;
  seller_name?: string;
  packet_form?: string;
  packet_volume?: number;
  package_packet_count?: number;
  inactive_on?: string;
  hot_code?: string;
  note?: string;
}

export interface OrderableItemFixturesPatchParams {
  id: number;
  name?: string | null;
  nameAppend?: string | null;
  gs1Code?: string | null;
  janCode?: string | null;
  stockableItemFixtureId?: number | null;
  quantity?: number | null;
  makerName?: string | null;
  sellerName?: string | null;
  packetForm?: string | null;
  packetVolume?: number | null;
  packagePacketCount?: number | null;
  inactiveOn?: string | null;
  hotCode?: string | null;
  note?: string | null;
}

export interface OrderableItemFixturesPatchRequestData {
  name?: string | null;
  name_append?: string | null;
  gs1_code?: string | null;
  jan_code?: string | null;
  stockable_item_fixture_id?: number | null;
  quantity?: number | null;
  maker_name?: string | null;
  seller_name?: string | null;
  packet_form?: string | null;
  packet_volume?: number | null;
  package_packet_count?: number | null;
  inactive_on?: string | null;
  hot_code?: string | null;
  note?: string | null;
}

function createOrderableItemFixturesStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<OrderableItemFixture>(),
    ...initStoreActionFindListState<OrderableItemFixture>(),
    ...initStoreActionCreateState(),
    ...initStoreActionPatchState(),
    gs1CodeItemMap: new Map<string, OrderableItemFixture>(),
    hotCodeItemMap: new Map<string, OrderableItemFixture>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/orderable_item_fixtures`;
  });
  const actionFetch = actionFetchBuilder.build();

  async function fetchByGs1Code(gs1Code: string | string[], force?: boolean) {
    return fetchBase<OrderableItemFixture, string, OrderableItemFixture>(
      gs1Code,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/orderable_item_fixtures";
      },
      "gs1_code",
      () => {
        return state.gs1CodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          if (item.gs1Code) {
            map.set(item.gs1Code, item);
          }
          state.itemMap.set(item.id, item);
        }
      },
      null,
      null
    );
  }

  function getByGs1Code(gs1Code: string) {
    return state.gs1CodeItemMap.get(gs1Code) || null;
  }

  async function fetchByHotCode(hotCode: string | string[], force?: boolean) {
    return fetchBase<OrderableItemFixture, string, OrderableItemFixture>(
      hotCode,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/orderable_item_fixtures";
      },
      "hot_code",
      () => {
        return state.hotCodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          if (item.hotCode) {
            map.set(item.hotCode, item);
          }
          state.itemMap.set(item.id, item);
        }
      },
      null,
      null
    );
  }

  function getByHotCode(hotCode: string) {
    return state.hotCodeItemMap.get(hotCode) || null;
  }

  const actionFind = createActionFindList<OrderableItemFixture, OrderableItemFixturesFindListParams>(
    state,
    context.root.$httpMedorder,
    () => {
      return `/api/v2/admin/orderable_item_fixtures`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: OrderableItemFixturesFindListRequestParams = {};
        if (params.gs1Code) {
          requestParams.gs1_code = params.gs1Code;
        }
        if (params.janCode) {
          requestParams.jan_code = params.janCode;
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  const actionCreate = createActionCreate<OrderableItemFixture, OrderableItemFixturesCreateParams>(
    context,
    state,
    (context, params) => {
      const url = "/api/v2/admin/orderable_item_fixtures";
      const data: OrderableItemFixturesCreateRequestData = {};
      if (params.name) {
        data.name = params.name;
      }
      if (params.nameAppend) {
        data.name_append = params.nameAppend;
      }
      if (params.gs1Code) {
        data.gs1_code = params.gs1Code;
      }
      if (params.janCode) {
        data.jan_code = params.janCode;
      }
      if (params.stockableItemFixtureId) {
        data.stockable_item_fixture_id = params.stockableItemFixtureId;
      }
      if (params.quantity !== undefined) {
        data.quantity = params.quantity;
      }
      if (params.makerName !== undefined) {
        data.maker_name = params.makerName;
      }
      if (params.sellerName !== undefined) {
        data.seller_name = params.sellerName;
      }
      if (params.packetForm !== undefined) {
        data.packet_form = params.packetForm;
      }
      if (params.packetVolume !== undefined) {
        data.packet_volume = params.packetVolume;
      }
      if (params.packagePacketCount !== undefined) {
        data.package_packet_count = params.packagePacketCount;
      }
      if (params.inactiveOn) {
        data.inactive_on = params.inactiveOn;
      }
      if (params.hotCode) {
        data.hot_code = params.hotCode;
      }
      if (params.note !== undefined) {
        data.note = params.note;
      }
      return context.root.$httpMedorder.post(url, data);
    },
    {
      findListState: state,
      afterEndCreate: (item) => {
        actionFetchItemCommitReplaceItem(state, item);
      },
    }
  );

  const actionPatch = createActionPatch<OrderableItemFixture, OrderableItemFixturesPatchParams>(
    context,
    state,
    (context, params) => {
      const url = `/api/v2/admin/orderable_item_fixtures/${params.id}`;
      const data: OrderableItemFixturesPatchRequestData = {};
      if (params.name !== undefined) {
        data.name = params.name;
      }
      if (params.nameAppend !== undefined) {
        data.name_append = params.nameAppend;
      }
      if (params.gs1Code !== undefined) {
        data.gs1_code = params.gs1Code;
      }
      if (params.janCode !== undefined) {
        data.jan_code = params.janCode;
      }
      if (params.stockableItemFixtureId !== undefined) {
        data.stockable_item_fixture_id = params.stockableItemFixtureId;
      }
      if (params.quantity !== undefined) {
        data.quantity = params.quantity;
      }
      if (params.makerName !== undefined) {
        data.maker_name = params.makerName;
      }
      if (params.sellerName !== undefined) {
        data.seller_name = params.sellerName;
      }
      if (params.packetForm !== undefined) {
        data.packet_form = params.packetForm;
      }
      if (params.packetVolume !== undefined) {
        data.packet_volume = params.packetVolume;
      }
      if (params.packagePacketCount !== undefined) {
        data.package_packet_count = params.packagePacketCount;
      }
      if (params.inactiveOn !== undefined) {
        data.inactive_on = params.inactiveOn;
      }
      if (params.hotCode !== undefined) {
        data.hot_code = params.hotCode;
      }
      if (params.note !== undefined) {
        data.note = params.note;
      }
      return context.root.$httpMedorder.patch(url, data);
    },
    {
      findListState: state,
      afterEndPatch: (item) => {
        actionFetchItemCommitReplaceItem(state, item);
      },
    }
  );

  return {
    ...actionFetch,
    fetchByGs1Code,
    getByGs1Code,
    fetchByHotCode,
    getByHotCode,
    ...actionFind,
    ...actionCreate,
    ...actionPatch,
  };
}

const provider = createStoreProvider(createOrderableItemFixturesStore, "stockableItemFixture");

export const provideOrderableItemFixturesStore = provider.provideStore;
export const useOrderableItemFixturesStore = provider.useStore;
