

























import { ref, defineComponent, watch } from "@vue/composition-api";

import {
  useStockableItemImportRequestsStore,
  StockableItemImportRequestState,
  StockableItemImportRequestCodeType,
} from "@/store/stockableItemImportRequestsStore";

import StockableItemImportRequestRow from "@/components/masterSources/StockableItemImportRequestRow.vue";
import { StockableItemImport, useStockableItemImportsStore } from "@/store/stockableItemImportsStore";
import { useStockableItemsStore } from "@/store/stockableItemsStore";

export default defineComponent({
  name: "StockableItemImportRequests",
  components: {
    StockableItemImportRequestRow,
  },
  setup() {
    const stockableItemsStore = useStockableItemsStore();
    const stockableItemImportsStore = useStockableItemImportsStore();
    const stockableItemImportRequestStore = useStockableItemImportRequestsStore();
    const currentPage = ref<number>(1);

    async function fetchStockableItemImportRequests(page: number) {
      await stockableItemImportRequestStore.findList({
        state: StockableItemImportRequestState.Open,
        page,
      });

      for (const item of stockableItemImportRequestStore.list.value) {
        let stockableItemImport: StockableItemImport | null = null;
        switch (item.codeType) {
          case StockableItemImportRequestCodeType.HotCode:
            await stockableItemImportsStore.fetchByHotCode(item.code);
            stockableItemImport = stockableItemImportsStore.getByHotCode(item.code);
            break;
          case StockableItemImportRequestCodeType.ReceiptCode:
            await stockableItemImportsStore.fetchByReceiptCode(item.code);
            stockableItemImport = stockableItemImportsStore.getByReceiptCode(item.code);
            break;
          default:
            continue;
        }
        if (stockableItemImport?.stockableItemId) {
          stockableItemsStore.fetch(stockableItemImport.stockableItemId);
        }
      }

      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchStockableItemImportRequests(page);
    });

    return {
      importRequests: stockableItemImportRequestStore.list,
      isFindingList: stockableItemImportRequestStore.isFindingList,
      totalCount: stockableItemImportRequestStore.totalCount,
      totalPages: stockableItemImportRequestStore.totalPages,
      currentPage,
    };
  },
});
