import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface MedhotRow {
  id: number;
  hotCode: string;
  yjCode: string | null;
  registerdOn: DateTime;
}

interface MedhotRowsFindListParams {
  medhotId?: number;
  packageGs1Code?: string;
}

interface MedhotRowsFindListRequestParams {
  medhot_id?: number;
  package_gs1_code?: string;
}

function createMedhotRowsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedhotRow>(),
    ...initStoreActionFindListState<MedhotRow>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medhot_rows`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedhotRow, MedhotRowsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medhot_rows`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedhotRowsFindListRequestParams = {};
        if (params.medhotId) {
          requestParams.medhot_id = params.medhotId;
        }
        if (params.packageGs1Code) {
          requestParams.package_gs1_code = params.packageGs1Code;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedhotRowsStore, "medhotRowsStore");

export const provideMedhotRowsStore = provider.provideStore;
export const useMedhotRowsStore = provider.useStore;
