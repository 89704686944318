<template>
  <div>
    <CCard>
      <CCardHeader>
        <span v-if="id">編集画面</span>
        <span v-else>新規作成</span>
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" to="."> <CIcon name="cil-chevron-left" /> キャンセル </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput v-model="company.id" label="薬局会社ID" horizontal disabled />
          <CInput v-model="company.name" label="名前" horizontal />
          <CTextarea v-model="company.note" label="ノート" horizontal rows="5" />
        </CForm>

        <CAlert :show.sync="successAlertCountDown" color="success" close-button>{{ saveResult }}</CAlert>
        <CAlert :show="shouldShowDangerAlert" color="danger">
          <ul v-for="error in errors" :key="error" class="mb-0">
            <li>{{ error }}</li>
          </ul>
        </CAlert>
      </CCardBody>
      <CCardFooter>
        <CButton color="secondary" :to="`.`"> キャンセル </CButton>
        <div class="float-right">
          <CButton v-if="id" color="primary" @click="saveCompany()">保存</CButton>
          <CButton v-else color="primary" @click="createCompany()">新規作成</CButton>
        </div>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "EditCompany",
  data() {
    return {
      id: null,
      company: {},
      saveResult: null,
      successAlertCountDown: 0,
      errors: [],
    };
  },
  computed: {
    shouldShowDangerAlert() {
      return this.errors.length > 0;
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) this.fetchCompany();
  },
  methods: {
    async fetchCompany() {
      if (this.id) {
        this.company = (await this.$axios.get(`/api/v2/core/admin/companies/${this.id}`)).data;
      }
    },
    async createCompany() {
      try {
        const response = (await this.$axios.post("/api/v2/core/admin/companies", this.company)).data;
        this.saveResult = "新規作成しました。";
        this.successAlertCountDown = 3;
        await this.$router.push({ name: "薬局会社情報", params: { id: response.id } });
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
    async saveCompany() {
      try {
        await this.$axios.put(`/api/v2/core/admin/companies/${this.id}`, this.company);
        this.saveResult = "更新成功しました。";
        this.successAlertCountDown = 3;
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>
