var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v(" 発注医薬品マスター手動登録 "),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action btn-setting text-primary",attrs:{"to":{ name: '発注医薬品マスター手動登録 新規作成' }}},[_vm._v(" 新規作成 ")])],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","bordered":"","fixed":"","responsive":"","loading":_vm.isFindingOrderableItemFixtures,"items":_vm.orderableItemFixtures,"fields":_vm.fields},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":{ name: '発注医薬品マスター手動登録 詳細', params: { id: orderableItemFixture.id } }}},[_vm._v(_vm._s(orderableItemFixture.id))])],1)]}},{key:"name",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(orderableItemFixture.name || "null"))])]}},{key:"stockableItemFixtureId",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":{ name: '在庫医薬品マスター手動登録 詳細', params: { id: orderableItemFixture.stockableItemFixtureId } }}},[_vm._v(_vm._s(orderableItemFixture.stockableItemFixtureId))])],1)]}},{key:"makerName",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(orderableItemFixture.makerName || "null"))])]}},{key:"sellerName",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(orderableItemFixture.sellerName || "null"))])]}},{key:"packetForm",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(orderableItemFixture.packetForm || "null"))])]}},{key:"inactiveOn",fn:function(ref){
var orderableItemFixture = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(orderableItemFixture.inactiveOn ? orderableItemFixture.inactiveOn.toFormat("yyyy年M月d日") : "null")+" ")])]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderableItemFixtures.length),expression:"orderableItemFixtures.length"}],attrs:{"align":"center","responsive":"","active-page":_vm.currentPage,"pages":_vm.totalPages,"limit":10},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }