import { AxiosRequestConfig, AxiosResponse, AxiosStatic } from "axios";

import { PromiseObserver } from "@/utils";
import { toStoreItems } from "@/store/utils";

const REQUEST_FETCH_ID_SIZE = 100;

export interface StoreActionFetchStateBase {
  isFetching: boolean;
  promiseObserver: PromiseObserver;
}

export interface SetItemsToMap<Item, MapKey, MapValue> {
  (map: Map<MapKey, MapValue>, items: Item[], ids: MapKey[], lastUpdated: number | null): void;
}

function commitStartFetch<MapKey, MapValue>(
  state: StoreActionFetchStateBase,
  map: Map<MapKey, MapValue>,
  ids: MapKey[],
  force: boolean,
  lastUpdated?: number
) {
  if (force) {
    state.isFetching = true;
    return { fetchIds: ids.concat(), ifUpdatedSince: false };
  }

  const notExistsIds = [];
  for (const fetchId of ids) {
    if (map.has(fetchId)) {
      continue;
    }
    notExistsIds.push(fetchId);
  }

  if (lastUpdated) {
    state.isFetching = true;
    if (notExistsIds.length > 0) {
      return { fetchIds: notExistsIds, ifUpdatedSince: false };
    } else {
      return { fetchIds: ids.concat(), ifUpdatedSince: true };
    }
  }

  if (notExistsIds.length > 0) {
    state.isFetching = true;
  }
  return { fetchIds: notExistsIds, ifUpdatedSince: false };
}

function commitEndFetch<Item, MapKey, MapValue>(
  state: StoreActionFetchStateBase,
  map: Map<MapKey, MapValue>,
  items: Item[],
  ids: MapKey[],
  lastUpdated: number | null,
  setItemsToMap: SetItemsToMap<Item, MapKey, MapValue>,
  isCacheClear: boolean
) {
  if (isCacheClear) {
    map.clear();
  }
  setItemsToMap(map, items, ids, lastUpdated);
  state.isFetching = false;
}

export function initStoreActionFetchBaseState() {
  return {
    isFetching: false,
    promiseObserver: new PromiseObserver(),
  };
}

function pushResponseData<Item>(items: Item[], response: AxiosResponse) {
  const responseItems = Array.isArray(response.data) ? response.data : [response.data];
  Array.prototype.push.apply(items, toStoreItems(responseItems));
}

function getLastUpdated(response: AxiosResponse) {
  const strLastUpdated = response.headers["x-last-updated"];
  if (!strLastUpdated) {
    return null;
  }
  return parseInt(strLastUpdated, 10);
}

function getIsCacheClear(response: AxiosResponse) {
  const storeCacheContorl = response.headers["x-store-cache-control"];
  if (!storeCacheContorl) {
    return false;
  }
  return storeCacheContorl === "clear";
}

export async function fetchBase<Item, MapKey, MapValue>(
  ids: MapKey | MapKey[],
  force: boolean,
  state: StoreActionFetchStateBase,
  httpClient: AxiosStatic,
  createUrl: () => string,
  paramIdsName: string,
  getMap: () => Map<MapKey, MapValue>,
  setItemsToMap: SetItemsToMap<Item, MapKey, MapValue>,
  afterReciveItems: ((items: Item[]) => Promise<void> | void) | null,
  afterEndFetch: ((items: Item[]) => void | Promise<void>) | null,
  lastUpdated?: number
) {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  const waitPromise = state.promiseObserver.startProcess();
  if (waitPromise) {
    await waitPromise;
  }

  try {
    const { fetchIds, ifUpdatedSince } = commitStartFetch(state, getMap(), ids, force, lastUpdated);
    if (!state.isFetching) {
      return [];
    }
    const items: Item[] = [];
    try {
      const url = createUrl();
      const requestConfig: AxiosRequestConfig = {};
      if (lastUpdated && ifUpdatedSince) {
        requestConfig.headers = {};
        requestConfig.headers["X-If-Updated-Since"] = lastUpdated;
      }
      let first = true;
      let resLastUpdated: number | null = null;
      let isCacheClear = false;
      while (fetchIds.length > 0) {
        const dividedFetchIds = fetchIds.splice(0, Math.min(fetchIds.length, REQUEST_FETCH_ID_SIZE));
        requestConfig.params = {};
        requestConfig.params[paramIdsName] = dividedFetchIds.join(",");
        const response = await httpClient.get(url, requestConfig);
        if (first) {
          first = false;
          resLastUpdated = getLastUpdated(response);
          isCacheClear = getIsCacheClear(response);
        }
        pushResponseData(items, response);
      }
      if (afterReciveItems) {
        await afterReciveItems(items);
      }
      commitEndFetch(state, getMap(), items, ids, resLastUpdated, setItemsToMap, isCacheClear);
    } catch (e) {
      commitEndFetch(state, getMap(), [], ids, null, setItemsToMap, false);

      // リソースがないときも 404 が返ってくるので、その時は例外をここで止める
      if (!e.response || e.response.status !== 404) {
        throw e;
      }
    }

    if (afterEndFetch) {
      await afterEndFetch(items);
    }

    return items;
  } finally {
    state.promiseObserver.endProcess();
  }
}

function commitStartAllFetch(state: StoreActionFetchStateBase) {
  state.isFetching = true;
  return true;
}

export async function fetchAllBase<Item, MapKey, MapValue>(
  state: StoreActionFetchStateBase,
  httpClient: AxiosStatic,
  createUrl: () => string,
  getMap: () => Map<MapKey, MapValue>,
  setItemsToMap: SetItemsToMap<Item, MapKey, MapValue>,
  afterReciveItems: ((items: Item[]) => Promise<void> | void) | null,
  afterEndFetch: ((items: Item[]) => void | Promise<void>) | null
) {
  const waitPromise = state.promiseObserver.startProcess();
  if (waitPromise) {
    await waitPromise;
  }

  try {
    if (!commitStartAllFetch(state)) {
      return [];
    }
    const items: Item[] = [];
    try {
      const url = createUrl();
      let page = 1;
      let totalPages = 1;
      while (page <= totalPages) {
        const response = await httpClient.get(url, { params: { page } });
        pushResponseData(items, response);
        totalPages = parseInt(response.headers["x-total-pages"], 10) || 0;
        ++page;
      }
      if (afterReciveItems) {
        await afterReciveItems(items);
      }
      commitEndFetch(state, getMap(), items, [], null, setItemsToMap, false);
    } catch (e) {
      commitEndFetch(state, getMap(), [], [], null, setItemsToMap, false);

      // リソースがないときも 404 が返ってくるので、その時は例外をここで止める
      if (!e.response || e.response.status !== 404) {
        throw e;
      }
    }

    if (afterEndFetch) {
      await afterEndFetch(items);
    }

    return items;
  } finally {
    state.promiseObserver.endProcess();
  }
}
