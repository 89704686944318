



















import { computed, defineComponent } from "@vue/composition-api";

import {
  StockableItemImportRequest,
  StockableItemImportRequestCodeType,
  StockableItemImportRequestState,
  useStockableItemImportRequestsStore,
} from "@/store/stockableItemImportRequestsStore";
import { useStockableItemImportsStore } from "@/store/stockableItemImportsStore";
import { useStockableItemsStore } from "@/store/stockableItemsStore";

import StockableItemImport from "@/components/masterSources/StockableItemImport.vue";

interface Props {
  stockableItemImportRequest: StockableItemImportRequest;
}

export default defineComponent<Props>({
  name: "StockableItemImportRequestRow",
  components: {
    StockableItemImport,
  },
  props: {
    stockableItemImportRequest: {
      type: Object,
    },
  },

  setup(props) {
    const stockableItemsStore = useStockableItemsStore();
    const stockableItemImportsStore = useStockableItemImportsStore();
    const stockableItemImportRequestsStore = useStockableItemImportRequestsStore();

    const stockableItemImport = computed(() => {
      stockableItemImportsStore.isFetching.value;
      switch (props.stockableItemImportRequest.codeType) {
        case StockableItemImportRequestCodeType.HotCode:
          return stockableItemImportsStore.getByHotCode(props.stockableItemImportRequest.code);
        case StockableItemImportRequestCodeType.ReceiptCode:
          return stockableItemImportsStore.getByReceiptCode(props.stockableItemImportRequest.code);
        default:
          return null;
      }
    });

    const stockableItem = computed(() => {
      if (!stockableItemImport.value || !stockableItemImport.value.stockableItemId) {
        return null;
      }
      return stockableItemsStore.get(stockableItemImport.value.stockableItemId);
    });

    async function importStockableItem() {
      switch (props.stockableItemImportRequest.codeType) {
        case StockableItemImportRequestCodeType.HotCode:
          await stockableItemImportsStore.create({
            hotCode: props.stockableItemImportRequest.code,
          });
          break;
        case StockableItemImportRequestCodeType.ReceiptCode:
          await stockableItemImportsStore.create({
            receiptCode: props.stockableItemImportRequest.code,
          });
          break;
      }
      stockableItemImportRequestsStore.deleteFromList(props.stockableItemImportRequest.code);
    }

    async function rejectImportRequest() {
      stockableItemImportRequestsStore.patch({
        id: props.stockableItemImportRequest.id,
        state: StockableItemImportRequestState.Rejected,
      });
    }

    return {
      stockableItemImport,
      stockableItem,
      importStockableItem,
      rejectImportRequest,
    };
  },
});
