// import CoreuiVue from "@coreui/vue";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CoreuiVue: any = require("@coreui/vue").default;
import VueCompositionApi from "@vue/composition-api";
import VCalendar from "v-calendar";
import Vue from "vue";
import Icon from "vue-awesome/components/Icon.vue";
import "vue-awesome/icons";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import { Auth0Plugin } from "./auth";
import Axios from "./plugins/axios";
import router from "./router";
import store from "./store";
import { createHttp } from "@/utils";

/* CoreUI */
Vue.use(CoreuiVue);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

/* Vue Composition API */
Vue.use(VueCompositionApi);

/* TOAST UI Editor for Vue */
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

/* VueFormGenerator */
import VueFormGenerator from "vue-form-generator";
import "@/css/vfg.scss"; // copy from "vue-form-generator/dist/vfg.css";
import "@/css/vue-form-generator.scss";
Vue.use(VueFormGenerator);

/* HTTP client */
if (!process.env.VUE_APP_V2_API_CORE_HOST) {
  throw "Set VUE_APP_V2_API_CORE_HOST in environment variables";
}
Vue.prototype.$httpCore = createHttp(process.env.VUE_APP_V2_API_CORE_HOST);

if (!process.env.VUE_APP_V2_API_MEDORDER_HOST) {
  throw "Set VUE_APP_V2_API_MEDORDER_HOST in environment variables";
}
Vue.prototype.$httpMedorder = createHttp(process.env.VUE_APP_V2_API_MEDORDER_HOST);

/* Vue Awesome */
Vue.component("v-icon", Icon);

/* Auth0 */
if (!process.env.VUE_APP_AUTH0_TENANT_DOMAIN) {
  throw "Set VUE_APP_AUTH0_TENANT_DOMAIN in environment variables";
}

if (!process.env.VUE_APP_AUTH0_CLIENT_ID) {
  throw "Set VUE_APP_AUTH0_CLIENT_ID in environment variables";
}

const domain = process.env.VUE_APP_AUTH0_TENANT_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRedirectCallback: (appState: any) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

/* vue-clipboard2 */
VueClipboard.config.autoSetContainer = true; // this line must be wrote before 'Vue.use(VueClipboard)'
Vue.use(VueClipboard);

/* Axios */
Vue.prototype.$axios = Axios;

/* Global Filters */
import "./filters/globalFilters";

/* Main */
Vue.config.productionTip = false;
new Vue({
  router,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  icons,
  store,
  render: (h) => h(App),
}).$mount("#app");
