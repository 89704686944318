import Vue from "vue";
import Vuex from "vuex";
import { DomainStore } from "./domain";
import { UIStore } from "./ui";

Vue.use(Vuex);

export type RootState = {};

export default new Vuex.Store({
  state: {},
  modules: {
    domain: DomainStore,
    ui: UIStore,
  },
});
