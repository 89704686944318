<template>
  <div>
    <CCard>
      <CCardBody>
        <SearchPharmacy :selected.sync="selectedPharmacy" :api-fields="apiFields" :filter="filterOutBelongingUser" />

        <CDataTable hover bordered fixed :items="listingPharmacies" :fields="fields" :loading="isLoading">
          <template #name="{item : pharmacy}">
            <td>
              <CLink :to="{ name: '薬局情報', params: { id: pharmacy.id } }">{{ pharmacy.name }}</CLink>
            </td>
          </template>
          <template #permission="{item : pharmacy}">
            <td>{{ userPermission(pharmacy.id) }}</td>
          </template>
          <template #actions="{item : pharmacy}">
            <td>
              <CButton
                :disabled="!isBelongingUser(pharmacy.id)"
                color="danger"
                class="mr-1 mb-1"
                size="sm"
                @click="removePharmacy(pharmacy.id)"
              >
                削除
              </CButton>
              <CButton
                v-for="permission in permissions"
                :key="permission + pharmacy.id"
                :disabled="userPermission(pharmacy.id) === permission"
                color="primary"
                class="mr-1 mb-1"
                size="sm"
                @click="addPharmacy(pharmacy.id, permission)"
              >
                {{ permission }}
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SearchPharmacy from "src/components/shared/search/SearchPharmacy";

export default {
  name: "UserPharmacies",
  components: { SearchPharmacy },
  data() {
    return {
      id: null,
      user: {},
      listingPharmacies: [], // テーブル表示用の一時的な薬局一覧
      selectedPharmacy: null,
      apiFields: ["id", "name", "micode", "address1"],
      permissions: ["read", "write"],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      return this.apiFields.concat(["permission", "actions"]);
    },
    pharmacyIds() {
      return this.user.app_metadata?.pharmacy_ids || [];
    },
  },
  watch: {
    selectedPharmacy() {
      this.listingPharmacies = [this.selectedPharmacy].concat(this.listingPharmacies);
      this.listingPharmacies = this.listingPharmacies.filter((p, i, array) => array.map((x) => x.id).indexOf(p.id) === i);
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchUser();
    this.listingPharmacies = this.user.pharmacies;
  },
  methods: {
    async fetchUser() {
      if (this.isLoading) return;

      this.isLoading = true;
      if (this.id) this.user = (await this.$axios.get(`/api/v2/core/admin/users/${this.id}`)).data;
      this.isLoading = false;
    },
    async addPharmacy(pharmacyId, permission) {
      const req_params = { user_id: this.id, permission: permission };
      await this.$axios.post(`/api/v2/core/admin/pharmacies/${pharmacyId}/users`, req_params);
      await this.fetchUser();
      this.listingPharmacies = this.listingPharmacies.map((pharmacy) => {
        if (pharmacy.id === pharmacyId) pharmacy.permission = permission;
        return pharmacy;
      });
    },
    async removePharmacy(pharmacyId) {
      const req_params = { user_id: this.id };
      await this.$axios.delete(`/api/v2/core/admin/pharmacies/${pharmacyId}/users`, { data: req_params });
      await this.fetchUser();
    },
    isBelongingUser(pharmacyId) {
      return this.pharmacyIds.includes(pharmacyId);
    },
    userPermission(pharmacyId) {
      if (this.isBelongingUser(pharmacyId)) {
        return this.listingPharmacies.find((pharmacy) => pharmacy.id === pharmacyId).permission;
      }
      return "未所属";
    },
    filterOutBelongingUser(pharmacy) {
      return !this.pharmacyIds.includes(pharmacy.id);
    },
  },
};
</script>
