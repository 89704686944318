<template>
  <div>
    <CTabs :active-tab="activeTab">
      <CTab title="薬局の詳細" :to="{ name: '薬局の詳細', params: { id: id } }"></CTab>
      <CTab title="開設許可証" :to="{ name: '薬局の開設許可証', params: { id: id } }"></CTab>
      <CTab title="薬局の近隣グループ一覧" :to="{ name: '薬局の近隣グループ一覧', params: { id: id } }"></CTab>
      <CTab title="薬局のユーザ一覧" :to="{ name: '薬局のユーザ一覧', params: { id: id } }"></CTab>
      <CTab title="薬局の設定" :to="{ name: '薬局の設定', params: { id: id } }"></CTab>
    </CTabs>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PharmacyTabs",
  data() {
    return {
      id: null,
    };
  },
  computed: {
    activeTab() {
      return ["薬局の詳細", "薬局の開設許可証", "薬局の近隣グループ一覧", "薬局のユーザ一覧", "薬局の設定"].indexOf(this.$route.name);
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>
