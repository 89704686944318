<template>
  <CCard v-if="filename">
    <CCardHeader>
      {{ filename }}
      <div class="card-header-actions">
        <CLink class="card-header-action btn-setting text-primary" :href="presignedUrl" :download="filename" target="_blank">
          Shift-JISでダウンロード
        </CLink>
      </div>
    </CCardHeader>
    <CCardBody>
      <CAlert color="secondary">
        <pre>{{ nsipsData }}</pre>
      </CAlert>
      <CAlert color="info">UTF8 へ変換済み</CAlert>
    </CCardBody>
  </CCard>
</template>

<script>
import * as iconv from "iconv-lite";

export default {
  name: "NsipsCard",
  props: {
    pharmacyId: {
      type: String,
      default: null,
    },
    filename: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      presignedUrl: null,
      nsipsData: null,
    };
  },
  watch: {
    async filename() {
      await this.fetchNsips();
    },
  },
  async created() {
    await this.fetchNsips();
  },
  methods: {
    async fetchNsips() {
      await this.fetchPresignedUrl();
      if (!this.presignedUrl) return;

      const response = await this.$axios.get(this.presignedUrl, { responseType: "arraybuffer" });
      this.nsipsData = iconv.decode(Buffer.from(response.data), "CP932");
    },
    async fetchPresignedUrl() {
      if (!this.pharmacyId || !this.filename) return;

      const url = `/api/v2/core/admin/pharmacies/${this.pharmacyId}/s3_nsipses/${this.filename}`;
      const response = await this.$axios.get(url);
      this.presignedUrl = response.data.presigned_url;
    },
  },
};
</script>

<style>
pre {
  white-space: pre-wrap;
}
</style>
