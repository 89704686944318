<template>
  <CCard>
    <CCardHeader>
      詳細情報
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput label="ユーザID" :value="user.id" horizontal plaintext disabled />
        <CInput label="Auth0 ID" :value="user.user_id" horizontal plaintext disabled />
        <CInput label="名前" :value="user.name" horizontal plaintext disabled />
        <CInput label="作成日" :value="user.created_at" horizontal plaintext disabled />
        <CInput label="更新日" :value="user.updated_at" horizontal plaintext disabled />
        <CInput label="作成者" :value="user.created_by" horizontal plaintext disabled />
        <CInput label="更新者" :value="user.updated_by" horizontal plaintext disabled />
        <div class="form-group form-row">
          <CCol tag="label" sm="3" class="col-form-label">Auth0 詳細情報</CCol>
          <CCol sm="9">
            <pre class="bg-light text-black-50"> {{ user }} </pre>
          </CCol>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "UserDetails",
  data() {
    return {
      id: null,
      user: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchUser();
  },
  methods: {
    async fetchUser() {
      if (this.id) this.user = (await this.$axios.get(`/api/v2/core/admin/users/${this.id}`)).data;
    },
  },
};
</script>
