<template>
  <CHeader fixed with-subheader light class="electron-draggable-area">
    <CToggler v-c-emit-root-event:toggle-sidebar-mobile in-header class="ml-3 d-lg-none" />
    <CToggler v-c-emit-root-event:toggle-sidebar in-header class="ml-3 d-md-down-none" />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">CSポータル</CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :to="{ name: 'JWT' }">JWT</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :href="core_url">薬局情報</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :href="medorder_url">メドオーダー</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :href="medshare_url">メドシェア</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav class="mr-4">
      <TheHeaderDropdownEnvironment class="d-md-down-none" />
      <TheHeaderDropdownAccount />
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccount from "@/containers/TheHeaderDropdownAccount";
import TheHeaderDropdownEnvironments from "@/containers/TheHeaderDropdownEnvironments";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccount: TheHeaderDropdownAccount,
    TheHeaderDropdownEnvironment: TheHeaderDropdownEnvironments,
  },
  data() {
    return {
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST,
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
      medshare_url: process.env.VUE_APP_V2_APP_MEDSHARE_HOST,
    };
  },
};
</script>

<style>
.electron-draggable-area {
  -webkit-app-region: drag;
}
</style>
