



















import { ref, defineComponent, watch } from "@vue/composition-api";

import { useMedisBaseRowsStore } from "@/store/medisBaseRowsStore";

import MedisBaseRowsDataTable from "@/components/masterSources/MedisBaseRowsDataTable.vue";

export default defineComponent({
  name: "MedisBaseDetails",
  components: {
    MedisBaseRowsDataTable,
  },
  setup(props, context) {
    const medisBaseId = parseInt(context.root.$route.params["id"], 10);
    const medisBaseRowsStore = useMedisBaseRowsStore();
    const currentPage = ref<number>(1);

    async function fetchMedisBaseRows(page: number) {
      medisBaseRowsStore.findList({
        medisBaseId,
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisBaseRows(page);
    });

    return {
      medisBaseRows: medisBaseRowsStore.list,
      isFindingList: medisBaseRowsStore.isFindingList,
      totalCount: medisBaseRowsStore.totalCount,
      totalPages: medisBaseRowsStore.totalPages,
      currentPage,
    };
  },
});
