<template>
  <div>
    <CAlert v-if="userRole === undefined" color="danger">userRoleが見つかりません。</CAlert>
    <CCard>
      <CCardHeader>
        <span>{{ $route.name }}</span>
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" to=".."> <CIcon name="cil-chevron-left" /> キャンセル </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <div v-if="shouldShowPharmacySelectBox" class="form-group form-row">
            <CCol tag="label" sm="3" class="col-form-label">薬局 *</CCol>
            <CCol sm="9">
              <SearchPharmacy :selected.sync="selectedPharmacy" />
            </CCol>
          </div>
          <div v-if="shouldShowPharmacySelectBox" class="form-group form-row">
            <CCol tag="label" sm="3" class="col-form-label">権限 *</CCol>
            <CCol sm="9">
              <CInputRadioGroup :options="permissions" :custom="true" :checked.sync="selectedPermission" :inline="true" />
            </CCol>
          </div>
          <CInput v-model="user.name" label="名前 *" horizontal />
          <CInput v-model="user.email" label="メールアドレス *" horizontal />
        </CForm>

        <CAlert :show.sync="successAlertCountDown" color="success" close-button>{{ saveResult }}</CAlert>
        <CAlert :show="shouldShowDangerAlert" color="danger">
          <ul v-for="error in errors" :key="error" class="mb-0">
            <li>{{ error }}</li>
          </ul>
        </CAlert>
      </CCardBody>
      <CCardFooter>
        <CButton color="secondary" to=".."> キャンセル </CButton>
        <div class="float-right">
          <CButton color="primary" @click="createUser()">新規作成</CButton>
        </div>
      </CCardFooter>
    </CCard>

    <CModal title="新規ユーザを作成しました" size="lg" :show.sync="isOpenCreatedModal">
      <CAlert color="info">下記のログイン情報を連絡してください。</CAlert>
      <CAlert v-if="createResponse" style="white-space: pre-line;" color="secondary">{{ loginInformation }}</CAlert>
      <CButton v-clipboard:copy="loginInformation" v-clipboard:success="onSuccessCopy" class="mb-2" color="secondary">
        {{ copyButtonText }}
      </CButton>
      <template #footer>
        <CButton color="info" @click="pushToDetailVue">詳細ページ</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import SearchPharmacy from "src/components/shared/search/SearchPharmacy";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: "CreateEditUser",
  components: { SearchPharmacy },
  data() {
    return {
      id: null,
      user: {},
      selectedPharmacy: null,
      permissions: ["read", "write"],
      selectedPermission: "write",
      isUpdating: false,
      createResponse: null,
      saveResult: null,
      successAlertCountDown: 0,
      errors: [],
      isOpenCreatedModal: false,
      copyButtonText: "コピー",
    };
  },
  computed: {
    shouldShowDangerAlert() {
      return this.errors.length > 0;
    },
    shouldShowPharmacySelectBox() {
      return this.userRole === "pharmacy";
    },
    userRole() {
      switch (this.$route.name) {
        case "薬局ユーザ新規作成":
          return "pharmacy";
        case "管理者ユーザ新規作成":
          return "admin";
        default:
          throw "Undefined User Role";
      }
    },
    loginInformation() {
      return this.createResponse ? `メールアドレス： ${this.createResponse.email}\n初期パスワード： ${this.createResponse.password}` : "";
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) this.fetchUser();
  },
  methods: {
    async fetchUser() {
      if (this.id) {
        this.user = (await this.$axios.get(`/api/v2/core/admin/users/${this.id}`)).data;
      }
    },
    async createUser() {
      this.isUpdating = true;
      try {
        const params = {
          role: this.userRole,
          name: this.user.name,
          email: this.user.email,
        };
        if (this.userRole === "pharmacy") {
          params.pharmacy_id = this.selectedPharmacy?.id;
          params.permission = this.selectedPermission;
        }

        const response = (await this.$axios.post("/api/v2/core/admin/users", params)).data;
        this.saveResult = "新規作成しました。";
        this.successAlertCountDown = 3;

        this.createResponse = response;
        this.openCreatedModal();
      } catch (error) {
        this.errors = error.response.data.errors;
      } finally {
        this.isUpdating = false;
      }
    },
    async openCreatedModal() {
      this.isOpenCreatedModal = true;
    },
    async onSuccessCopy() {
      const origin = this.copyButtonText;
      this.copyButtonText = "コピーしました";
      await sleep(1500);
      this.copyButtonText = origin;
    },
    async pushToDetailVue() {
      await this.$router.push({ name: "ユーザ情報", params: { id: this.createResponse.id } });
    },
  },
};
</script>
