





























































































































































import { computed, defineComponent } from "@vue/composition-api";

import { StockableItemImport } from "@/store/stockableItemImportsStore";
import { useStockableItemsStore } from "@/store/stockableItemsStore";
import { useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";

interface Props {
  stockableItemImport: StockableItemImport | null;
}

export default defineComponent<Props>({
  name: "StockableItemImport",
  props: {
    stockableItemImport: {
      type: Object,
    },
  },
  setup(props) {
    const stockableItemsStore = useStockableItemsStore();
    const orderableItemFixturesStore = useOrderableItemFixturesStore();

    const stockableItem = computed(() => {
      if (!props.stockableItemImport || !props.stockableItemImport.stockableItemId) {
        return null;
      }
      return stockableItemsStore.get(props.stockableItemImport.stockableItemId);
    });

    const importOrderableItemId = computed(() => {
      const stockableItemImport = props.stockableItemImport;
      if (!stockableItemImport || !stockableItemImport.orderableItems?.length) {
        return null;
      }
      return stockableItemImport.orderableItems[0].orderableItemId;
    });

    const orderableItem = computed(() => {
      const orderableItemId = importOrderableItemId.value;
      if (!orderableItemId || !stockableItem.value) {
        return null;
      }

      return (
        stockableItem.value.orderableItems.find((item) => {
          return item.id === orderableItemId;
        }) || null
      );
    });

    function saveInactiveOn() {
      if (!orderableItem.value || !orderableItem.value.inactiveOn) {
        return;
      }
      orderableItemFixturesStore.create({
        gs1Code: orderableItem.value.gs1Code,
        inactiveOn: orderableItem.value.inactiveOn.toFormat("yyyy-MM-dd"),
      });
    }

    return {
      stockableItem,
      orderableItem,
      importOrderableItemId,
      saveInactiveOn,
    };
  },
});
