import Axios from "axios";
import { apiPathInterceptor } from "./apiPathInterceptor";
import { convertCaseInterceptor } from "./convertCaseInterceptor";
import { Interceptor } from "./interceptor";
import { responseInterceptor } from "./responseInterceptor";

const axios = Axios.create();

// responseInterceptor は最後に適用すべき
const interceptors: Interceptor[] = [apiPathInterceptor, convertCaseInterceptor, responseInterceptor];
interceptors.forEach((interceptor) => interceptor.apply(axios));

export default axios;
