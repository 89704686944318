import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { collectNullableIds, createStoreProvider } from "@/store/utils";
import { fetchBase } from "@/store/actions/createActionFetchBase";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { initStoreActionCreateState, createActionCreate } from "@/store/actions/createActionCreate";
import { useStockableItemsStore } from "./stockableItemsStore";

interface StockableItemAttributes {
  name?: string;
}

interface OrderableItemAttributes {
  name?: string;
}

export interface StockableItemImportOrderableItem {
  operation?: string;
  orderableItemId?: number;
  attributes?: OrderableItemAttributes;
}

export interface StockableItemImport {
  id: number;
  stockableItemOperation?: string;
  stockableItemId?: number;
  stockableItemAttributes?: StockableItemAttributes;
  orderableItems?: StockableItemImportOrderableItem[];
  invalidReason?: string;
}

interface StockableItemImportsCreateParams {
  hotCode?: string;
  receiptCode?: string;
  stockableItemFixtureId?: number;
}

interface StockableItemImportsCreateData {
  hot_code?: string;
  receipt_code?: string;
  stockable_item_fixture_id?: number;
}

function createStockableItemImportStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<StockableItemImport>(),
    ...initStoreActionCreateState(),
    hotCodeItemMap: new Map<string, StockableItemImport>(),
    receiptCodeItemMap: new Map<string, StockableItemImport>(),
    stockableItemFixtureItemMap: new Map<number, StockableItemImport>(),
  });

  const stockableItemsStore = useStockableItemsStore();

  function fetchStockableItems(items: StockableItemImport[]) {
    const stockableItemIds = collectNullableIds(items, (item) => {
      return item.stockableItemId;
    });
    return stockableItemsStore.fetch(stockableItemIds);
  }

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/stockable_items/import`;
  });
  actionFetchBuilder.addAfterReceiveItems(async (items) => {
    await fetchStockableItems(items);
  });
  const actionFetch = actionFetchBuilder.build();

  async function fetchByHotCode(hotCode: string, force?: boolean) {
    return fetchBase<StockableItemImport, string, StockableItemImport>(
      hotCode,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/stockable_items/import";
      },
      "hot_code",
      () => {
        return state.hotCodeItemMap;
      },
      (map, items, hot_codes) => {
        for (const item of items) {
          map.set(hot_codes[0], item);
        }
      },
      async (items) => {
        await fetchStockableItems(items);
      },
      null
    );
  }

  function getByHotCode(hotCode: string) {
    return state.hotCodeItemMap.get(hotCode) || null;
  }

  async function fetchByReceiptCode(receiptCode: string, force?: boolean) {
    return fetchBase<StockableItemImport, string, StockableItemImport>(
      receiptCode,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/stockable_items/import";
      },
      "receipt_code",
      () => {
        return state.receiptCodeItemMap;
      },
      (map, items, receiptCodes) => {
        for (const item of items) {
          map.set(receiptCodes[0], item);
        }
      },
      async (items) => {
        await fetchStockableItems(items);
      },
      null
    );
  }

  function getByReceiptCode(receiptCode: string) {
    return state.receiptCodeItemMap.get(receiptCode) || null;
  }

  async function fetchByStockableItemFixtureId(stockableItemFixtureId: number, force?: boolean) {
    return fetchBase<StockableItemImport, number, StockableItemImport>(
      stockableItemFixtureId,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/stockable_items/import";
      },
      "stockable_item_fixture_id",
      () => {
        return state.stockableItemFixtureItemMap;
      },
      (map, items, stockableItemFixtureIds) => {
        for (const item of items) {
          map.set(stockableItemFixtureIds[0], item);
        }
      },
      async (items) => {
        await fetchStockableItems(items);
      },
      null
    );
  }

  function getByStockableItemFixtureId(stockableItemFixtureId: number) {
    return state.stockableItemFixtureItemMap.get(stockableItemFixtureId) || null;
  }

  const actionCreate = createActionCreate<StockableItemImport, StockableItemImportsCreateParams>(context, state, (context, params) => {
    const url = "/api/v2/admin/stockable_items/import";
    const data: StockableItemImportsCreateData = {};
    if (params.hotCode) {
      data.hot_code = params.hotCode;
    }
    if (params.receiptCode) {
      data.receipt_code = params.receiptCode;
    }
    if (params.stockableItemFixtureId) {
      data.stockable_item_fixture_id = params.stockableItemFixtureId;
    }
    return context.root.$httpMedorder.post(url, data);
  });

  return {
    ...actionFetch,
    fetchByHotCode,
    getByHotCode,
    fetchByReceiptCode,
    getByReceiptCode,
    fetchByStockableItemFixtureId,
    getByStockableItemFixtureId,
    ...actionCreate,
  };
}

const provider = createStoreProvider(createStockableItemImportStore, "stockableItemImportStore");

export const provideStockableItemImportsStore = provider.provideStore;
export const useStockableItemImportsStore = provider.useStore;
