











































































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import FromNowSpan from "@/components/shared/datetime/FromNowSpan.vue";
import FromNowSpanWithWarning from "@/components/shared/datetime/FromNowSpanWithWarning.vue";
import { defineComponent, ref } from "@vue/composition-api";
import axios from "@/plugins/axios";

class BigqueryTable {
  id?: string;
  project_id?: string;
  dataset_id?: string;
  table_id?: string;
  created_at?: string;
  modified_at?: string;
  bytes_count?: number;
  rows_count?: number;
  description?: string;
  labels?: object;
  mysql_exist?: boolean;
  mysql_exist_label?: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.mysql_exist_label = init?.mysql_exist ? "はい" : "いいえ";
  }
}

export default defineComponent({
  name: "BigQueryTables",
  components: { LoadingAlert, FromNowSpan, FromNowSpanWithWarning },
  setup(props, context) {
    const serviceName = context.root.$route.params.serviceName;
    const fields = [
      // { key: "project_id", label: "プロジェクトID", sorter: true },
      { key: "dataset_id", label: "データセットID", sorter: true },
      { key: "table_id", label: "テーブルID", sorter: true },
      { key: "created_at", label: "作成日", sorter: true },
      { key: "modified_at", label: "更新日", sorter: true },
      { key: "bytes_count", label: "表のサイズ(byte)", sorter: true },
      { key: "rows_count", label: "行数", sorter: true },
      // { key: "description", label: "説明", sorter: true },
      // { key: "labels", label: "ラベル", sorter: true },
      { key: "mysql_exist_label", label: "MySQL存在", sorter: true },
      { key: "actions", label: "操作", sorter: false },
    ];
    const sorter = { resetable: false };
    const sorterValue = { column: "id", asc: true };

    const openDetailModal = ref<boolean>(false);
    const selectingTable = ref<BigqueryTable>({});
    const toastMessages = ref<Array<object>>([]);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const tables = ref<Array<BigqueryTable>>([]);

    async function fetchTables() {
      if (isLoading.value) return;

      isLoading.value = true;
      const req_params = { column: "full" };
      const url = `/api/v2/core/admin/bigqueries/${serviceName}`;
      const response = await axios.get(url, { params: req_params });
      tables.value = response.data.map((d: object) => new BigqueryTable(d));

      isFirstLoading.value = false;
      isLoading.value = false;
    }

    async function showDetails(id: string) {
      selectingTable.value = tables.value.find((t) => t.id === id) || {};
      openDetailModal.value = true;
    }

    async function executeCopy(table: BigqueryTable) {
      try {
        const data = { service_name: table.dataset_id, table_name: table.table_id };
        const url = "/api/v2/core/admin/embulks/execute_copy";
        const response = await axios.post(url, data);

        toastMessages.value.push({ id: response.data.job_id, textClass: "text-info", message: "ジョブを実行しました。" });
        openDetailModal.value = false;
      } catch (e) {
        const id = Math.random().toString(32).substring(2);
        const message = `ジョブ実行が失敗しました。${e.message}`;
        toastMessages.value.push({ id: id, textClass: "text-danger", message: message });
      }
    }

    fetchTables();

    return {
      fields,
      sorter,
      sorterValue,

      selectingTable,
      openDetailModal,
      toastMessages,

      isFirstLoading,
      isLoading,
      tables,

      showDetails,
      executeCopy,
    };
  },
});
