import { RootState } from "@/store";
import { Module } from "vuex";
import { UIMasterStoreState } from "./master";

export type UIStoreState = {};

export const UIStore: Module<UIStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    master: UIMasterStoreState,
  },
};
