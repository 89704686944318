


















import { defineComponent } from "@vue/composition-api";

import { IryohokenRow } from "@/store/iryohokenRowsStore";

interface Props {
  iryohokenRows: IryohokenRow[];
  loading: boolean;
}

export default defineComponent<Props>({
  name: "IryohokenRowDataTable",
  props: {
    iryohokenRows: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const fields = [
      { key: "id", label: "ID" },
      { key: "registerType", label: "変更区分" },
      { key: "receiptCode", label: "レセプト電算コード" },
      { key: "brandName", label: "医薬品名規格名" },
      { key: "officialUnitPriceType", label: "金額種別" },
      { key: "officialUnitPrice", label: "新又は現金額" },
      { key: "drugPoison", label: "麻薬など" },
      { key: "generic", label: "後発品" },
      { key: "registeredOn", label: "変更年月日" },
      { key: "disestablishesDate", label: "販廃止年月日売名" },
      { key: "mhlwCode", label: "薬価基準収載医薬品コード" },
      { key: "interimPeriodDate", label: "経過措置年月日" },
    ];

    return {
      fields,
    };
  },
});
