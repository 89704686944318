























































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import FromNowSpan from "@/components/shared/datetime/FromNowSpan.vue";
import FromNowSpanWithWarning from "@/components/shared/datetime/FromNowSpanWithWarning.vue";
import { defineComponent, ref } from "@vue/composition-api";
import axios from "@/plugins/axios";
import * as moment from "moment";
import "moment-duration-format";

class Embulk {
  job_id?: string;
  job_name?: string;
  status?: string;
  status_reason?: string;
  created_at?: number;
  started_at?: number;
  stopped_at?: number;
  container?: object;
}

export default defineComponent({
  name: "Embulks",
  components: { LoadingAlert, FromNowSpan, FromNowSpanWithWarning },
  setup() {
    const fields = [
      // { key: "job_id", label: "ジョブID", sorter: true },
      { key: "job_name", label: "ジョブ名", sorter: true },
      { key: "created_at", label: "作成日", sorter: true },
      { key: "started_at", label: "開始日", sorter: true },
      { key: "stopped_at", label: "終了日", sorter: true },
      { key: "time", label: "実行時間", sorter: false },
      { key: "status", label: "ステータス", sorter: true },
      // { key: "status_reason", label: "理由", sorter: true },
    ];
    const sorter = { resetable: false };
    const sorterValue = { column: "created_at", asc: false };

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const tables = ref<Array<Embulk>>([]);

    async function fetchEmbulks() {
      if (isLoading.value) return;

      isLoading.value = true;
      const url = `/api/v2/core/admin/embulks`;
      const response_submitted = await axios.get(url, { params: { job_status: "SUBMITTED" } });
      const response_pending = await axios.get(url, { params: { job_status: "PENDING" } });
      const response_runnable = await axios.get(url, { params: { job_status: "RUNNABLE" } });
      const response_starting = await axios.get(url, { params: { job_status: "STARTING" } });
      const response_running = await axios.get(url, { params: { job_status: "RUNNING" } });
      const response_succeeded = await axios.get(url, { params: { job_status: "SUCCEEDED" } });
      const response_failed = await axios.get(url, { params: { job_status: "FAILED" } });
      tables.value = [];
      tables.value = tables.value.concat(response_submitted.data.job_summary_list);
      tables.value = tables.value.concat(response_pending.data.job_summary_list);
      tables.value = tables.value.concat(response_runnable.data.job_summary_list);
      tables.value = tables.value.concat(response_starting.data.job_summary_list);
      tables.value = tables.value.concat(response_running.data.job_summary_list);
      tables.value = tables.value.concat(response_succeeded.data.job_summary_list);
      tables.value = tables.value.concat(response_failed.data.job_summary_list);

      isFirstLoading.value = false;
      isLoading.value = false;
    }

    async function showDetails(id: string) {
      console.log(id);
    }

    function statusColor(status: string) {
      switch (status) {
        /* eslint-disable prettier/prettier */
        case "SUCCEEDED": return "success";
        case "FAILED": return "danger";
        default: return "info";
        /* eslint-enable prettier/prettier */
      }
    }

    fetchEmbulks();

    return {
      fields,
      sorter,
      sorterValue,

      isFirstLoading,
      isLoading,
      tables,

      fetchEmbulks,
      showDetails,
      statusColor,

      moment,
    };
  },
});
