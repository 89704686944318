
















import { defineComponent, ref } from "@vue/composition-api";
import axios from "@/plugins/axios";
import yaml from "js-yaml";
import { DateTime } from "luxon";

function get_extension(filename: string) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export default defineComponent({
  name: "PcAppLink",
  setup() {
    const apps = ref<object[]>([]);
    const appsFields = ref<object[]>([
      { key: "platform", label: "プラットフォーム" },
      { key: "env", label: "環境" },
      { key: "releaseDate", label: "リリース日" },
      { key: "version", label: "バージョン" },
      { key: "link", label: "リンク" },
    ]);

    const disabled = ["develop"].includes(process.env.VUE_APP_ENV || "error");

    async function setWindowsAppInfo() {
      const win_latest_url = `${process.env.VUE_APP_V2_PC_APP_HOST}/latest.yml?timestamp=${Date.now()}`; // timestamp はキャッシュ対策簡易版
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = yaml.safeLoad((await axios.get(win_latest_url)).data);
      apps.value.push({
        env: `${process.env.VUE_APP_ENV}`,
        disabled: disabled,
        link: `${process.env.VUE_APP_V2_PC_APP_HOST}/${data.path}`,
        platform: "Windows",
        releaseDate: data.releaseDate,
        version: data.version,
      });
    }
    async function setMacAppInfo() {
      const mac_latest_url = `${process.env.VUE_APP_V2_PC_APP_HOST}/latest-mac.yml?timestamp=${Date.now()}`; // timestamp はキャッシュ対策簡易版
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = yaml.safeLoad((await axios.get(mac_latest_url)).data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dmg_url = data.files.find((infos: any) => get_extension(infos.url) === "dmg").url;

      apps.value.push({
        env: `${process.env.VUE_APP_ENV}`,
        disabled: disabled,
        link: `${process.env.VUE_APP_V2_PC_APP_HOST}/${dmg_url}`,
        platform: "Mac",
        releaseDate: data.releaseDate,
        version: data.version,
      });
    }

    function displayIsoDate(date: string) {
      return DateTime.fromISO(date).toISO();
    }

    setWindowsAppInfo();
    setMacAppInfo();

    return {
      apps,
      appsFields,

      displayIsoDate,
    };
  },
});
