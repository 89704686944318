import { PaginatedResponse } from "@/api/paginatedResponse";
import { ApiRequest, isPaginatedApiRequest, PaginatedApiRequest } from "@/api/request";
import { Response } from "@/api/response";
import { EthicalMedicine } from "@/models/ethicalMedicine";
import axios from "@/plugins/axios/index";
import Axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { snakeCase } from "change-case";
import { DateTime } from "luxon";

type TransactionPoolType = {
  [key: string]: CancelTokenSource[];
};

const transactionPool: TransactionPoolType = {};

function getPool(transactionPoolName: string): CancelTokenSource[] {
  if (!Object.prototype.hasOwnProperty.call(transactionPool, transactionPoolName)) {
    transactionPool[transactionPoolName] = [];
  }
  return transactionPool[transactionPoolName];
}

type EthicalMedicineApiClient = {
  isFetching: () => boolean;
  cancelAll: () => void;
  fetchAll: (page: number, includeInactive: boolean, query: string) => Promise<PaginatedResponse<EthicalMedicine[]>>;
  fetch: (id: number) => Promise<Response<EthicalMedicine>>;
  downloadHotCode: (startDate: DateTime) => void;
  importHotCode: () => void;
  downloadMedhot: (startDate: DateTime) => void;
  importMedhot: () => void;
  downloadIryohoken: (startYear: number) => void;
  importIryohoken: () => void;
};

export function ethicalMedicineApi(transactionPoolName = "default"): EthicalMedicineApiClient {
  const pool = getPool(transactionPoolName);

  function sendRequest<T extends ApiRequest<U>, U>(request: T): Promise<U> {
    const cancelTokenSource = Axios.CancelToken.source();
    pool.push(cancelTokenSource);

    const requestPath = (() => {
      if (request.version === "v1") {
        return `/api/${request.version}/${request.path}`;
      } else if (request.version === "v2") {
        if (!request.module) {
          throw Error();
        }
        return `/api/${request.version}/${request.module}${request.path}`;
      } else {
        throw Error("Invalid API path");
      }
    })();

    const params = ((): { [key: string]: unknown } => {
      if (request.method === "get" && request.parameters) {
        return request.parameters;
      } else {
        return {};
      }
    })();

    if (isPaginatedApiRequest(request)) {
      params["page"] = request.page;
    }

    const data = new URLSearchParams();
    if (request.method === "patch" || request.method === "post" || (request.method === "put" && request.parameters)) {
      for (const key in request.parameters) {
        if (Object.prototype.hasOwnProperty.call(request.parameters, key)) {
          const element = request.parameters[key];
          data.append(snakeCase(key), element);
        }
      }
    }

    const config: AxiosRequestConfig = {
      url: requestPath,
      method: request.method,
      params,
      data,
      cancelToken: cancelTokenSource.token,
    };

    return new Promise((resolve, reject) => {
      axios
        .request(config)
        .then((value) => resolve(value.data))
        .catch((error) => reject(error))
        .finally(() => {
          pool.splice(
            pool.findIndex((source) => source.token === cancelTokenSource.token),
            1
          );
        });
    });
  }

  return {
    isFetching: () => pool.length > 0,
    cancelAll: () => {
      pool.forEach((source) => source.cancel());
    },
    fetchAll: (page = 1, includeInactive = false, query = "") => {
      if (page < 1) {
        page = 1;
      }

      const fetchAllRequest: PaginatedApiRequest<EthicalMedicine[]> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines",
        method: "get",
        page: page,
        parameters: {
          includeInactive: includeInactive ? "true" : "false",
          query,
        },
      };
      return sendRequest(fetchAllRequest);
    },
    fetch: (id) => {
      const fetchRequest: ApiRequest<EthicalMedicine> = {
        version: "v2",
        module: "core",
        path: `/master/ethical_medicines/${id}`,
        method: "get",
      };

      return sendRequest(fetchRequest);
    },
    downloadHotCode: (startDate: DateTime) => {
      const downloadRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/downloads/hot_code",
        method: "post",
        parameters: {
          startDate: startDate.toISODate(),
        },
      };

      return sendRequest(downloadRequest);
    },
    importHotCode: () => {
      const importRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/imports/hot_code",
        method: "post",
      };

      return sendRequest(importRequest);
    },
    downloadMedhot: (startDate: DateTime) => {
      const downloadRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/downloads/medhot",
        method: "post",
        parameters: {
          startDate: startDate.toISODate(),
        },
      };

      return sendRequest(downloadRequest);
    },
    importMedhot: () => {
      const importRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/imports/medhot",
        method: "post",
      };
      return sendRequest(importRequest);
    },
    downloadIryohoken: (startYear: number) => {
      const downloadRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/downloads/iryohoken",
        method: "post",
        parameters: {
          startYear: startYear.toString(),
        },
      };

      return sendRequest(downloadRequest);
    },
    importIryohoken: () => {
      const importRequest: ApiRequest<void> = {
        version: "v2",
        module: "core",
        path: "/master/ethical_medicines/imports/iryohoken",
        method: "post",
      };
      return sendRequest(importRequest);
    },
  };
}
