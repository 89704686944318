

































import { computed, defineComponent, ref, watch } from "@vue/composition-api";

import { UnknownSdcvanDeliveryDRecord, useUnknownSdcvanDeliveryDRecordsStore } from "@/store/unknownSdcvanDeliveryDRecordsStore";
import { OrderableItem, StockableItem, useStockableItemsStore } from "@/store/stockableItemsStore";

interface Row extends UnknownSdcvanDeliveryDRecord {
  stockableItem: StockableItem | null;
  orderableItem: OrderableItem | null;
}

export default defineComponent({
  name: "UnknownSdcvanDeliveryDRecords",
  setup() {
    const stockableItemsStore = useStockableItemsStore();
    const unknownSdcvanDeliveryDRecordsStore = useUnknownSdcvanDeliveryDRecordsStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "orderableItem", label: "該当医薬品" },
      { key: "itemCodeType", label: "コードタイプ" },
      { key: "itemCode", label: "アイテムコード" },
      { key: "name", label: "名前" },
      { key: "notFoundAt", label: "判定失敗時刻" },
      { key: "ignored", label: "無視対象" },
    ];

    async function fetchUnknownSdcvanDeliveryDRecords(page: number) {
      unknownSdcvanDeliveryDRecordsStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchUnknownSdcvanDeliveryDRecords(page);
    });

    unknownSdcvanDeliveryDRecordsStore.findList({});

    const rows = computed(() => {
      const rows: Row[] = [];
      for (const unknownSdcvanDeliveryDRecord of unknownSdcvanDeliveryDRecordsStore.list.value) {
        const row: Row = {
          ...unknownSdcvanDeliveryDRecord,
          stockableItem: null,
          orderableItem: null,
        };
        const stockableItem = stockableItemsStore.getByJanCode(unknownSdcvanDeliveryDRecord.itemCode);
        if (stockableItem) {
          row.stockableItem = stockableItem;
          row.orderableItem =
            stockableItem.orderableItems.find((item) => {
              return item.janCode === unknownSdcvanDeliveryDRecord.itemCode;
            }) || null;
        }
        rows.push(row);
      }
      return rows;
    });

    function toggleIgnore(unknown: UnknownSdcvanDeliveryDRecord) {
      unknownSdcvanDeliveryDRecordsStore.patch({
        id: unknown.id,
        ignored: !unknown.ignored,
      });
    }

    return {
      fields,
      rows,
      isFindingUnknownSdcvanDeliveryDRecords: unknownSdcvanDeliveryDRecordsStore.isFindingList,
      totalCount: unknownSdcvanDeliveryDRecordsStore.totalCount,
      totalPages: unknownSdcvanDeliveryDRecordsStore.totalPages,
      currentPage,
      toggleIgnore,
    };
  },
});
