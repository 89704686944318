<template>
  <CCard>
    <CCardHeader>
      詳細情報
      <div class="card-header-actions">
        <CLink class="card-header-action btn-setting text-primary" :to="{ name: '薬局会社編集', params: { id: id } }">
          <CIcon name="cil-pencil" />
          編集する
        </CLink>
      </div>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput label="薬局会社ID" :value="company.id" horizontal plaintext disabled />
        <CInput label="名前" :value="company.name" horizontal plaintext disabled />
        <CInput label="所属薬局数" :value="company.pharmacies_count" horizontal plaintext disabled />
        <CTextarea label="ノート" :value="company.note" horizontal plaintext disabled />
        <CInput label="作成日" :value="company.created_at" horizontal plaintext disabled />
        <CInput label="更新日" :value="company.updated_at" horizontal plaintext disabled />
        <CInput label="作成者" :value="company.created_by" horizontal plaintext disabled />
        <CInput label="更新者" :value="company.updated_by" horizontal plaintext disabled />
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "CompanyDetails",
  data() {
    return {
      id: null,
      company: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchCompany();
  },
  methods: {
    async fetchCompany() {
      if (this.id) this.company = (await this.$axios.get(`/api/v2/core/admin/companies/${this.id}`)).data;
    },
  },
};
</script>
