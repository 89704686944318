import { ethicalMedicineApi } from "@/api/master/ethicalMedicine";
import { RootState } from "@/store";
import { ActionUpdateMedicinesPayload } from "@/store/domain/master/ethicalMedicine";
import Axios from "axios";
import { Module } from "vuex";

export type UIMasterEthicalMedicineListStoreState = {
  isFirstFetched: boolean;
  isFetching: boolean;
  medicineIds: number[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
  includeInactive: boolean;
  query: string;
};

export type ActionUpdateCurrentPagePayload = {
  currentPage: number;
};

export type AccountUpdateIncludeInactivePayload = {
  includeInactive: boolean;
};

export type ActionUpdateQueryPayload = {
  query: string;
};

type MutationsUpdateStatePayload = {
  medicineIds: number[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
};

type MutationsUpdateCurrentPagePayload = {
  currentPage: number;
};

type MutationsUpdateIncludeInactivePayload = {
  includeInactive: boolean;
};

type MutationsUpdateQueryPayload = {
  query: string;
};

const apiClient = ethicalMedicineApi();

export const UIMasterEthicalMedicineListStore: Module<UIMasterEthicalMedicineListStoreState, RootState> = {
  namespaced: true,
  state: {
    isFirstFetched: false,
    isFetching: false,
    medicineIds: [],
    currentPage: 1,
    totalPages: 0,
    totalCount: 0,
    includeInactive: false,
    query: "",
  },
  mutations: {
    startFetching(state) {
      state.isFetching = true;
    },
    finishFetching(state) {
      state.isFetching = false;
    },
    updateCurrentPage(state, payload: MutationsUpdateCurrentPagePayload) {
      state.currentPage = payload.currentPage;
    },
    updateState(state, payload: MutationsUpdateStatePayload) {
      state.isFirstFetched = true;
      state.currentPage = payload.currentPage;
      state.medicineIds = payload.medicineIds;
      state.totalCount = payload.totalCount;
      state.totalPages = payload.totalPages;
    },
    updateIncludeInactive(state, payload: MutationsUpdateIncludeInactivePayload) {
      state.includeInactive = payload.includeInactive;
    },
    updateQuery(state, payload: MutationsUpdateQueryPayload) {
      state.query = payload.query;
    },
  },
  actions: {
    cancelFetchingAll() {
      apiClient.cancelAll();
    },
    async fetchAll(context) {
      try {
        context.commit("startFetching");

        const paginatedMedicines = await apiClient.fetchAll(context.state.currentPage, context.state.includeInactive, context.state.query);

        const medicines = paginatedMedicines.data;

        const updateMedicinesPayload: ActionUpdateMedicinesPayload = {
          medicines: medicines,
        };
        await context.dispatch("domain/master/ethicalMedicine/updateMedicines", updateMedicinesPayload, { root: true });

        const updateStatePayload: MutationsUpdateStatePayload = {
          medicineIds: medicines.map((medicine) => medicine.id),
          currentPage: paginatedMedicines.currentPage,
          totalPages: paginatedMedicines.totalPages,
          totalCount: paginatedMedicines.totalCount,
        };
        context.commit("updateState", updateStatePayload);
      } catch (error) {
        if (!Axios.isCancel(error)) {
          console.error(error);
          throw error;
        }
      } finally {
        context.commit("finishFetching");
      }
    },
    updateCurrentPage(context, payload: ActionUpdateCurrentPagePayload) {
      context.dispatch("cancelFetchingAll");

      const updateCurrentPagePayload: MutationsUpdateCurrentPagePayload = {
        currentPage: payload.currentPage,
      };
      context.commit("updateCurrentPage", updateCurrentPagePayload);

      context.dispatch("fetchAll");
    },
    updateIncludeInactive(context, payload: AccountUpdateIncludeInactivePayload) {
      context.dispatch("cancelFetchingAll");

      const updateCurrentPagePayload: MutationsUpdateCurrentPagePayload = {
        currentPage: 1,
      };
      context.commit("updateCurrentPage", updateCurrentPagePayload);

      const updateIncludeInactivePayload: MutationsUpdateIncludeInactivePayload = {
        includeInactive: payload.includeInactive,
      };
      context.commit("updateIncludeInactive", updateIncludeInactivePayload);

      context.dispatch("fetchAll");
    },
    updateQuery(context, payload: ActionUpdateQueryPayload) {
      context.dispatch("cancelFetchingAll");

      const updateCurrentPagePayload: MutationsUpdateCurrentPagePayload = {
        currentPage: 1,
      };
      context.commit("updateCurrentPage", updateCurrentPagePayload);

      const updateQueryPayload: MutationsUpdateQueryPayload = {
        query: payload.query,
      };
      context.commit("updateQuery", updateQueryPayload);

      context.dispatch("fetchAll");
    },
  },
};
