<template>
  <CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/">
      <div v-if="!minimize" class="text-value-lg">CSポータル</div>
      <CImg v-else src="/img/mini-logo.png" height="40" />
    </CSidebarBrand>

    <CSidebarNav>
      <!-- <CSidebarNavTitle>メドオーダーV1</CSidebarNavTitle> -->
      <!-- <CSidebarNavItem name="在庫数修正" :to="{ name: '在庫数修正' }" icon="cil-file" /> -->

      <CSidebarNavTitle>コアV2</CSidebarNavTitle>
      <CSidebarNavItem name="問合せ一覧" :to="{ name: '問合せ一覧' }" icon="cil-chat-bubble" />
      <CSidebarNavDropdown name="薬局系マスター" icon="cil-info">
        <CSidebarNavItem name="薬局一覧" :to="{ name: '薬局一覧' }" icon="cil-house" />
        <CSidebarNavItem name="ユーザ一覧" :to="{ name: 'ユーザ一覧' }" icon="cil-user" />
        <CSidebarNavItem name="薬局会社一覧" :to="{ name: '薬局会社一覧' }" icon="cil-building" />
        <CSidebarNavItem name="近隣グループ一覧" :to="{ name: '近隣グループ一覧' }" icon="cil-people" />
        <CSidebarNavDivider />
        <CSidebarNavItem name="開設許可証の期限管理" :to="{ name: '開設許可証の期限管理' }" icon="cil-calendar" />
        <CSidebarNavDivider />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown name="その他マスター" icon="cil-file">
        <CSidebarNavItem name="卸会社一覧" :to="{ name: '卸会社一覧' }" icon="cil-building" />
        <CSidebarNavItem name="医療用医薬品一覧" :to="{ name: '医療用医薬品一覧' }" icon="cil-eyedropper" />
        <CSidebarNavItem name="外部マスターデータ" :to="{ name: '外部マスターデータ' }" icon="cil-file" />
        <CSidebarNavDivider />
      </CSidebarNavDropdown>
      <CSidebarNavItem name="NSIPS一覧" :to="{ name: 'NSIPS一覧' }" icon="cil-file" />
      <CSidebarNavItem name="マニュアル一覧" :to="{ name: 'マニュアル一覧' }" icon="cil-info" />
      <CSidebarNavItem name="Embulk管理" :to="{ name: 'Embulk管理' }" icon="cil-loop-circular" />

      <CSidebarNavTitle>開発用</CSidebarNavTitle>
      <CSidebarNavItem name="プロフィール" :to="{ name: 'プロフィール' }" icon="cil-user" />
      <CSidebarNavItem name="JWT" :to="{ name: 'JWT' }" icon="cil-settings" />
      <CSidebarNavItem name="在庫数比較" :to="{ name: '在庫数比較' }" icon="cil-settings" />
    </CSidebarNav>
    <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>
</template>

<script>
export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      show: "responsive",
    };
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  },
};
</script>
