<template>
  <div>
    <CTabs :active-tab="activeTab">
      <CTab title="薬局会社の詳細" :to="{ name: '薬局会社の詳細', params: { id: id } }">
        <router-view />
      </CTab>
      <CTab title="薬局会社に所属する薬局一覧" :to="{ name: '薬局会社の薬局一覧', params: { id: id } }">
        <router-view />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
export default {
  name: "CompanyTabs",
  data() {
    return {
      id: null,
      fields: ["id", "name", "micode", "created_at", "actions"],
    };
  },
  computed: {
    activeTab() {
      return ["薬局会社の詳細", "薬局会社の薬局一覧"].indexOf(this.$route.name);
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>
