<template>
  <div>
    <LoadingAlert v-if="isFirstLoading" />
    <CCard v-else>
      <CCardHeader> {{ total_count }} 件の卸会社を表示 </CCardHeader>
      <CCardBody>
        <CForm @submit.prevent="searchDealers">
          <CInput v-model="query" placeholder="卸会社名で検索">
            <template #append><CButton type="submit" color="info">検索</CButton></template>
          </CInput>
        </CForm>
        <CDataTable
          hover
          bordered
          fixed
          responsive
          :loading="isLoading"
          :items="dealers"
          :fields="fields"
          :sorter="sorter"
          :sorter-value.sync="sorterValue"
        >
          <template #name="{item : dealer}">
            <td>
              <CLink :to="{ name: '卸会社情報', params: { id: dealer.id } }">{{ dealer.name }}</CLink>
            </td>
          </template>
          <template #actions="{item : dealer}">
            <td>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :to="{ name: '卸会社情報', params: { id: dealer.id } }">
                詳細
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination v-show="dealers.length" align="center" responsive :active-page.sync="current_page" :pages="total_pages" :limit="10" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import LoadingAlert from "src/components/shared/LoadingAlert";

export default {
  name: "Dealers",
  components: { LoadingAlert },
  data() {
    return {
      isFirstLoading: true,
      isLoading: false,
      query: null,
      dealers: [],
      api_fields: ["id", "code", "name", "prefecture_id"],
      sorter: { external: true, resetable: false },
      sorterValue: { column: "id", asc: true },
      current_page: 1,
      total_pages: 1,
      total_count: 0,
    };
  },
  computed: {
    fields() {
      const items = [
        { key: "prefecture_name", sorter: false },
        { key: "actions", sorter: false },
      ];
      return this.api_fields.concat(items);
    },
    sort() {
      return this.sorterValue.asc ? this.sorterValue.column : `-${this.sorterValue.column}`;
    },
  },
  watch: {
    $route() {
      this.current_page = parseInt(this.$route.query.page) || 1;
    },
    current_page(page) {
      if (!this.$route.query.page || parseInt(this.$route.query.page) !== page) {
        this.$router.push({ query: { page: `${page}` } });
      }
      this.fetchDealers();
    },
    sorterValue() {
      this.fetchDealers();
    },
  },
  async created() {
    this.current_page = parseInt(this.$route.query.page) || 1;
    await this.fetchDealers();
    this.isFirstLoading = false;
  },
  methods: {
    async fetchDealers() {
      if (this.isLoading) return;

      this.isLoading = true;
      const req_params = { page: this.current_page, query: this.query, sort: this.sort };
      const response = await this.$axios.get("/api/v2/core/master/dealers", { params: req_params });
      this.dealers = response.data;
      this.current_page = Number(response.headers["x-current-page"]);
      this.total_pages = Number(response.headers["x-total-pages"]);
      this.total_count = Number(response.headers["x-total-count"]);
      this.isLoading = false;
    },
    searchDealers() {
      this.current_page = 1;
      this.fetchDealers();
    },
  },
};
</script>
