





















import { ChatNameForm } from "@/models/lib/vue-advanced-chat/chatNameForm";
import axios from "@/plugins/axios";
import { CInput } from "@coreui/vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "InquiryTitleModal",
  props: {
    title: {
      type: String,
      default: null,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const nameRef = ref<InstanceType<typeof CInput>>();

    const name = ref<string>(null);
    const isLoading = ref<boolean>(false);
    const errorMessages = ref<Array<string>>([]);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const shouldDisableSubmitButton = computed(() => !name.value || isLoading.value);

    watch(name, () => {
      // 入力しているメッセージが変更されたら、エラーメッセージはクリアする
      errorMessages.value = [];
    });
    watch(propsOpenModal, (value: boolean) => {
      if (value) {
        // 開く時に props で上書きする
        name.value = props.title;
        setTimeout(() => nameRef.value?.$el.getElementsByTagName("input")[0].focus(), 100);
      } else {
        // 閉じるときに初期化する
        isLoading.value = false;
        errorMessages.value = [];
      }
    });

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function changeName() {
      errorMessages.value = [];

      try {
        isLoading.value = true;

        if (!props.roomId || !name.value) return;
        const url = `/api/v2/core/admin/inquiry_rooms/${props.roomId}`;
        const data: ChatNameForm = { name: name.value };
        await axios.patch(url, data);
        hideModal();
      } catch (e) {
        console.log(e);
        errorMessages.value = ["メッセージ送信でエラーが発生しました。"];
      } finally {
        isLoading.value = false;
      }
    }

    return {
      nameRef,
      name,

      isLoading,
      errorMessages,
      propsOpenModal,
      shouldDisableSubmitButton,

      changeName,
      hideModal,
    };
  },
});
