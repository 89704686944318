import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { DateTime } from "luxon";

export interface MedisOption {
  id: number;
  fileName: string;
  fileDate: string;
}

interface MedisOptionsFindListParams {
  fromDate?: DateTime;
  page?: number;
}

interface MedisOptionsFindListRequestParams {
  fromDate?: DateTime;
  page?: number;
}

function createMedisOptionsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisOption>(),
    ...initStoreActionFindListState<MedisOption>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_options`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisOption, MedisOptionsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_options`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisOptionsFindListRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisOptionsStore, "medisOptionsStore");

export const provideMedisOptionsStore = provider.provideStore;
export const useMedisOptionsStore = provider.useStore;

export function useMedisOption(medisOptionId: number, withFetch?: boolean, noCache?: boolean) {
  const medisOptionsStore = useMedisOptionsStore();
  if (withFetch) {
    medisOptionsStore.fetch(medisOptionId, noCache);
  }
  let medisOption: MedisOption | null = null;

  return computed(() => {
    if (medisOption && !noCache) {
      return medisOption;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisOptionsStore.isFetching.value;
    medisOption = medisOptionsStore.get(medisOptionId);
    return medisOption;
  });
}
