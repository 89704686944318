import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { actionFindListCommitDeleteItem, createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { createActionPatch, initStoreActionPatchState } from "@/store/actions/createActionPatch";

export const StockableItemImportRequestCodeType = {
  HotCode: "hot_code",
  ReceiptCode: "receipt_code",
} as const;
export type StockableItemImportRequestCodeType = typeof StockableItemImportRequestCodeType[keyof typeof StockableItemImportRequestCodeType];

export const StockableItemImportRequestState = {
  Open: "open",
  Accepted: "accepted",
  Rejected: "rejected",
} as const;
export type StockableItemImportRequestState = typeof StockableItemImportRequestState[keyof typeof StockableItemImportRequestState];

export interface StockableItemImportRequest {
  id: number;
  codeType: string;
  code: string;
  state: StockableItemImportRequestState;
}

interface StockableItemImportRequestsFindListParams {
  state?: StockableItemImportRequestState;
  page?: number;
}

interface StockableItemImportRequestsFindListRequestParams {
  state?: StockableItemImportRequestState;
  page?: number;
}

interface StockableItemImportRequestsPatchParams {
  id: number;
  state?: StockableItemImportRequestState;
}

interface StockableItemImportRequestsPatchRequestData {
  state?: StockableItemImportRequestState;
}

function createStockableItemImportRequestsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<StockableItemImportRequest>(),
    ...initStoreActionFindListState<StockableItemImportRequest>(),
    ...initStoreActionPatchState(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/stockable_item_import_requests`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<StockableItemImportRequest, StockableItemImportRequestsFindListParams>(
    state,
    context.root.$httpMedorder,
    () => {
      return `/api/v2/admin/stockable_item_import_requests`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: StockableItemImportRequestsFindListRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  const actionPatch = createActionPatch<StockableItemImportRequest, StockableItemImportRequestsPatchParams>(
    context,
    state,
    (context, params) => {
      const url = `/api/v2/admin/stockable_item_import_requests/${params.id}`;
      const data: StockableItemImportRequestsPatchRequestData = {};
      if (params.state) {
        data.state = params.state;
      }
      return context.root.$httpMedorder.patch(url, data);
    },
    {
      afterEndPatch: (item) => {
        actionFindListCommitDeleteItem(state, item.id);
      },
    }
  );

  function deleteFromList(code: string) {
    const index = state.list.findIndex((item) => {
      return item.code === code;
    });
    if (index === -1) {
      return;
    }
    state.list.splice(index, 1);
  }

  return {
    ...actionFetch,
    ...actionFind,
    ...actionPatch,
    deleteFromList,
  };
}

const provider = createStoreProvider(createStockableItemImportRequestsStore, "stockableItemImportRequestsStore");

export const provideStockableItemImportRequestsStore = provider.provideStore;
export const useStockableItemImportRequestsStore = provider.useStore;
