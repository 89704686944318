import { EthicalMedicine } from "@/models/ethicalMedicine";
import { RootState } from "@/store";
import { Module } from "vuex";

export type DomainMasterEthicalMedicineStoreState = {
  medicines: {
    [index: number]: EthicalMedicine;
  };
};

export type ActionUpdateMedicinesPayload = {
  medicines: EthicalMedicine[];
};

type MutationsUpdateMedicinesPayload = {
  medicines: EthicalMedicine[];
};

export const DomainMasterEthicalMedicineStore: Module<DomainMasterEthicalMedicineStoreState, RootState> = {
  namespaced: true,
  state: {
    medicines: {},
  },
  getters: {
    getById: (state) => (id: number | string) => {
      return state.medicines[Number(id)];
    },
  },
  mutations: {
    updateMedicines(state, payload: MutationsUpdateMedicinesPayload) {
      payload.medicines.forEach((medicine) => {
        state.medicines[medicine.id] = medicine;
      });
    },
  },
  actions: {
    updateMedicines(context, payload: ActionUpdateMedicinesPayload) {
      context.commit("updateMedicines", payload);
    },
  },
};
