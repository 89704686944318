<template>
  <div>
    <CTabs :active-tab="activeTab">
      <CTab title="卸会社の詳細" :to="{ name: '卸会社の詳細', params: { id: id } }">
        <router-view />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
export default {
  name: "DealerTabs",
  data() {
    return {
      id: null,
    };
  },
  computed: {
    activeTab() {
      return ["卸会社の詳細"].indexOf(this.$route.name);
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>
