














import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SidekiqLinks",
  setup() {
    const links = ref<object[]>([
      {
        service: "core",
        env: `${process.env.VUE_APP_ENV}`,
        link: `${process.env.VUE_APP_V2_API_CORE_HOST}/sidekiq`,
      },
      {
        service: "medorder",
        env: `${process.env.VUE_APP_ENV}`,
        link: `${process.env.VUE_APP_V2_API_MEDORDER_HOST}/sidekiq`,
      },
      {
        service: "medshare",
        env: `${process.env.VUE_APP_ENV}`,
        link: `${process.env.VUE_APP_V2_API_MEDSHARE_HOST}/sidekiq`,
      },
    ]);
    const linkFields = ref<object[]>([
      { key: "service", label: "サービス" },
      { key: "env", label: "環境" },
      { key: "link", label: "管理画面リンク" },
    ]);

    return {
      links,
      linkFields,
    };
  },
});
