




import { ManualCategory } from "@/models/ManualCategory";
import axios from "@/plugins/axios";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "ManualBreadcrumb",
  props: {
    categoryId: {
      type: String,
      default: undefined,
    },
    lastBreadcrumb: {
      type: Object,
      default: undefined,
    },
  },
  setup(props) {
    const rootBreadcrumbs = [{ text: "マニュアル", to: { name: "マニュアル一覧" } }];
    const breadcrumbs = ref<Array<object>>(rootBreadcrumbs);
    const propsCategoryId = computed(() => props.categoryId);

    async function fetchManualCategory(categoryId: string | undefined) {
      if (!categoryId) {
        breadcrumbs.value = rootBreadcrumbs;
      } else {
        const response = await axios.get(`/api/v2/core/admin/manual_categories/${categoryId}`);
        const category = new ManualCategory(response.data);
        breadcrumbs.value = category.breadcrumbs();
      }

      if (props.lastBreadcrumb) breadcrumbs.value.push(props.lastBreadcrumb);
    }

    watch(propsCategoryId, (categoryId: string | undefined) => {
      fetchManualCategory(categoryId);
    });

    return {
      breadcrumbs,
    };
  },
});
