import { render, staticRenderFns } from "./InquiryTitleModal.vue?vue&type=template&id=68a969c1&"
import script from "./InquiryTitleModal.vue?vue&type=script&lang=ts&"
export * from "./InquiryTitleModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports