import { ChatRoomClass } from "@/models/lib/vue-advanced-chat/chatRoomClass";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { InquiryRoomsFindListParams } from "@/store/interfaces/inquiryRoomsFindListParams";
import { InquiryRoomsFindListRequestParams } from "@/store/interfaces/inquiryRoomsFindListRequestParams";
import { createStoreProvider } from "@/store/utils";
import { SetupContext } from "@vue/composition-api";
import Vue from "vue";

function createInquiryRoomsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFindListState<ChatRoomClass>(),
  });

  const actionFindList = createActionFindList<ChatRoomClass, InquiryRoomsFindListParams>(
    state,
    context.root.$httpCore,
    () => "/api/v2/admin/inquiry_rooms",
    {
      createRequestParams: (params) => {
        const requestParams: InquiryRoomsFindListRequestParams = {};
        if (params.roomId) requestParams.room_id = params.roomId;
        if (params.pharmacyId) requestParams.pharmacy_id = params.pharmacyId;
        if (params.status) requestParams.status = params.status;
        return requestParams;
      },
    }
  );

  return {
    ...actionFindList,
  };
}

const provider = createStoreProvider(createInquiryRoomsStore, "inquiryRoomsStore");

export const provideInquiryRoomsStore = provider.provideStore;
export const useInquiryRoomsStore = provider.useStore;
