import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { collectNullableIds, createStoreProvider } from "@/store/utils";
import { fetchBase } from "@/store/actions/createActionFetchBase";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { initStoreActionCreateState, createActionCreate } from "@/store/actions/createActionCreate";
import { useStockableItemsStore } from "./stockableItemsStore";

interface OrderableItemAttributes {
  name?: string;
}

export interface OrderableItemImport {
  id: number;
  stockableItemId?: number;
  operation?: string;
  orderableItemId?: number;
  attributes?: OrderableItemAttributes;
  invalidReason?: string;
}

interface OrderableItemImportsCreateParams {
  orderableItemFixtureId?: number;
}

interface OrderableItemImportsCreateData {
  orderable_item_fixture_id?: number;
}

function createOrderableItemImportStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<OrderableItemImport>(),
    ...initStoreActionCreateState(),
    orderableItemFixtureIdItemMap: new Map<number, OrderableItemImport>(),
  });

  const stockableItemsStore = useStockableItemsStore();

  function fetchStockableItems(items: OrderableItemImport[]) {
    const stockableItemIds = collectNullableIds(items, (item) => {
      return item.stockableItemId;
    });
    return stockableItemsStore.fetch(stockableItemIds);
  }

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/stockable_items/import`;
  });
  actionFetchBuilder.addAfterReceiveItems(async (items) => {
    await fetchStockableItems(items);
  });
  const actionFetch = actionFetchBuilder.build();

  async function fetchByOrderableItemFixtureId(orderableItemFixtureId: number, force?: boolean) {
    return fetchBase<OrderableItemImport, number, OrderableItemImport>(
      orderableItemFixtureId,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/orderable_items/import";
      },
      "orderable_item_fixture_id",
      () => {
        return state.orderableItemFixtureIdItemMap;
      },
      (map, items, orderableItemFixtureIds) => {
        for (const item of items) {
          map.set(orderableItemFixtureIds[0], item);
        }
      },
      async (items) => {
        await fetchStockableItems(items);
      },
      null
    );
  }

  function getByOrderableItemFixtureId(orderableItemFixtureId: number) {
    return state.orderableItemFixtureIdItemMap.get(orderableItemFixtureId) || null;
  }

  const actionCreate = createActionCreate<OrderableItemImport, OrderableItemImportsCreateParams>(context, state, (context, params) => {
    const url = "/api/v2/admin/orderable_items/import";
    const data: OrderableItemImportsCreateData = {};
    if (params.orderableItemFixtureId) {
      data.orderable_item_fixture_id = params.orderableItemFixtureId;
    }
    return context.root.$httpMedorder.post(url, data);
  });

  return {
    ...actionFetch,
    fetchByOrderableItemFixtureId,
    getByOrderableItemFixtureId,
    ...actionCreate,
  };
}

const provider = createStoreProvider(createOrderableItemImportStore, "orderableItemImportStore");

export const provideOrderableItemImportsStore = provider.provideStore;
export const useOrderableItemImportsStore = provider.useStore;
