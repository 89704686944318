










































import { ref, defineComponent, watch } from "@vue/composition-api";

import { useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";

export default defineComponent({
  name: "StockableItemFixtures",
  setup() {
    const stockableItemFixturesStore = useStockableItemFixturesStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "name", label: "医薬品名" },
      { key: "unit", label: "単位" },
      { key: "price", label: "薬価" },
      { key: "yjCode", label: "YJコード" },
      { key: "receiptCode", label: "レセプト電算コード" },
      { key: "genericId", label: "後発品ID" },
      { key: "generic", label: "後発品" },
      { key: "interimPeriodOn", label: "経過措置期日" },
      { key: "disableStock", label: "在庫管理無効" },
    ];

    async function fetchStockableItemFixtures(page: number) {
      stockableItemFixturesStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchStockableItemFixtures(page);
    });

    return {
      fields,
      stockableItemFixtures: stockableItemFixturesStore.list,
      isFindingStockableItemFixtures: stockableItemFixturesStore.isFindingList,
      totalCount: stockableItemFixturesStore.totalCount,
      totalPages: stockableItemFixturesStore.totalPages,
      currentPage,
    };
  },
});
