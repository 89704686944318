<template>
  <div>
    <PcAppLink />
    <SidekiqLinks />
  </div>
</template>

<script>
import PcAppLink from "@/components/shared/home/PcAppLink";
import SidekiqLinks from "@/components/shared/home/SidekiqLinks";

export default {
  name: "Home",
  components: { PcAppLink, SidekiqLinks },
};
</script>
