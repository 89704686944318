import { SetupContext, computed } from "@vue/composition-api";
import { AxiosResponse } from "axios";

import { StoreItem, toStoreItems } from "@/store/utils";
import { StoreActionFindListState, actionFindListCommitAddItem } from "@/store/actions/createActionFindList";

interface StoreActionPutState {
  isPutting: boolean;
}

interface RequstFunc<P> {
  (context: SetupContext, params: P): Promise<AxiosResponse>;
}

interface Options<T extends StoreItem, P> {
  findListState?: StoreActionFindListState<T>;
  afterEndPut?: (item: T, params: P) => void;
}

export function initStoreActionPutState() {
  return {
    isPutting: false,
  };
}

function commitStartPut<T extends StoreItem>(state: StoreActionPutState) {
  state.isPutting = true;
}
function commitEndPut<T extends StoreItem>(state: StoreActionPutState) {
  state.isPutting = false;
}

async function putBase<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionPutState,
  params: P,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  // TODO: 二重リクエスト防止
  commitStartPut(state);
  let response = null;
  try {
    response = await requestFunc(context, params);
  } finally {
    commitEndPut(state);
  }
  const item: T = toStoreItems(response.data);

  if (options) {
    if (options.findListState) {
      actionFindListCommitAddItem(options.findListState, item);
    }
    if (options.afterEndPut) {
      options.afterEndPut(item, params);
    }
  }

  return item;
}

export function createActionPut<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionPutState,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  async function put(params: P) {
    return putBase(context, state, params, requestFunc, options);
  }

  const isPutting = computed(() => {
    return state.isPutting;
  });

  return {
    put,
    isPutting,
  };
}
