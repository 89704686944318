

























import { defineComponent, reactive } from "@vue/composition-api";

import { StockableItemFixturesCreateParams, useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";

export default defineComponent({
  name: "StockableItemFixtureNew",
  setup(props, context) {
    const stockableItemFixturesStore = useStockableItemFixturesStore();

    const params = reactive<StockableItemFixturesCreateParams>({});
    if (context.root.$route.query.yjCode) {
      params.yjCode = context.root.$route.query.yjCode as string;
    }

    async function create() {
      await stockableItemFixturesStore.create(params);
      context.root.$router.push({ name: "在庫医薬品マスター手動登録" });
    }

    return {
      params,
      create,
    };
  },
});
