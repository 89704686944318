<template>
  <div>
    <multiselect
      v-model="selectedPharmacy"
      class="mb-3"
      :options="pharmacies"
      :custom-label="customLabel"
      placeholder="薬局名で検索"
      label="name"
      track-by="name"
      :loading="isSearching"
      @search-change="searchPharmacies"
    >
      <span slot="noResult">薬局が見つかりません</span>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "SearchPharmacy",
  components: { Multiselect },
  props: {
    selected: {
      type: Object,
      default: null,
    },
    apiFields: {
      type: Array,
      default: () => ["id", "name", "micode", "company_id"],
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      selectedPharmacy: this.selected,
      isSearching: false,
      toggleDropdown: false,
      pharmacies: [],
      sorterValue: { column: "updated_at", asc: false },
    };
  },
  computed: {
    sort() {
      return this.sorterValue.asc ? this.sorterValue.column : `-${this.sorterValue.column}`;
    },
  },
  watch: {
    selectedPharmacy() {
      this.$emit("update:selected", Object.assign({}, this.selectedPharmacy));
    },
  },
  async created() {
    await this.searchPharmacies();
  },
  methods: {
    customLabel({ name, micode }) {
      return `${name} [${micode}]`;
    },
    async searchPharmacies(query) {
      if (this.isSearching) return;

      this.isSearching = true;
      const req_params = {
        query: query,
        fields: this.apiFields.concat(["id", "company_id"]).join(","),
        sort: this.sort,
      };
      const response = await this.$axios
        .get(`/api/v2/core/admin/pharmacies`, { params: req_params })
        .catch(() => (this.isSearching = false));
      this.pharmacies = response.data.filter(this.filter);
      this.isSearching = false;
    },
  },
};
</script>
