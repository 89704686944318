import { ChatMessageClass } from "@/models/lib/vue-advanced-chat/chatMessageClass";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { InquiryMessagesFindListParams } from "@/store/interfaces/inquiryMessagesFindListParams";
import { createStoreProvider } from "@/store/utils";
import { SetupContext } from "@vue/composition-api";
import Vue from "vue";

function createInquiryMessagesStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFindListState<ChatMessageClass>(),
  });

  const actionFindList = createActionFindList<ChatMessageClass, InquiryMessagesFindListParams>(
    state,
    context.root.$httpCore,
    (params) => `/api/v2/admin/inquiry_rooms/${params.roomId}/messages`
  );

  return {
    ...actionFindList,
  };
}

const provider = createStoreProvider(createInquiryMessagesStore, "inquiryMessagesStore");

export const provideInquiryMessagesStore = provider.provideStore;
export const useInquiryMessagesStore = provider.useStore;
