










import { reactive, toRefs, computed, defineComponent, PropType, ref, SetupContext, onMounted, watch } from "@vue/composition-api";
import { EthicalMedicine } from "@/models/ethicalMedicine";
import { ActionFetchPayload } from "@/store/ui/master/ethicalMedicine/show";

const storePrefix = "ui/master/ethicalMedicine/show";

export default defineComponent({
  props: {
    id: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const store = context.root.$store;

    const state = reactive({
      activeTab: computed(() => 0),
      isFetched: computed(() => store.state.ui.master.ethicalMedicine.show.isFetched),
      medicine: ref<EthicalMedicine | null>(null),
      title: computed((): string => `${state.medicine?.brandName ?? "医療用医薬品"}の基本情報`),
    });

    const fetch = () => {
      const payload: ActionFetchPayload = {
        id: props.id,
      };
      store.dispatch(`${storePrefix}/fetch`, payload);
    };

    onMounted(() => fetch());

    watch(
      () => store.state.ui.master.ethicalMedicine.show.isFetched,
      () => {
        state.medicine = store.getters["domain/master/ethicalMedicine/getById"](props.id);
      }
    );

    return { ...toRefs(state) };
  },
});
