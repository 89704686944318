<template>
  <div>
    <CCard>
      <CCardBody>
        <SearchPharmacy :selected.sync="selectedPharmacy" :api-fields="apiFields" :filter="filterOutBelongingGroup" />

        <CDataTable hover bordered fixed :items="listingPharmacies" :fields="fields" :loading="isLoading">
          <template #name="{item : pharmacy}">
            <td>
              <CLink :to="{ name: '薬局情報', params: { id: pharmacy.id } }">{{ pharmacy.name }}</CLink>
            </td>
          </template>
          <template #status="{item : pharmacy}">
            <td><span v-if="isBelongingGroup(pharmacy.id)">所属中</span></td>
          </template>
          <template #actions="{item : pharmacy}">
            <td>
              <CButton v-if="isBelongingGroup(pharmacy.id)" color="danger" class="mr-1 mb-1" size="sm" @click="removePharmacy(pharmacy.id)">
                削除
              </CButton>
              <CButton v-else color="primary" class="mr-1 mb-1" size="sm" @click="addPharmacy(pharmacy.id)">
                追加
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SearchPharmacy from "src/components/shared/search/SearchPharmacy";

export default {
  name: "GroupPharmacies",
  components: { SearchPharmacy },
  data() {
    return {
      id: null,
      group: {},
      listingPharmacies: [], // テーブル表示用の一時的な薬局一覧
      selectedPharmacy: null,
      apiFields: ["id", "name", "micode", "address1"],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      return this.apiFields.concat(["status", "actions"]);
    },
    pharmacyIds() {
      return this.group.pharmacies ? this.group.pharmacies.map((p) => p.id) : [];
    },
  },
  watch: {
    selectedPharmacy() {
      this.listingPharmacies = [this.selectedPharmacy].concat(this.listingPharmacies);
      this.listingPharmacies = this.listingPharmacies.filter((p, i, array) => array.map((x) => x.id).indexOf(p.id) === i);
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchGroup();
    this.listingPharmacies = this.group.pharmacies;
  },
  methods: {
    async fetchGroup() {
      if (this.isLoading) return;

      this.isLoading = true;
      if (this.id) this.group = (await this.$axios.get(`/api/v2/core/admin/groups/${this.id}`)).data;
      this.isLoading = false;
    },
    async addPharmacy(pharmacyId) {
      const req_params = { pharmacy_id: pharmacyId };
      await this.$axios.post(`/api/v2/core/admin/groups/${this.id}/pharmacy`, req_params);
      await this.fetchGroup();
    },
    async removePharmacy(pharmacyId) {
      const req_params = { pharmacy_id: pharmacyId };
      await this.$axios.delete(`/api/v2/core/admin/groups/${this.id}/pharmacy`, { data: req_params });
      await this.fetchGroup();
    },
    isBelongingGroup(pharmacyId) {
      return this.pharmacyIds.includes(pharmacyId);
    },
    filterOutBelongingGroup(pharmacy) {
      return !this.pharmacyIds.includes(pharmacy.id);
    },
  },
};
</script>
