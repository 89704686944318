var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFirstLoading)?_c('div',[_c('LoadingAlert')],1):_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.tables.length)+" 件のジョブ履歴を表示（※ 3-4日で履歴は自動的に削除されます。） "),_c('div',{staticClass:"card-header-actions"},[_c('CButton',{attrs:{"color":"primary","size":"sm"},on:{"click":function($event){return _vm.fetchEmbulks()}}},[_vm._v("更新")])],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","bordered":"","fixed":"","responsive":"","loading":_vm.isLoading,"items":_vm.tables,"fields":_vm.fields,"sorter":_vm.sorter,"sorter-value":_vm.sorterValue},on:{"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var table = ref.item;
return [_c('td',[_c('FromNowSpan',{attrs:{"date":table.created_at}})],1)]}},{key:"started_at",fn:function(ref){
var table = ref.item;
return [_c('td',[_c('FromNowSpan',{attrs:{"date":table.started_at}})],1)]}},{key:"stopped_at",fn:function(ref){
var table = ref.item;
return [_c('td',[_c('FromNowSpan',{attrs:{"date":table.stopped_at}})],1)]}},{key:"time",fn:function(ref){
var table = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.moment.duration(table.stopped_at - table.started_at).format())+" ")])]}},{key:"status",fn:function(ref){
var table = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.statusColor(table.status)}},[_vm._v(_vm._s(table.status))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }