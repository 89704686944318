



























import { PharmacyLicense } from "@/models/PharmacyLicens";
import axios from "@/plugins/axios";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "PharmacyLicenseExpires",
  setup() {
    const isLoading = ref<boolean>(true);
    const licenses = ref<Array<PharmacyLicense>>([]);
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    const fields = ["id", "pharmacy_name", "active", "number", "expires_on", "created_at"];

    async function fetchLicenses() {
      try {
        isLoading.value = true;
        const req_params = { page: currentPage.value };
        const response = await axios.get("/api/v2/core/admin/licenses/expires", { params: req_params });
        licenses.value = response.data;
        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);
      } finally {
        isLoading.value = false;
      }
    }

    watch(currentPage, () => {
      fetchLicenses();
    });

    return {
      isLoading,
      licenses,
      currentPage,
      totalPages,
      totalCount,
      fields,
    };
  },
});
