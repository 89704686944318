


















import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Manual } from "@/models/Manual";
import axios from "@/plugins/axios";
import { getRouteIntParam } from "@/utils";
import { Editor } from "@toast-ui/vue-editor";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ManualCreateEdit",
  components: { LoadingAlert, "toast-editor": Editor },
  setup(props, context) {
    let id = getRouteIntParam(context, "id");
    const isLoading = ref<boolean>(true);

    const title = ref<string>("");
    const content = ref<string>("");
    const editor = ref<Editor>(undefined);

    const shouldDisableSaveButton = computed(() => !(title.value && content.value));

    async function initialize() {
      if (id) {
        const response = await axios.get(`/api/v2/core/admin/manuals/${id}`);
        const manual = new Manual(response.data);
        title.value = manual.title;
        content.value = manual.content;
      }
      isLoading.value = false; // 新規の場合も、編集の場合も、ローディングをここで解除する
    }

    async function addImageBlobHook(file: File, callback: (url: string, altText: string) => void) {
      const response = await axios.get("/api/v2/core/admin/manual_files/presigned_url");
      const presignedUrl = response.data.presigned_url;
      await axios.put(presignedUrl, file, { headers: { "content-type": file.type } });
      callback(response.data.public_url, file.name || response.data.public_url);
      return true;
    }

    function onEditorChange() {
      content.value = editor.value.invoke("getMarkdown");
    }

    async function save() {
      const req_params = { title: title.value, content: content.value };

      if (id) {
        await axios.patch(`/api/v2/core/admin/manuals/${id}`, req_params);
      } else {
        const response = await axios.post("/api/v2/core/admin/manuals", req_params);
        id = response.data.id;
      }
      context.root.$router.push({ name: "マニュアル詳細", params: { id: id.toString() } });
    }

    const options = {
      language: "en-US",
      hooks: { addImageBlobHook },
      placeholder: "# Input with Markdown",
    };

    initialize();

    return {
      isLoading,
      editor,
      title,
      content,
      options,
      shouldDisableSaveButton,
      onEditorChange,
      save,
    };
  },
});
