



















import { ref, defineComponent, watch } from "@vue/composition-api";

import { useIryohokenRowsStore } from "@/store/iryohokenRowsStore";

import IryohokenRowsDataTable from "@/components/masterSources/IryohokenRowsDataTable.vue";

export default defineComponent({
  name: "IryohokenDetails",
  components: {
    IryohokenRowsDataTable,
  },
  setup(props, context) {
    const iryohokenId = parseInt(context.root.$route.params["id"], 10);
    const iryohokenRowsStore = useIryohokenRowsStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "receiptCode", label: "変更区分" },
      { key: "brandName", label: "医薬品名・規格名 漢字名称" },
      { key: "officialUnitPriceType", label: "新又は現金額 金額種別" },
      { key: "officialUnitPrice", label: "新又は現金額" },
      { key: "drugPoison", label: "麻薬・毒薬・覚醒剤原料・向精神薬" },
      { key: "generic", label: "後発品" },
      { key: "registeredOn", label: "変更年月日" },
      { key: "disestablishesDate", label: "販廃止年月日売名" },
      { key: "mhlwCode", label: "薬価基準収載医薬品コード" },
      { key: "interimPeriodDate", label: "経過措置年月日又は商品名医薬品コード使用期限" },
    ];

    async function fetchIryohokenRows(page: number) {
      iryohokenRowsStore.findList({
        iryohokenId,
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchIryohokenRows(page);
    });

    return {
      fields,
      iryohokenRows: iryohokenRowsStore.list,
      isFindingList: iryohokenRowsStore.isFindingList,
      totalCount: iryohokenRowsStore.totalCount,
      totalPages: iryohokenRowsStore.totalPages,
      currentPage,
    };
  },
});
