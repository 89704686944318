import { Pharmacy } from "@/models/Pharmacy";
import { ActionFetchItemBuilder, initStoreActionFetchItemState } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { createStoreProvider } from "@/store/utils";
import { computed, SetupContext } from "@vue/composition-api";
import Vue from "vue";

function createPharmaciesStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<Pharmacy>(),
    ...initStoreActionFindListState<Pharmacy>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpCore, () => {
    return "/api/v2/admin/pharmacies";
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFindList = createActionFindList<Pharmacy, void>(state, context.root.$httpCore, () => {
    return `/api/v2/admin/pharmacies`;
  });

  return {
    ...actionFetch,
    ...actionFindList,
  };
}

const provider = createStoreProvider(createPharmaciesStore, "pharmaciesStore");

export const providePharmaciesStore = provider.provideStore;
export const usePharmaciesStore = provider.useStore;

export function usePharmacy(pharmacyId: number) {
  const pharmaciesStore = usePharmaciesStore();

  return computed(() => {
    return pharmaciesStore.list.value.find((p) => {
      return p.id === pharmacyId;
    });
  });
}

export function createPharmacyAddress(pharmacy: Pharmacy) {
  return `${pharmacy.address1 || ""}${pharmacy.address2 || ""}${pharmacy.address3 || ""}${pharmacy.address4 || ""}`;
}
