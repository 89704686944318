<template>
  <CCard>
    <CCardHeader>詳細情報</CCardHeader>
    <CCardBody>
      <CForm>
        <CInput label="卸会社ID" :value="dealer.id" horizontal plaintext disabled />
        <CInput label="コード" :value="dealer.code" horizontal plaintext disabled />
        <CInput label="名前" :value="dealer.name" horizontal plaintext disabled />
        <CInput label="都道府県ID" :value="dealer.prefecture_id" horizontal plaintext disabled />
        <CInput label="都道府県名" :value="dealer.prefecture_name" horizontal plaintext disabled />
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "DealerDetails",
  data() {
    return {
      id: null,
      dealer: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchDealer();
  },
  methods: {
    async fetchDealer() {
      if (this.id) this.dealer = (await this.$axios.get(`/api/v2/core/master/dealers/${this.id}`)).data;
    },
  },
};
</script>
