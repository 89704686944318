<template>
  <div>
    <CCard>
      <CCardBody>
        <SearchUsers :selected.sync="selectedUser" :api-fields="apiFields" :filter="filterOutBelongingUser" />

        <CDataTable hover bordered fixed :items="tempUsers" :fields="fields" :loading="isLoading || isUpdating">
          <template #name="{item : user}">
            <td>
              <CLink :to="{ name: 'ユーザ情報', params: { id: user.id } }">{{ user.name }}</CLink>
            </td>
          </template>
          <template #permission="{item : user}">
            <td>{{ userPermission(user.id) }}</td>
          </template>
          <template #actions="{item : user}">
            <td>
              <CButton :disabled="!isBelongingUser(user.id)" color="danger" class="mr-1 mb-1" size="sm" @click="leaveUser(user.id)">
                離脱
              </CButton>
              <CButton
                v-for="permission in permissions"
                :key="permission + user.id"
                :disabled="userPermission(user.id) === permission"
                color="primary"
                class="mr-1 mb-1"
                size="sm"
                @click="belongUser(user.id, permission)"
              >
                {{ permission }}
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SearchUsers from "src/components/shared/search/SearchUsers";

export default {
  name: "PharmacyUsers",
  components: { SearchUsers },
  data() {
    return {
      id: null,
      pharmacyUsers: [],
      tempUsers: [], // テーブル表示用の一時的なリスト
      selectedUser: null,
      apiFields: ["id", "auth0_user_id", "name", "email"],
      permissions: ["read", "write"],
      isLoading: false,
      isUpdating: false,
    };
  },
  computed: {
    fields() {
      return this.apiFields.concat(["permission", "actions"]);
    },
    userIds() {
      return this.pharmacyUsers.map((user) => user.id);
    },
  },
  watch: {
    selectedUser() {
      this.tempUsers = [this.selectedUser].concat(this.tempUsers);
      this.tempUsers = this.tempUsers.filter((u, i, array) => array.map((x) => x.id).indexOf(u.id) === i);
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchPharmacyUsers();
    this.tempUsers = this.pharmacyUsers;
  },
  methods: {
    async fetchPharmacyUsers() {
      if (this.isLoading) return;

      this.isLoading = true;
      if (this.id) {
        this.pharmacyUsers = (await this.$axios.get(`/api/v2/core/admin/pharmacies/${this.id}/users`)).data;
      }
      this.isLoading = false;
    },
    async belongUser(userId, permission) {
      this.isUpdating = true;
      const req_params = { user_id: userId, permission: permission };
      await this.$axios.post(`/api/v2/core/admin/pharmacies/${this.id}/users`, req_params);
      await this.fetchPharmacyUsers();
      this.isUpdating = false;
    },
    async leaveUser(userId) {
      this.isUpdating = true;
      const req_params = { user_id: userId };
      await this.$axios.delete(`/api/v2/core/admin/pharmacies/${this.id}/users`, { data: req_params });
      await this.fetchPharmacyUsers();
      this.isUpdating = false;
    },
    isBelongingUser(userId) {
      return this.userIds.includes(userId);
    },
    userPermission(userId) {
      if (this.isBelongingUser(userId)) {
        return this.pharmacyUsers.find((user) => user.id === userId).permission;
      }
      return "未所属";
    },
    filterOutBelongingUser(user) {
      return !this.userIds.includes(user.id);
    },
  },
};
</script>
