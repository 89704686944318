<template>
  <div>
    <LoadingAlert v-if="isFirstLoading" />
    <CCard v-else>
      <CCardHeader>
        {{ total_count }} 件の近隣グループを表示
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" :to="{ name: '近隣グループ新規作成' }">
            <CIcon name="cil-plus" />新規作成する
          </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm @submit.prevent="searchGroups">
          <CInput v-model="query" placeholder="近隣グループ名で検索">
            <template #append><CButton type="submit" color="info">検索</CButton></template>
          </CInput>
        </CForm>
        <CDataTable
          hover
          bordered
          fixed
          responsive
          :loading="isLoading"
          :items="groups"
          :fields="fields"
          :sorter="sorter"
          :sorter-value.sync="sorterValue"
        >
          <template #name="{item : group}">
            <td>
              <CLink :to="{ name: '近隣グループ情報', params: { id: group.id } }">{{ group.name }}</CLink>
            </td>
          </template>
          <template #actions="{item : group}">
            <td>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :to="{ name: '近隣グループ情報', params: { id: group.id } }">
                詳細
              </CButton>
              <CButton color="primary" class="mr-1 mb-1" size="sm" :to="{ name: '近隣グループ編集', params: { id: group.id } }">
                編集
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination v-show="groups.length" align="center" responsive :active-page.sync="current_page" :pages="total_pages" :limit="10" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import LoadingAlert from "src/components/shared/LoadingAlert";

export default {
  name: "Groups",
  components: { LoadingAlert },
  data() {
    return {
      isFirstLoading: true,
      isLoading: false,
      query: null,
      groups: [],
      api_fields: ["id", "name", "pharmacies_count", "updated_at"],
      sorter: { external: true, resetable: false },
      sorterValue: { column: "updated_at", asc: false },
      current_page: 1,
      total_pages: 1,
      total_count: 0,
    };
  },
  computed: {
    fields() {
      return this.api_fields.concat({ key: "actions", sorter: false });
    },
    sort() {
      return this.sorterValue.asc ? this.sorterValue.column : `-${this.sorterValue.column}`;
    },
  },
  watch: {
    $route() {
      this.current_page = parseInt(this.$route.query.page) || 1;
    },
    current_page(page) {
      if (!this.$route.query.page || parseInt(this.$route.query.page) !== page) {
        this.$router.push({ query: { page: `${page}` } });
      }
      this.fetchGroups();
    },
    sorterValue() {
      this.fetchGroups();
    },
  },
  async created() {
    this.current_page = parseInt(this.$route.query.page) || 1;
    await this.fetchGroups();
    this.isFirstLoading = false;
  },
  methods: {
    async fetchGroups() {
      if (this.isLoading) return;

      this.isLoading = true;
      const fields = this.api_fields.join(",");
      const req_params = { page: this.current_page, query: this.query, sort: this.sort, fields: fields };
      const response = await this.$axios.get("/api/v2/core/admin/groups", { params: req_params });
      this.groups = response.data;
      this.current_page = Number(response.headers["x-current-page"]);
      this.total_pages = Number(response.headers["x-total-pages"]);
      this.total_count = Number(response.headers["x-total-count"]);
      this.isLoading = false;
    },
    searchGroups() {
      this.current_page = 1;
      this.fetchGroups();
    },
  },
};
</script>
