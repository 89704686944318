
















import { defineComponent } from "@vue/composition-api";
import moment, { Moment } from "moment";
moment.locale("ja");

export default defineComponent({
  name: "FromNowSpanWithWarning",
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const dateMoment = moment(props.date);
    const dateString = moment(props.date).format();

    function fromNow(d: Moment) {
      return d.fromNow();
    }
    function shouldShowWarning(d: Moment) {
      // 対象日時が、今日のAM0時より前ならWarningを表示する
      const today_am0 = moment().hour(0).minute(0).seconds(0);
      return moment(d).isBefore(today_am0);
    }

    return {
      dateMoment,
      dateString,

      fromNow,
      shouldShowWarning,
    };
  },
});
