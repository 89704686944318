
































import { ref, defineComponent, watch } from "@vue/composition-api";

import { useMedisOptionsStore } from "@/store/medisOptionsStore";

export default defineComponent({
  name: "MedisOptions",
  setup() {
    const medisOptionesStore = useMedisOptionsStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "fileName", label: "ファイル名" },
      { key: "fileDate", label: "ファイル年月日" },
    ];

    async function fetchMedisOptions(page: number) {
      medisOptionesStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisOptions(page);
    });

    return {
      fields,
      medisOptions: medisOptionesStore.list,
      isFindingList: medisOptionesStore.isFindingList,
      totalCount: medisOptionesStore.totalCount,
      totalPages: medisOptionesStore.totalPages,
      currentPage,
    };
  },
});
