import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { createActionPatch, initStoreActionPatchState } from "./actions/createActionPatch";
import { useStockableItemsStore } from "./stockableItemsStore";

export interface UnknownSdcvanDeliveryDRecord {
  id: number;
  itemCodeType: number;
  itemCode: string;
  name: string;
  latestSdcvanDeliveryDRecordId: number;
  notFoundAt: DateTime;
  ignored: boolean;
}

interface UnknownSdcvanDeliveryDRecordsFindListParams {
  pharmacyId?: number;
  page?: number;
}

interface UnknownSdcvanDeliveryDRecordsFindRequestParams {
  pharmacyId?: number;
  page?: number;
}

export interface UnknownSdcvanDeliveryDRecordsPatchParams {
  id: number;
  ignored?: boolean;
}

export interface UnknownSdcvanDeliveryDRecordsPatchData {
  ignored?: boolean;
}

function createUnknownSdcvanDeliveryDRecordsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFindListState<UnknownSdcvanDeliveryDRecord>(),
    ...initStoreActionPatchState(),
  });

  const stockableItemsStore = useStockableItemsStore();

  const actionFind = createActionFindList<UnknownSdcvanDeliveryDRecord, UnknownSdcvanDeliveryDRecordsFindListParams>(
    state,
    context.root.$httpMedorder,
    () => {
      return `/api/v2/admin/unknown_sdcvan_delivery_d_records`;
    },
    {
      afterReciveList: async (items) => {
        const janCodes: string[] = [];
        for (const item of items) {
          if (item.itemCode) {
            janCodes.push(item.itemCode);
          }
        }
        await stockableItemsStore.fetchByJanCode(janCodes);
      },
      createRequestParams: (params) => {
        const requestParams: UnknownSdcvanDeliveryDRecordsFindRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  const actionPatch = createActionPatch<UnknownSdcvanDeliveryDRecord, UnknownSdcvanDeliveryDRecordsPatchParams>(
    context,
    state,
    (context, params) => {
      const url = `/api/v2/admin/unknown_sdcvan_delivery_d_records/${params.id}`;
      const data: UnknownSdcvanDeliveryDRecordsPatchData = {};
      if (params.ignored !== undefined) {
        data.ignored = params.ignored;
      }
      return context.root.$httpMedorder.patch(url, data);
    },
    {
      findListState: state,
    }
  );

  return {
    ...actionFind,
    ...actionPatch,
  };
}

const provider = createStoreProvider(createUnknownSdcvanDeliveryDRecordsStore, "unknownSdcvanDeliveryDRecordsStore");

export const provideUnknownSdcvanDeliveryDRecordsStore = provider.provideStore;
export const useUnknownSdcvanDeliveryDRecordsStore = provider.useStore;
