import { camelCase, snakeCase } from "change-case";

type JsonType = string | number | boolean | null | { [property: string]: JsonType } | JsonType[];

const isObject = (x: unknown): x is object => x !== null && typeof x === "object";

export function jsonConvertKeyCamelToSnake(target: JsonType): void {
  if (Array.isArray(target)) {
    target.forEach((t) => jsonConvertKeyCamelToSnake(t));
  } else if (isObject(target)) {
    Object.keys(target).forEach((key) => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        jsonConvertKeyCamelToSnake(target[key]);
      }

      if (key !== snakeCase(key)) {
        target[snakeCase(key)] = target[key];
        delete target[key];
      }
    });
  }
}

export function jsonConvertKeySnakeToCamel(target: JsonType): void {
  if (Array.isArray(target)) {
    target.forEach((t) => jsonConvertKeySnakeToCamel(t));
  } else if (isObject(target)) {
    Object.keys(target).forEach((key) => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        jsonConvertKeySnakeToCamel(target[key]);
      }
      if (key.includes("_")) {
        target[camelCase(key)] = target[key];
        delete target[key];
      }
    });
  }
}
