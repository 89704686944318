



















import { defineComponent } from "@vue/composition-api";

import { MedisGs1Row } from "@/store/medisGs1RowsStore";

interface Props {
  medisGs1Rows: MedisGs1Row[];
  loading: boolean;
}

export default defineComponent<Props>({
  name: "MedisBaseRowDataTable",
  props: {
    medisGs1Rows: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const fields = [
      { key: "id", label: "ID" },
      { key: "hotCode", label: "基準番号(HOTコード)" },
      { key: "brandName", label: "販売名" },
      { key: "standard", label: "規格単位" },
      { key: "packageGs1Code", label: "販売包装単位コード" },
      { key: "noticeFlag", label: "注意フラグ" },
      { key: "registeredOn", label: "登録日" },
    ];

    return {
      fields,
    };
  },
});
