





























































































import { computed, defineComponent } from "@vue/composition-api";

import { OrderableItemImport } from "@/store/orderableItemImportsStore";
import { useStockableItemsStore } from "@/store/stockableItemsStore";

interface Props {
  orderableItemImport: OrderableItemImport | null;
}

export default defineComponent<Props>({
  name: "OrderableItemImport",
  props: {
    orderableItemImport: {
      type: Object,
    },
  },
  setup(props) {
    const stockableItemsStore = useStockableItemsStore();

    const stockableItem = computed(() => {
      if (!props.orderableItemImport || !props.orderableItemImport.stockableItemId) {
        return null;
      }
      return stockableItemsStore.get(props.orderableItemImport.stockableItemId);
    });

    const orderableItem = computed(() => {
      const stockableItemImport = props.orderableItemImport;
      if (!stockableItemImport || !stockableItemImport.orderableItemId || !stockableItem.value) {
        return null;
      }

      return (
        stockableItem.value.orderableItems.find((item) => {
          return item.id === stockableItemImport.orderableItemId;
        }) || null
      );
    });

    return {
      stockableItem,
      orderableItem,
    };
  },
});
