























import { ref, defineComponent, watch } from "@vue/composition-api";

import { useMedisBasesStore } from "@/store/medisBasesStore";

export default defineComponent({
  name: "MedisBases",
  setup() {
    const medisBasesStore = useMedisBasesStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "fileName", label: "ファイル名" },
      { key: "fileDate", label: "ファイル年月日" },
    ];

    async function fetchMedisBases(page: number) {
      medisBasesStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisBases(page);
    });

    return {
      fields,
      medisBases: medisBasesStore.list,
      isFindingList: medisBasesStore.isFindingList,
      totalCount: medisBasesStore.totalCount,
      totalPages: medisBasesStore.totalPages,
      currentPage,
    };
  },
});
