const apiVersions = ["v1", "v2"] as const;
type ApiVersionType = typeof apiVersions[number];

const methods = ["get", "post", "put", "patch", "delete"] as const;
type MethodType = typeof methods[number];

const modules = ["core", "medorder"] as const;
type ModuleType = typeof modules[number];

type ApiRequest<ResponseType> = {
  version: ApiVersionType;
  module?: ModuleType;
  path: string;
  method: MethodType;
  parameters?: { [key: string]: string };
};

type PaginatedApiRequest<ResponseType> = ApiRequest<ResponseType> & {
  page: number;
};

export type { ApiRequest, PaginatedApiRequest };

export function isPaginatedApiRequest<R>(request: ApiRequest<R>): request is PaginatedApiRequest<R> {
  return Object.prototype.hasOwnProperty.call(request, "page");
}
