






































import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { ManualCategory } from "@/models/ManualCategory";
import axios from "@/plugins/axios";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ManualContainer",
  components: { LoadingAlert, DatetimeSpan },
  setup(props, context) {
    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const categories = ref<Array<ManualCategory>>([]);
    const query = ref<string>("");

    async function fetchManualCategory() {
      if (isLoading.value) return;

      isLoading.value = true;
      const response = await axios.get("/api/v2/core/admin/manual_categories");
      categories.value = response.data.children.map((d: object) => new ManualCategory(d));
      isFirstLoading.value = false;
      isLoading.value = false;
    }

    async function search() {
      if (query.value) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await context.root.$router.push({ name: "マニュアル検索", query: { query: query.value } }).catch(() => {});
      } else {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await context.root.$router.push({ name: "マニュアル一覧" }).catch(() => {});
      }
    }

    async function onClickCategory(category: ManualCategory) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await context.root.$router.push({ name: "マニュアル一覧", query: { categoryId: category.id.toString() } }).catch(() => {});
      if (!category.has_child) return;
      if (!category.open) {
        category.open = !category.open;
        const index = categories.value.findIndex((c) => c.id === category.id);
        categories.value.splice(index, 1, category);

        const response = await axios.get(`/api/v2/core/admin/manual_categories/${category.id}`);
        categories.value.splice(index + 1, 0, ...response.data["children"].map((d: object) => new ManualCategory(d)));
      }
    }

    fetchManualCategory();

    return {
      isFirstLoading,
      isLoading,
      categories,
      query,
      search,
      onClickCategory,
    };
  },
});
