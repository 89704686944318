


































































































































































































import { computed, defineComponent, watch } from "@vue/composition-api";

import { useStockableItemImportsStore } from "@/store/stockableItemImportsStore";
import { useMedisBaseRowsStore } from "@/store/medisBaseRowsStore";
import { useMedisGs1RowsStore } from "@/store/medisGs1RowsStore";
import { useMedhotRowsStore } from "@/store/medhotRowsStore";
import { useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";
import { useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";
import { useIryohokenRowsStore } from "@/store/iryohokenRowsStore";

import StockableItemImport from "@/components/masterSources/StockableItemImport.vue";
import MedisBaseRowsDataTable from "@/components/masterSources/MedisBaseRowsDataTable.vue";
import MedisGs1RowsDataTable from "@/components/masterSources/MedisGs1RowsDataTable.vue";
import IryohokenRowsDataTable from "@/components/masterSources/IryohokenRowsDataTable.vue";
import MedhotRowsDataTable from "@/components/masterSources/MedhotRowsDataTable.vue";

export default defineComponent({
  name: "HotCodeDetails",
  components: {
    StockableItemImport,
    MedisBaseRowsDataTable,
    MedisGs1RowsDataTable,
    IryohokenRowsDataTable,
    MedhotRowsDataTable,
  },
  setup(props, context) {
    const hotCode = context.root.$route.params["hotCode"];

    const stockableItemImportsStore = useStockableItemImportsStore();
    const medisBaseRowsStore = useMedisBaseRowsStore();
    const medisGs1RowsStore = useMedisGs1RowsStore();
    const iryohokenRowsStore = useIryohokenRowsStore();
    const medhotRowsStore = useMedhotRowsStore();
    const stockableItemFixturesStore = useStockableItemFixturesStore();
    const orderableItemFixturesStore = useOrderableItemFixturesStore();

    const stockableItemImport = computed(() => {
      stockableItemImportsStore.isFetching.value;
      return stockableItemImportsStore.getByHotCode(hotCode);
    });

    const hasDiff = computed(() => {
      if (!stockableItemImport.value) {
        return false;
      }
      if (stockableItemImport.value.stockableItemOperation !== "none") {
        return true;
      }
      if (!stockableItemImport.value.orderableItems || stockableItemImport.value.orderableItems.length === 0) {
        return false;
      }
      const orderablaeItemImport = stockableItemImport.value.orderableItems[0];
      return orderablaeItemImport.operation !== "none";
    });

    stockableItemImportsStore.fetchByHotCode(hotCode);

    medisBaseRowsStore.findList({
      hotCode,
    });
    medisGs1RowsStore.findList({
      hotCode,
    });
    orderableItemFixturesStore.fetchByHotCode(hotCode);

    const receiptCode = computed(() => {
      if (medisBaseRowsStore.list.value.length === 0) {
        return null;
      }
      const medisBaseRow = medisBaseRowsStore.list.value[0];
      return medisBaseRow.receiptCode;
    });

    const yjCode = computed(() => {
      if (medisBaseRowsStore.list.value.length === 0) {
        return null;
      }
      const medisBaseRow = medisBaseRowsStore.list.value[0];
      return medisBaseRow.yjCode;
    });

    const gs1Code = computed(() => {
      if (medisGs1RowsStore.list.value.length === 0) {
        return null;
      }
      const medisGs1Row = medisGs1RowsStore.list.value[0];
      return medisGs1Row.packageGs1Code;
    });

    watch(receiptCode, (newReceiptCode) => {
      if (newReceiptCode) {
        iryohokenRowsStore.findList({
          receiptCode: newReceiptCode,
        });
      }
    });

    watch(yjCode, (newYjCode) => {
      if (newYjCode) {
        stockableItemFixturesStore.fetchByYjCode(newYjCode);
      }
    });

    const stockableItemFixture = computed(() => {
      stockableItemFixturesStore.isFetching.value;
      if (!yjCode.value) {
        return null;
      }
      return stockableItemFixturesStore.getByYjCode(yjCode.value);
    });

    watch(gs1Code, (newGs1Code) => {
      if (newGs1Code) {
        medhotRowsStore.findList({
          packageGs1Code: newGs1Code,
        });
        orderableItemFixturesStore.fetchByGs1Code(newGs1Code);
      }
    });
    const orderableItemFixture = computed(() => {
      orderableItemFixturesStore.isFetching.value;
      const orderableItemFixtureHotCode = orderableItemFixturesStore.getByHotCode(hotCode);
      if (orderableItemFixtureHotCode) {
        return orderableItemFixtureHotCode;
      }
      if (gs1Code.value) {
        const orderableItemFixtureHotCodeGs1Code = orderableItemFixturesStore.getByGs1Code(gs1Code.value);
        if (orderableItemFixtureHotCodeGs1Code) {
          return orderableItemFixtureHotCodeGs1Code;
        }
      }
      return null;
    });

    async function importByHotCode() {
      await stockableItemImportsStore.create({
        hotCode,
      });
      stockableItemImportsStore.fetchByHotCode(hotCode, true);
    }

    return {
      hotCode,
      stockableItemImport,
      medisBaseRows: medisBaseRowsStore.list,
      isFindingMedisBaseRows: medisBaseRowsStore.isFindingList,
      medisGs1Rows: medisGs1RowsStore.list,
      isFindingMedisGs1Rows: medisGs1RowsStore.isFindingList,
      iryohokenRows: iryohokenRowsStore.list,
      isFindingIryohokenRows: iryohokenRowsStore.isFindingList,
      medhotRows: medhotRowsStore.list,
      isFindingMedhotRows: medhotRowsStore.isFindingList,
      yjCode,
      gs1Code,
      stockableItemFixture,
      orderableItemFixture,
      importByHotCode,
      hasDiff,
    };
  },
});
