









































import { Pharmacy } from "@/models/Pharmacy";
import { usePharmaciesStore } from "@/store/pharmaciesStore";
import { InquiryFilter } from "@/views/v2/core/inquiries/inquiryFilter";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Multiselect from "vue-multiselect";

export interface InquiryStatus {
  id: string;
  value: string;
  label: string;
}

export default defineComponent({
  name: "InquiryFilterModal",
  components: { Multiselect },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    inquiryFilter: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pharmaciesStore = usePharmaciesStore();

    const selectedPharmacy = ref<Pharmacy>();
    const selectablePharmacies = ref<Array<Pharmacy>>([]);
    const selectableStatus = ref<Array<InquiryStatus>>([
      { id: "new", value: false, label: "新規" },
      { id: "replied", value: false, label: "返信済み" },
      { id: "unreplied", value: false, label: "未返信" },
      { id: "need_action", value: false, label: "要アクション" },
      { id: "closed", value: false, label: "クローズ" },
      { id: "completed", value: false, label: "完了" },
    ]);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });

    async function listPharmacies() {
      await pharmaciesStore.findList();
      while (pharmaciesStore.hasMore.value) await pharmaciesStore.findListMore();
      selectablePharmacies.value = [...pharmaciesStore.list.value];
    }
    listPharmacies();

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function save() {
      const status = selectableStatus.value
        .filter((s) => s.value)
        .map((s) => s.id)
        .join(",");

      const filter = new InquiryFilter({ pharmacyId: selectedPharmacy.value?.id, status: status });
      await emit("update:inquiryFilter", filter);
      propsOpenModal.value = false;
    }

    return {
      selectedPharmacy,
      selectablePharmacies,
      selectableStatus,
      propsOpenModal,

      hideModal,
      save,
    };
  },
});
