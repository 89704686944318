import { getInstance } from "@/auth";
import AxiosError, { AxiosInstance } from "axios";
import { Interceptor } from "./interceptor";

export const apiPathInterceptor: Interceptor = {
  apply: (axios: AxiosInstance) => {
    axios.interceptors.request.use(async (request) => {
      const url = request.url;
      if (!url) {
        throw AxiosError(request);
      }

      // V1 APIの場合
      if (url.startsWith("/api/v1/")) {
        // 環境によってURLを書き換える
        const host = process.env.VUE_APP_V1_API_HOST;
        if (!host) {
          throw AxiosError(request);
        }

        const version = url.split("/")[2];
        const module = url.split("/")[3];
        const path = url.replace(`/api/${version}/${module}/`, "");

        if (process.env.VUE_APP_ENV === "development") {
          request.url = `${host}/p/1/${module}/${path}`;
        } else {
          request.url = `${host}/_ah/api/${module}/v1.0/${path}`;
        }
      }
      // V2 APIの場合
      else if (url.startsWith("/api/v2/")) {
        // Authorization 付与
        const jwtToken = await getInstance().getJwtToken();
        request.headers.Authorization = `Bearer ${jwtToken}`;

        // サービスごとにhostを書き換える
        const version = url.split("/")[2];
        const module = url.split("/")[3];
        const path = url.replace(`/api/${version}/${module}/`, "");

        const host = ((_module) => {
          switch (_module) {
            case "core":
              return process.env.VUE_APP_V2_API_CORE_HOST;
            case "medorder":
              return process.env.VUE_APP_V2_API_MEDORDER_HOST;
            default:
              throw `url module(${_module}) not defined error`;
          }
        })(module);

        if (!host) {
          throw AxiosError(request);
        }

        request.url = `${host}/api/${version}/${path}`;
      }

      return request;
    });
  },
};
