



























































































































import Vue from "vue";
import { DateTime } from "luxon";
import { reactive, toRefs, computed, ref } from "@vue/composition-api";
import { ethicalMedicineApi } from "@/api/master/ethicalMedicine";
import { CAlert } from "@coreui/vue";
export default {
  setup() {
    const apiClient = ethicalMedicineApi();

    const state = reactive({
      alert: ref(null),
      alertText: "",
      showAlert: ref<boolean | number>(false),
      hotCodeStartDate: DateTime.local().minus({ month: 2 }).toJSDate(),
      hotCodePickerAvailableDates: computed(() => {
        return {
          start: DateTime.local(2001, 12, 28).toJSDate(),
          end: new Date(),
        };
      }),
      medhotStartDate: DateTime.local().minus({ month: 2 }).toJSDate(),
      medhotDatePickerAvailableDates: computed(() => {
        return {
          start: DateTime.local(2015, 6, 30).toJSDate(),
          end: new Date(),
        };
      }),
      iryohokenStartYear: ref(2019),
      iryohokenStartYears: [
        { value: 2012, label: "2012" },
        { value: 2014, label: "2014" },
        { value: 2016, label: "2016" },
        { value: 2018, label: "2018" },
        { value: 2019, label: "2019" },
        { value: 2020, label: "2020" },
      ],
    });

    const addAlert = (text: string) => {
      const clazz = Vue.extend(CAlert);
      const instance = new clazz({
        propsData: {
          color: "success",
          closeButton: true,
          show: 5,
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      instance.$slots["default"] = text;
      instance.$mount();

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      state.alert.appendChild(instance.$el);
    };

    const hotCodeStartDownloading = () => {
      // なぜか型推論がおかしい
      apiClient.downloadHotCode(DateTime.fromJSDate(state.hotCodeStartDate as Date));
      addAlert("MEDIS 医薬品 HOT コードマスターデータのダウンロードを開始しました");
    };

    const hotCodeStartImporting = () => {
      apiClient.importHotCode();
      addAlert("MEDIS 医薬品 HOT コードマスターデーターの取り込みを開始しました");
    };

    const medhotStartDownloading = () => {
      apiClient.downloadMedhot(DateTime.fromJSDate(state.medhotStartDate as Date));
      addAlert("MEDHOT マスターデータのダウンロードを開始しました");
    };

    const medhotStartImporting = () => {
      apiClient.importMedhot();
      addAlert("MEDHOT マスターデーターの取り込みを開始しました");
    };

    const iryohokenStartDownloading = () => {
      apiClient.downloadIryohoken(state.iryohokenStartYear);
      addAlert("医療保険マスターデーターのダウンロードを開始しました");
    };

    const iryohokenStartImporting = () => {
      apiClient.importIryohoken();
      addAlert("医療保険マスターデーターの取り込みを開始しました");
    };

    return {
      ...toRefs(state),
      hotCodeStartDownloading,
      hotCodeStartImporting,
      medhotStartDownloading,
      medhotStartImporting,
      iryohokenStartDownloading,
      iryohokenStartImporting,
    };
  },
};
