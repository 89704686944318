import { DateTime } from "luxon";
import { LastMessage, Room, RoomUsers, StringNumber } from "vue-advanced-chat";

export class ChatRoomClass implements Room {
  id!: number;
  name!: string;
  roomId!: StringNumber;
  roomName!: string;
  status?: string;
  closed?: boolean;
  completed?: boolean;
  unread_count?: number;
  unreadCount?: number;
  last_message?: LastMessage;
  lastMessage?: LastMessage;
  users!: RoomUsers;
  pharmacy?: object;
  created_at?: string;
  createdAt?: DateTime;
  updated_at?: string;
  updatedAt?: DateTime;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  static create(r: ChatRoomClass | Room): ChatRoomClass {
    const room = new ChatRoomClass(r as object);

    room.roomId = room.id;
    room.roomName = room.name;
    if (room.unread_count) room.unreadCount = room.unread_count;
    if (room.last_message) room.lastMessage = room.last_message;

    if (room.lastMessage) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const createdAtString = room.lastMessage.createdAt?.toString() || room.lastMessage.created_at;
      if (createdAtString) {
        const createdAt = DateTime.fromISO(createdAtString);
        room.lastMessage.timestamp = createdAt.toLocaleString() || "-";
        // room.lastMessage.date = createdAt.toMillis() || 0;
      }
    }
    room.users = [
      // メッセージにユーザー名を強制的に表示させるために、ダミーユーザーが3人所属しているように設定。
      { _id: -1, username: "", avatar: "", status: { state: "offline", lastChanged: "" } },
      { _id: -2, username: "", avatar: "", status: { state: "offline", lastChanged: "" } },
      { _id: -3, username: "", avatar: "", status: { state: "offline", lastChanged: "" } },
    ];
    return room;
  }

  public statusLabel(): string {
    switch (this.status) {
      case "new":
        return "新規";
      case "replied":
        return "返信済み";
      case "unreplied":
        return "未返信";
      case "need_action":
        return "要アクション";
      case "closed":
        return "クローズ";
      case "completed":
        return "完了";
      default:
        return "不明";
    }
  }

  public badgeClass(): object {
    switch (this.status) {
      case "new":
        return { badge: true, "badge-danger": true };
      case "replied":
        return { badge: true, "badge-info": true };
      case "unreplied":
        return { badge: true, "badge-danger": true };
      case "need_action":
        return { badge: true, "badge-warning": true };
      case "closed":
        return { badge: true, "badge-warning": true };
      case "completed":
        return { badge: true, "badge-secondary": true };
      default:
        return { badge: true, "badge-danger": true };
    }
  }
}
