



















import { defineComponent, onBeforeMount } from "@vue/composition-api";

import { getRouteIntParam } from "@/utils";
import { useBatchConfigStore, useBatchConfig } from "@/store/batchConfigStore";

export default defineComponent({
  name: "PharmacyConfigs",
  components: {},
  setup(props, context) {
    const pharmacyId = getRouteIntParam(context, "id");

    const batchConfigStore = useBatchConfigStore();
    const batchConfig = useBatchConfig(pharmacyId, true, true);

    function saveBatchConfig(ttl: number) {
      batchConfigStore.put({
        pharmacyId,
        ttl,
      });
    }

    onBeforeMount(() => {
      batchConfigStore.fetch(pharmacyId, true);
    });

    return {
      batchConfig,
      saveBatchConfig,
    };
  },
});
