

























import { ref, defineComponent, watch } from "@vue/composition-api";

import { useMedisOptionRowsStore } from "@/store/medisOptionRowsStore";

export default defineComponent({
  name: "MedisOptionDetails",
  setup(props, context) {
    const medisOptionId = parseInt(context.root.$route.params["id"], 10);
    const medisOptionRowsStore = useMedisOptionRowsStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "hotCode", label: "基準番号(HOTコード)" },
      { key: "packetCount", label: "包装数量(数量)" },
      { key: "packetCountUnitName", label: "包装数量(単位)" },
      { key: "registerType", label: "レコード区分" },
      { key: "registeredOn", label: "更新年月日" },
    ];

    async function fetchMedisOptionRows(page: number) {
      medisOptionRowsStore.findList({
        medisOptionId,
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisOptionRows(page);
    });

    return {
      fields,
      medisOptionRows: medisOptionRowsStore.list,
      isFindingList: medisOptionRowsStore.isFindingList,
      totalCount: medisOptionRowsStore.totalCount,
      totalPages: medisOptionRowsStore.totalPages,
      currentPage,
    };
  },
});
