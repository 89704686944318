
























import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import axios from "@/plugins/axios";

export default defineComponent({
  name: "ChangePasswordModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const newPassword = ref<string>("");
    const newPasswordAgain = ref<string>("");
    const isLoading = ref<boolean>(false);
    const successMessage = ref<string>("");
    const errorMessages = ref<Array<string>>([]);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => emit("update:openModal", value),
    });
    const isPasswordDifferent = computed(() => {
      if (newPassword.value && newPasswordAgain.value) {
        return newPassword.value !== newPasswordAgain.value;
      }
      return false;
    });
    const shouldDisableSubmitButton = computed(() => {
      return !newPassword.value || !newPasswordAgain.value || isPasswordDifferent.value || isLoading.value;
    });

    watch(newPassword, () => {
      // 入力しているパスワードが変更されたら、エラーメッセージはクリアする
      errorMessages.value = [];
    });
    watch(propsOpenModal, (value: boolean) => {
      if (!value) {
        // 閉じるときに初期化する
        isLoading.value = false;
        newPassword.value = "";
        newPasswordAgain.value = "";
        successMessage.value = "";
        errorMessages.value = [];
      }
    });

    async function changePassword() {
      successMessage.value = "";
      errorMessages.value = [];
      try {
        isLoading.value = true;
        const req_params = { new_password: newPassword.value, new_password_again: newPasswordAgain.value };
        await axios.post("/api/v2/core/common/users/change_password", req_params);

        newPassword.value = "";
        newPasswordAgain.value = "";
        successMessage.value = "パスワードが変更されました。";
      } catch (e) {
        errorMessages.value =
          e.response.data.errors.map((msg: string) => {
            if (msg === "PasswordStrengthError: Password is too weak") {
              return "パスワードは、8文字以上、大文字小文字数字を全て使ってください。記号は「!@#$%^&*」が使えます。";
            }
            return msg;
          }) || [];
      } finally {
        isLoading.value = false;
      }
    }

    function hidePasswordModal() {
      propsOpenModal.value = false;
    }

    return {
      newPassword,
      newPasswordAgain,
      isLoading,
      successMessage,
      errorMessages,

      propsOpenModal,
      isPasswordDifferent,
      shouldDisableSubmitButton,

      changePassword,
      hidePasswordModal,
    };
  },
});
