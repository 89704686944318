
































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Manual } from "@/models/Manual";
import axios from "@/plugins/axios";
import { getRouteIntParam } from "@/utils";
import DeleteConfirmationModal from "@/views/shared/DeleteConfirmationModal.vue";
import ManualBreadcrumb from "@/views/v2/core/manuals/ManualBreadCrumb.vue";
import { Viewer } from "@toast-ui/vue-editor";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ManualDetail",
  components: { DeleteConfirmationModal, LoadingAlert, ManualBreadcrumb, "toast-viewer": Viewer },
  setup(props, context) {
    const isLoading = ref<boolean>(true);
    const id = getRouteIntParam(context, "id");
    const manual = ref<Manual>(undefined);

    const isOpenDeleteModal = ref<boolean>(false);
    async function fetchManual() {
      const response = await axios.get(`/api/v2/core/admin/manuals/${id}`);
      manual.value = new Manual(response.data);
      isLoading.value = false;
    }

    async function onDeleteClick() {
      isOpenDeleteModal.value = true;
    }

    async function doDelete() {
      await axios.delete(`/api/v2/core/admin/manuals/${id}`);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await context.root.$router.push({ name: "マニュアル一覧" }).catch(() => {});
    }

    fetchManual();

    return {
      isOpenDeleteModal,
      isLoading,
      manual,
      onDeleteClick,
      doDelete,
    };
  },
});
