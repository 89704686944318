import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import {
  initStoreActionFetchItemState,
  ActionFetchItemBuilder,
  actionFetchItemCommitReplaceItem,
} from "@/store/actions/createActionFetchItem";
import { fetchBase } from "./actions/createActionFetchBase";
import { initStoreActionFindListState, createActionFindList } from "./actions/createActionFindList";
import { createActionCreate, initStoreActionCreateState } from "./actions/createActionCreate";
import { createActionPatch, initStoreActionPatchState } from "./actions/createActionPatch";

export interface StockableItemFixture {
  id: number;
  name: string | null;
  unit: string | null;
  price: number | null;
  yjCode: string | null;
  receiptCode: string | null;
  genericId: number | null;
  generic: boolean | null;
  interimPeriodOn: DateTime | null;
  disableStock: boolean | null;
  note: string | null;
}

export interface StockableItemFixturesFindListParams {
  yjCode?: string;
  page?: number;
}

interface StockableItemFixturesFindListRequestParams {
  yj_code?: string;
  page?: number;
}

export interface StockableItemFixturesCreateParams {
  name?: string;
  unit?: string;
  yjCode?: string;
  note?: string;
}

export interface StockableItemFixturesPatchParams {
  id: number;
  name?: string | null;
  unit?: string | null;
  price?: number | null;
  yjCode?: string | null;
  receiptCode?: string | null;
  genericId?: number | null;
  generic?: boolean | null;
  interimPeriodOn?: string | null;
  disableStock?: boolean | null;
  note?: string | null;
}

interface StockableItemFixturesCreateRequestData {
  name?: string;
  unit?: string;
  yj_code?: string;
  note?: string;
}

interface StockableItemFixturesPatchRequestData {
  name?: string | null;
  unit?: string | null;
  price?: number | null;
  yj_code?: string | null;
  receipt_code?: string | null;
  generic_id?: number | null;
  generic?: boolean | null;
  interim_period_on?: string | null;
  disable_stock?: boolean | null;
  note?: string | null;
}

function createStockableItemFixturesStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<StockableItemFixture>(),
    ...initStoreActionFindListState<StockableItemFixture>(),
    ...initStoreActionCreateState<StockableItemFixture>(),
    ...initStoreActionPatchState(),
    yjCodeItemMap: new Map<string, StockableItemFixture>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/stockable_item_fixtures`;
  });
  const actionFetch = actionFetchBuilder.build();

  async function fetchByYjCode(yjCode: string | string[], force?: boolean) {
    return fetchBase<StockableItemFixture, string, StockableItemFixture>(
      yjCode,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/admin/stockable_item_fixtures";
      },
      "yj_code",
      () => {
        return state.yjCodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          if (item.yjCode) {
            map.set(item.yjCode, item);
          }
          state.itemMap.set(item.id, item);
        }
      },
      null,
      null
    );
  }

  function getByYjCode(yjCode: string) {
    return state.yjCodeItemMap.get(yjCode) || null;
  }

  const actionFind = createActionFindList<StockableItemFixture, StockableItemFixturesFindListParams>(
    state,
    context.root.$httpMedorder,
    () => {
      return `/api/v2/admin/stockable_item_fixtures`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: StockableItemFixturesFindListRequestParams = {};
        if (params.yjCode) {
          requestParams.yj_code = params.yjCode;
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  const actionCreate = createActionCreate<StockableItemFixture, StockableItemFixturesCreateParams>(
    context,
    state,
    (context, params) => {
      const url = "/api/v2/admin/stockable_item_fixtures";
      const data: StockableItemFixturesCreateRequestData = {};
      if (params.name) {
        data.name = params.name;
      }
      if (params.unit) {
        data.unit = params.unit;
      }
      if (params.yjCode) {
        data.yj_code = params.yjCode;
      }
      if (params.note) {
        data.note = params.note;
      }
      return context.root.$httpMedorder.post(url, data);
    },
    {
      findListState: state,
      afterEndCreate: (item) => {
        actionFetchItemCommitReplaceItem(state, item);
      },
    }
  );

  const actionPatch = createActionPatch<StockableItemFixture, StockableItemFixturesPatchParams>(
    context,
    state,
    (context, params) => {
      const url = `/api/v2/admin/stockable_item_fixtures/${params.id}`;
      const data: StockableItemFixturesPatchRequestData = {};
      if (params.name !== undefined) {
        data.name = params.name;
      }
      if (params.unit !== undefined) {
        data.unit = params.unit;
      }
      if (params.price !== undefined) {
        data.price = params.price;
      }
      if (params.yjCode !== undefined) {
        data.yj_code = params.yjCode;
      }
      if (params.receiptCode !== undefined) {
        data.receipt_code = params.receiptCode;
      }
      if (params.genericId !== undefined) {
        data.generic_id = params.genericId;
      }
      if (params.generic !== undefined) {
        data.generic = params.generic;
      }
      if (params.interimPeriodOn !== undefined) {
        data.interim_period_on = params.interimPeriodOn;
      }
      if (params.disableStock !== undefined) {
        data.disable_stock = params.disableStock;
      }
      if (params.note !== undefined) {
        data.note = params.note;
      }
      return context.root.$httpMedorder.patch(url, data);
    },
    {
      findListState: state,
      afterEndPatch: (item) => {
        actionFetchItemCommitReplaceItem(state, item);
      },
    }
  );

  return {
    ...actionFetch,
    fetchByYjCode,
    getByYjCode,
    ...actionFind,
    ...actionCreate,
    ...actionPatch,
  };
}

const provider = createStoreProvider(createStockableItemFixturesStore, "stockableItemFixture");

export const provideStockableItemFixturesStore = provider.provideStore;
export const useStockableItemFixturesStore = provider.useStore;
