



















import { useMedisGs1RowsStore } from "@/store/medisGs1RowsStore";
import { ref, defineComponent, watch } from "@vue/composition-api";

import MedisGs1RowsDataTable from "@/components/masterSources/MedisGs1RowsDataTable.vue";

export default defineComponent({
  name: "MedisBaseDetails",
  components: {
    MedisGs1RowsDataTable,
  },
  setup(props, context) {
    const medisGs1Id = parseInt(context.root.$route.params["id"], 10);
    const medisGs1RowsStore = useMedisGs1RowsStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "hotCode", label: "基準番号(HOTコード)" },
      { key: "brandName", label: "販売名" },
      { key: "standard", label: "規格単位" },
      { key: "packageGs1Code", label: "販売包装単位コード" },
      { key: "noticeFlag", label: "注意フラグ" },
      { key: "fileDate", label: "ファイル年月日" },
    ];

    async function fetchMedisGs1Rows(page: number) {
      medisGs1RowsStore.findList({
        medisGs1Id,
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisGs1Rows(page);
    });

    return {
      fields,
      medisGs1Rows: medisGs1RowsStore.list,
      isFindingList: medisGs1RowsStore.isFindingList,
      totalCount: medisGs1RowsStore.totalCount,
      totalPages: medisGs1RowsStore.totalPages,
      currentPage,
    };
  },
});
