<template>
  <div>
    <CRow>
      <CCol v-for="menu in menus" :key="menu" sm="6" md="4">
        <CCardLink :to="{ name: menu }">
          <CCard color="secondary" class="text-center" body-wrapper>
            <blockquote class="card-blockquote">
              <p class="text-body h4">{{ menu }}</p>
            </blockquote>
          </CCard>
        </CCardLink>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "MenuLink",
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
