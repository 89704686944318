export function collectIds<T>(list: T[], idGetter: (item: T) => number) {
  const idSet = new Set<number>();
  for (const item of list) {
    idSet.add(idGetter(item));
  }
  return Array.from(idSet);
}

export function collectNullableIds<T>(list: T[], idGetter: (item: T) => number | undefined | null) {
  const idSet = new Set<number>();
  for (const item of list) {
    const id = idGetter(item);
    if (id) {
      idSet.add(id);
    }
  }
  return Array.from(idSet);
}
