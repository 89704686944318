






































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Manual } from "@/models/Manual";
import { ManualCategory } from "@/models/ManualCategory";
import axios from "@/plugins/axios";
import { useQuery } from "@/utils/routeData";
import ManualBreadCrumb from "@/views/v2/core/manuals/ManualBreadCrumb.vue";
import { defineComponent, ref, watch } from "@vue/composition-api";
import { Dictionary } from "vue-router/types/router";

export default defineComponent({
  name: "Manuals",
  components: { LoadingAlert, ManualBreadCrumb },
  setup() {
    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const manuals = ref<Array<Manual>>([]);
    const manualCategoryId = ref<string | (string | null)[] | null | undefined>(undefined);
    const manualCategories = ref<Array<ManualCategory>>([]);

    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchManuals(categoryId: string | (string | null)[] | null | undefined) {
      if (isLoading.value) return;

      isLoading.value = true;
      const req_params = { page: currentPage.value, manual_category: true, manual_category_id: categoryId };
      const response = await axios.get("/api/v2/core/admin/manuals", { params: req_params });
      manuals.value = response.data.map((d: object) => new Manual(d));
      currentPage.value = Number(response.headers["x-current-page"]);
      totalPages.value = Number(response.headers["x-total-pages"]);
      totalCount.value = Number(response.headers["x-total-count"]);

      const url = categoryId ? `/api/v2/core/admin/manual_categories/${categoryId}` : "/api/v2/core/admin/manual_categories";
      const response2 = await axios.get(url);
      manualCategories.value = new ManualCategory(response2.data).children;

      isFirstLoading.value = false;
      isLoading.value = false;
    }

    watch(useQuery(), (value: Dictionary<string | (string | null)[] | null | undefined>) => {
      manualCategoryId.value = value.categoryId;
      fetchManuals(value.categoryId);
    });

    return {
      isFirstLoading,
      isLoading,
      manuals,
      manualCategoryId,
      manualCategories,
      currentPage,
      totalPages,
      totalCount,
    };
  },
});
