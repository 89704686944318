import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { DateTime } from "luxon";

export interface Iryohoken {
  id: number;
  fileName: string;
  fileDate: string;
}

interface IryohokensFindListParams {
  fromDate?: DateTime;
  page?: number;
}

interface IryohokensFindListRequestParams {
  fromDate?: DateTime;
  page?: number;
}

function createIryohokensStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<Iryohoken>(),
    ...initStoreActionFindListState<Iryohoken>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/iryohokens`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<Iryohoken, IryohokensFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/iryohokens`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: IryohokensFindListRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createIryohokensStore, "iryohokensStore");

export const provideIryohokensStore = provider.provideStore;
export const useIryohokensStore = provider.useStore;

export function useIryohoken(iryohokenId: number, withFetch?: boolean, noCache?: boolean) {
  const iryohokensStore = useIryohokensStore();
  if (withFetch) {
    iryohokensStore.fetch(iryohokenId, noCache);
  }
  let iryohoken: Iryohoken | null = null;

  return computed(() => {
    if (iryohoken && !noCache) {
      return iryohoken;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    iryohokensStore.isFetching.value;
    iryohoken = iryohokensStore.get(iryohokenId);
    return iryohoken;
  });
}
