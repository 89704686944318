import { RootState } from "@/store";
import { Module } from "vuex";
import { DomainMasterStore } from "./master";

export type DomainStoreState = {};

export const DomainStore: Module<DomainStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    master: DomainMasterStore,
  },
};
