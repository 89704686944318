import { RootState } from "@/store";
import { Module } from "vuex";
import { UIMasterEthicalMedicineListStore } from "./list";
import { UIMasterEthicalMedicineShowStore } from "./show";

type UIMasterEthicalMedicineStoreState = {};

export const UIMasterEthicalMedicineStore: Module<UIMasterEthicalMedicineStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    list: UIMasterEthicalMedicineListStore,
    show: UIMasterEthicalMedicineShowStore,
  },
};
