


































































import { ref, defineComponent, watch } from "@vue/composition-api";

import { useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";

export default defineComponent({
  name: "OrderableItemFixtures",
  setup() {
    const orderableItemFixturesStore = useOrderableItemFixturesStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "name", label: "医薬品名" },
      { key: "nameAppend", label: "名前追加" },
      { key: "gs1Code", label: "GS1コード" },
      { key: "janCode", label: "JANコード" },
      { key: "stockableItemFixtureId", label: "在庫医薬品ID" },
      { key: "quantity", label: "用量" },
      { key: "makerName", label: "製造会社" },
      { key: "sellerName", label: "販売会社" },
      { key: "packetForm", label: "包装形式" },
      { key: "packetVolume", label: "包装用量" },
      { key: "packagePacketCount", label: "包装個数" },
      { key: "inactiveOn", label: "無効日" },
    ];

    async function fetchOrderableItemFixtures(page: number) {
      orderableItemFixturesStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchOrderableItemFixtures(page);
    });

    return {
      fields,
      orderableItemFixtures: orderableItemFixturesStore.list,
      isFindingOrderableItemFixtures: orderableItemFixturesStore.isFindingList,
      totalCount: orderableItemFixturesStore.totalCount,
      totalPages: orderableItemFixturesStore.totalPages,
      currentPage,
    };
  },
});
