<template>
  <div>
    <CCard>
      <CCardHeader>
        <span v-if="id">編集画面</span>
        <span v-else>新規作成</span>
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" to="."> <CIcon name="cil-chevron-left" /> キャンセル </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput v-model="group.id" label="近隣グループID" horizontal disabled />
          <CInput v-model="group.name" label="名前" horizontal />
        </CForm>

        <CAlert :show.sync="successAlertCountDown" color="success" close-button>{{ saveResult }}</CAlert>
        <CAlert :show="shouldShowDangerAlert" color="danger">
          <ul v-for="error in errors" :key="error" class="mb-0">
            <li>{{ error }}</li>
          </ul>
        </CAlert>
      </CCardBody>
      <CCardFooter>
        <CButton color="secondary" :to="`.`"> キャンセル </CButton>
        <div class="float-right">
          <CButton v-if="id" color="primary" @click="saveGroup()">保存</CButton>
          <CButton v-else color="primary" @click="createGroup()">新規作成</CButton>
        </div>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "EditGroup",
  data() {
    return {
      id: null,
      group: {},
      saveResult: null,
      successAlertCountDown: 0,
      errors: [],
    };
  },
  computed: {
    shouldShowDangerAlert() {
      return this.errors.length > 0;
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) this.fetchGroup();
  },
  methods: {
    async fetchGroup() {
      if (this.id) {
        this.group = (await this.$axios.get(`/api/v2/core/admin/groups/${this.id}`)).data;
      }
    },
    async createGroup() {
      try {
        const response = (await this.$axios.post("/api/v2/core/admin/groups", this.group)).data;
        this.saveResult = "新規作成しました。";
        this.successAlertCountDown = 3;
        await this.$router.push({ name: "近隣グループ情報", params: { id: response.id } });
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
    async saveGroup() {
      try {
        await this.$axios.put(`/api/v2/core/admin/groups/${this.id}`, this.group);
        this.saveResult = "更新成功しました。";
        this.successAlertCountDown = 3;
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>
