import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { DateTime } from "luxon";

export interface MedisBase {
  id: number;
  fileName: string;
  fileDate: string;
}

interface MedisBasesFindListParams {
  fromDate?: DateTime;
  page?: number;
}

interface MedisBasesFindListRequestParams {
  fromDate?: DateTime;
  page?: number;
}

function createMedisBasesStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisBase>(),
    ...initStoreActionFindListState<MedisBase>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_bases`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisBase, MedisBasesFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_bases`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisBasesFindListRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisBasesStore, "medisBasesStore");

export const provideMedisBasesStore = provider.provideStore;
export const useMedisBasesStore = provider.useStore;

export function useMedisBase(medisBaseId: number, withFetch?: boolean, noCache?: boolean) {
  const medisBasesStore = useMedisBasesStore();
  if (withFetch) {
    medisBasesStore.fetch(medisBaseId, noCache);
  }
  let medisBase: MedisBase | null = null;

  return computed(() => {
    if (medisBase && !noCache) {
      return medisBase;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisBasesStore.isFetching.value;
    medisBase = medisBasesStore.get(medisBaseId);
    return medisBase;
  });
}
