<template>
  <CCard>
    <CCardHeader>
      詳細情報
      <div class="card-header-actions">
        <CLink class="card-header-action btn-setting text-primary" :to="{ name: '近隣グループ編集', params: { id: id } }">
          <CIcon name="cil-pencil" />
          編集する
        </CLink>
      </div>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput label="近隣グループID" :value="group.id" horizontal plaintext disabled />
        <CInput label="名前" :value="group.name" horizontal plaintext disabled />
        <CInput label="所属薬局数" :value="group.pharmacies_count" horizontal plaintext disabled />
        <CInput label="作成日" :value="group.created_at" horizontal plaintext disabled />
        <CInput label="更新日" :value="group.updated_at" horizontal plaintext disabled />
        <CInput label="作成者" :value="group.created_by" horizontal plaintext disabled />
        <CInput label="更新者" :value="group.updated_by" horizontal plaintext disabled />
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "GroupDetails",
  data() {
    return {
      id: null,
      group: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchGroup();
  },
  methods: {
    async fetchGroup() {
      if (this.id) this.group = (await this.$axios.get(`/api/v2/core/admin/groups/${this.id}`)).data;
    },
  },
};
</script>
