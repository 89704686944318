































































import { SetupContext, reactive, computed, toRefs, onMounted } from "@vue/composition-api";
import { Data } from "@vue/composition-api/dist/component";
import { EthicalMedicine } from "@/models/ethicalMedicine";
import { BigNumber } from "bignumber.js";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";

type Field = {
  key: string;
  label: string;
  _classes?: string | string[];
  sorter: boolean;
};

const fields: Field[] = [
  { key: "id", label: "ID", sorter: true },
  { key: "brandName", label: "商品名", sorter: false },
  { key: "brandNamePronunciation", label: "商品名（読み）", sorter: false },
  { key: "hotCode", label: "HotCode", sorter: false },
  { key: "hotCode_7", label: "HotCode 7", sorter: false },
  { key: "hotCode_9", label: "HotCode 9", sorter: false },
  { key: "hotCodeGeneric", label: "一般名用 HotCode 9", sorter: false },
  { key: "packageGs1Code", label: "販売包装単位コード", sorter: false },
  { key: "janCode", label: "JANコード", sorter: false },
  { key: "packetGs1Code", label: "調剤包装単位コード", sorter: false },
  { key: "supplyGs1Code", label: "元梱包装単位コード", sorter: false },
  { key: "mhlwCode", label: "厚生労働省（薬価基準収載医薬品）コード", sorter: false },
  { key: "yjCode", label: "個別医薬品（YJ）コード", sorter: false },
  { key: "receiptCode", label: "レセプト電算処理システムコード", sorter: false },
  { key: "receiptCodeSecondary", label: "レセプト電算処理システムコード(2)", sorter: false },
  { key: "genericCode", label: "一般名コード", sorter: false },
  { key: "receiptSystemName", label: "レセプト電算処理システム医薬品名", sorter: false },
  { key: "genericName", label: "一般名", sorter: false },
  { key: "ingredientName", label: "成分名", sorter: false },
  { key: "dosageType", label: "区分", sorter: false },
  { key: "unitType", label: "剤形", sorter: false },
  { key: "standard", label: "規格", sorter: false },
  { key: "maker", label: "製造会社", sorter: false },
  { key: "seller", label: "販売会社", sorter: false },
  { key: "packetForm", label: "包装形態", sorter: false },
  { key: "packetFormSubdivision", label: "調剤包装単位細区分", sorter: false },
  { key: "packetVolume", label: "包装単位数", sorter: false },
  { key: "packetUnitName", label: "包装単位数単位", sorter: false },
  { key: "packetPerSheet", label: "一連の包装数", sorter: false },
  { key: "packageVolume", label: "包装総量数", sorter: false },
  { key: "packageVolumeUnitName", label: "包装総量数単位", sorter: false },
  { key: "packagePacketCount", label: "包装数量数", sorter: false },
  { key: "packagePacketCountUnitName", label: "包装数量数単位", sorter: false },
  { key: "publicNoticeType", label: "告示分類", sorter: false },
  { key: "officialName", label: "告示名称", sorter: false },
  { key: "officialRegisteredOn", label: "告示日", sorter: false },
  { key: "officialUnitPrice", label: "薬価", sorter: false, _classes: "text-right" },
  { key: "interimPeriodOn", label: "経過措置日", sorter: false },
  { key: "generic", label: "後発品", sorter: false },
  { key: "narcotic", label: "麻薬", sorter: false },
  { key: "poison", label: "毒薬", sorter: false },
  { key: "stimulantMaterial", label: "覚醒剤原料", sorter: false },
  { key: "psychotropic", label: "向精神薬", sorter: false },
  { key: "biotic", label: "生物由来製品", sorter: false },
  { key: "designatedBiotic", label: "特定生物由来製品", sorter: false },
  { key: "regenerative", label: "指定再生医療等製品", sorter: false },
  { key: "clinicalSample", label: "臨床試用医薬品", sorter: false },
  { key: "intracutaneousReaction", label: "皮内反応薬", sorter: false },
  { key: "inactiveOn", label: "データ無効日", sorter: false },
];

type EthicalMedicineFormat = Omit<
  EthicalMedicine,
  | "hotCodeGeneric"
  | "brandNamePronunciation"
  | "packetGs1Code"
  | "supplyGs1Code"
  | "mhlwCode"
  | "receiptCode"
  | "receiptCodeSecondary"
  | "genericCode"
  | "unitType"
  | "packetFormSubdivision"
  | "receiptSystemName"
  | "genericName"
  | "ingredientName"
  | "packetPerSheet"
  | "officialUnitPrice"
  | "generic"
  | "narcotic"
  | "poison"
  | "stimulantMaterial"
  | "psychotropic"
  | "biotic"
  | "designatedBiotic"
  | "regenerative"
  | "clinicalSample"
  | "intracutaneousReaction"
> & {
  hotCodeGeneric: string;
  packetGs1Code: string;
  supplyGs1Code: string;
  mhlwCode: string;
  receiptCode: string;
  receiptCodeSecondary: string;
  genericCode: string;
  brandNamePronunciation: string;
  receiptSystemName: string;
  genericName: string;
  ingredientName: string;
  unitType: string;
  packetFormSubdivision: string;
  packetPerSheet: string;
  publicNoticeType: string;
  officialUnitPrice: string;
  generic: string;
  narcotic: string;
  poison: string;
  stimulantMaterial: string;
  psychotropic: string;
  biotic: string;
  designatedBiotic: string;
  regenerative: string;
  clinicalSample: string;
  intracutaneousReaction: string;
};

function nullToEmpty<T>(value: T | null): string {
  if (value) {
    return String(value);
  }

  return "";
}

export default {
  components: { LoadingAlert },
  setup(props: Data, context: SetupContext) {
    const store = context.root.$store;
    const storeStateUI = store.state.ui.master.ethicalMedicine.list;
    const storeStateDomain = store.state.domain.master.ethicalMedicine;

    const uiStorePrefix = "ui/master/ethicalMedicine/list";

    const toFormat = (medicine: EthicalMedicine): EthicalMedicineFormat => {
      const booleanToString = (value: boolean) => (value ? "はい" : "いいえ");

      return Object.assign(
        {},
        {
          ...medicine,
          hotCodeGeneric: nullToEmpty(medicine.hotCodeGeneric),
          packetGs1Code: nullToEmpty(medicine.packetGs1Code),
          supplyGs1Code: nullToEmpty(medicine.supplyGs1Code),
          packetFormSubdivision: nullToEmpty(medicine.packetFormSubdivision),
          packetPerSheet: nullToEmpty(medicine.packetPerSheet),
          mhlwCode: nullToEmpty(medicine.mhlwCode),
          receiptCode: nullToEmpty(medicine.receiptCode),
          receiptCodeSecondary: nullToEmpty(medicine.receiptCodeSecondary),
          genericCode: nullToEmpty(medicine.genericCode),
          brandNamePronunciation: nullToEmpty(medicine.brandNamePronunciation),
          receiptSystemName: nullToEmpty(medicine.receiptSystemName),
          genericName: nullToEmpty(medicine.genericName),
          ingredientName: nullToEmpty(medicine.ingredientName),
          unitType: nullToEmpty(medicine.unitType),
          publicNoticeType: nullToEmpty(medicine.publicNoticeType),
          officialUnitPrice: (() => {
            if (medicine.officialUnitPrice === null) {
              return "";
            }
            return new BigNumber(medicine.officialUnitPrice).toFormat(1);
          })(),
          generic: booleanToString(medicine.generic),
          narcotic: booleanToString(medicine.narcotic),
          poison: booleanToString(medicine.poison),
          stimulantMaterial: booleanToString(medicine.stimulantMaterial),
          psychotropic: booleanToString(medicine.psychotropic),
          biotic: booleanToString(medicine.biotic),
          designatedBiotic: booleanToString(medicine.designatedBiotic),
          regenerative: booleanToString(medicine.regenerative),
          clinicalSample: booleanToString(medicine.clinicalSample),
          intracutaneousReaction: booleanToString(medicine.intracutaneousReaction),
        }
      );
    };

    const state = reactive({
      isFirstFetched: computed(() => storeStateUI.isFirstFetched),
      isFetching: computed(() => storeStateUI.isFetching),
      currentPage: computed({
        get: () => storeStateUI.currentPage,
        set: (value) => {
          store.dispatch(`${uiStorePrefix}/updateCurrentPage`, {
            currentPage: value,
          });
        },
      }),
      includeInactive: computed({
        get: () => storeStateUI.includeInactive,
        set: (value) => {
          store.dispatch(`${uiStorePrefix}/updateIncludeInactive`, {
            includeInactive: value,
          });
        },
      }),
      query: computed({
        get: () => storeStateUI.query,
        set: (value) => {
          store.dispatch(`${uiStorePrefix}/updateQuery`, {
            query: value,
          });
        },
      }),
      medicines: computed(() => storeStateUI.medicineIds.map((id: number) => toFormat(storeStateDomain.medicines[id]))),
      totalCount: computed(() => storeStateUI.totalCount),
      totalPages: computed(() => storeStateUI.totalPages),
      sorter: { external: true, resetable: false },
      sorterValue: { column: "id", asc: true },
    });

    const fetchAll = () => {
      store.dispatch(`${uiStorePrefix}/fetchAll`);
    };

    const searchMedicines = () => {
      if (!storeStateUI.isFetching) {
        fetchAll();
      }
    };

    onMounted(() => fetchAll());

    return { ...toRefs(state), fields, searchMedicines };
  },
};
