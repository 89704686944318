import { ChatFileClass } from "@/models/lib/vue-advanced-chat/chatFileClass";
import { DateTime } from "luxon";
import { Message, MessageFile, MessageReactions, StringNumber } from "vue-advanced-chat";

export class ChatMessageClass implements Message {
  id!: number;
  _id!: string | number;
  content!: string;
  sender_id!: StringNumber;
  senderId!: StringNumber;
  reply_message?: ChatMessageClass | null;
  replyMessage?: ChatMessageClass | null;
  pharmacyName?: string;
  username?: string;
  date!: string;
  timestamp!: string;
  system?: boolean;
  saved?: boolean;
  edited?: boolean;
  distributed?: boolean;
  seen?: boolean;
  disableActions?: boolean;
  disableReactions?: boolean;
  file?: MessageFile;
  files?: MessageFile[];
  reactions!: MessageReactions;
  created_at?: string;
  createdAt?: DateTime;
  updated_at?: string;
  updatedAt?: DateTime;

  static create(message: ChatMessageClass): ChatMessageClass {
    message._id = message.id;
    if (message.sender_id) message.senderId = message.sender_id;
    if (message.senderId) message.sender_id = message.senderId;

    const createdAt = message.createdAt?.toJSDate() || message.created_at;
    message.date = createdAt ? new Date(createdAt).toLocaleDateString() : "-";
    message.timestamp = createdAt ? new Date(createdAt).toLocaleString() : "-";

    message.saved = true;
    message.seen = true;
    message.disableActions = false;
    message.disableReactions = true;

    const replyMessage = message.replyMessage || message.reply_message;
    if (replyMessage) message.replyMessage = ChatMessageClass.create(replyMessage);

    if (message.files && message.files.length > 0) {
      message.file = ChatFileClass.create(message.files[0]);
    }
    return message;
  }
}
