








import { provideAuth0Store } from "@/store/auth0Store";
import { provideBatchConfigStore } from "@/store/batchConfigStore";
import { provideInquiryMessagesStore } from "@/store/inquiryMessagesStore";
import { provideInquiryRoomsStore } from "@/store/inquiryRoomsStore";
import { provideIryohokenRowsStore } from "@/store/iryohokenRowsStore";
import { provideIryohokensStore } from "@/store/iryohokensStore";
import { provideMedhotRowsStore } from "@/store/medhotRowsStore";
import { provideMedisBaseRowsStore } from "@/store/medisBaseRowsStore";
import { provideMedisBasesStore } from "@/store/medisBasesStore";
import { provideMedisGs1RowsStore } from "@/store/medisGs1RowsStore";
import { provideMedisGs1sStore } from "@/store/medisGs1sStore";
import { provideMedisOptionRowsStore } from "@/store/medisOptionRowsStore";
import { provideMedisOptionsStore } from "@/store/medisOptionsStore";
import { providePharmaciesStore } from "@/store/pharmaciesStore";
import { provideStockableItemImportRequestsStore } from "@/store/stockableItemImportRequestsStore";
import { provideStockableItemImportsStore } from "@/store/stockableItemImportsStore";
import { provideOrderableItemImportsStore } from "@/store/orderableItemImportsStore";
import { provideStockableItemsStore } from "@/store/stockableItemsStore";
import { provideStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";
import { provideOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";
import { provideUnknownSdcvanDeliveryDRecordsStore } from "./store/unknownSdcvanDeliveryDRecordsStore";

import "@coreui/coreui";
import { defineComponent } from "@vue/composition-api";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default defineComponent({
  name: "App",
  setup(props, context) {
    // Auth0
    provideAuth0Store(context);

    // Master
    provideStockableItemsStore(context);

    // Core
    provideInquiryMessagesStore(context);
    provideInquiryRoomsStore(context);
    provideMedisBasesStore(context);
    provideMedisBaseRowsStore(context);
    provideMedisGs1sStore(context);
    provideMedisGs1RowsStore(context);
    provideMedisOptionsStore(context);
    provideMedisOptionRowsStore(context);
    provideMedhotRowsStore(context);
    provideIryohokensStore(context);
    provideIryohokenRowsStore(context);
    provideStockableItemImportsStore(context);
    provideOrderableItemImportsStore(context);
    provideStockableItemImportRequestsStore(context);
    providePharmaciesStore(context);

    // Medorder
    provideBatchConfigStore(context);
    provideStockableItemFixturesStore(context);
    provideOrderableItemFixturesStore(context);
    provideUnknownSdcvanDeliveryDRecordsStore(context);
  },
});
