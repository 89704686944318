import { ethicalMedicineApi } from "@/api/master/ethicalMedicine";
import { RootState } from "@/store";
import { ActionUpdateMedicinesPayload } from "@/store/domain/master/ethicalMedicine";
import Axios from "axios";
import { Module } from "vuex";

export type UIMasterEthicalMedicineStoreState = {
  isFetched: boolean;
};

export type ActionFetchPayload = {
  id: number;
};

type MutationsUpdateStatePayload = {
  isFetched: boolean;
};

const apiClient = ethicalMedicineApi();

export const UIMasterEthicalMedicineShowStore: Module<UIMasterEthicalMedicineStoreState, RootState> = {
  namespaced: true,
  state: {
    isFetched: false,
  },
  mutations: {
    updateIsFetched(state) {
      state.isFetched = true;
    },
  },
  actions: {
    cancelAll() {
      apiClient.cancelAll();
    },
    async fetch(context, payload: ActionFetchPayload) {
      try {
        const response = await apiClient.fetch(payload.id);

        const updateMedicinesPayload: ActionUpdateMedicinesPayload = {
          medicines: [response.data],
        };
        await context.dispatch("domain/master/ethicalMedicine/updateMedicines", updateMedicinesPayload, { root: true });
        context.commit("updateIsFetched");
      } catch (error) {
        if (!Axios.isCancel(error)) {
          console.error(error);
          throw error;
        }
      }
    },
  },
};
