































































import { defineComponent, ref } from "@vue/composition-api";

import { StockableItemFixturesPatchParams, useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";

export default defineComponent({
  name: "StockableItemFixtureEdit",
  setup(props, context) {
    const stockableItemFixtureId = parseInt(context.root.$route.params.id, 10);

    const stockableItemFixturesStore = useStockableItemFixturesStore();

    const inputName = ref<string>(null);
    const inputUnit = ref<string>(null);
    const inputPrice = ref<string>(null);
    const inputYjCode = ref<string>(null);
    const inputReceiptCode = ref<string>(null);
    const inputGenericId = ref<string>(null);
    const selectGeneric = ref<boolean>(null);
    const inputInterimPeriodOn = ref<string>(null);
    const selectDisableStock = ref<boolean>(null);
    const textareaNote = ref<string>(null);

    (async () => {
      await stockableItemFixturesStore.fetch(stockableItemFixtureId);
      const stockableItemFixture = stockableItemFixturesStore.get(stockableItemFixtureId);
      if (!stockableItemFixture) {
        return;
      }
      inputName.value = stockableItemFixture.name;
      inputUnit.value = stockableItemFixture.unit;
      inputPrice.value = stockableItemFixture.price ? String(stockableItemFixture.price) : null;
      inputYjCode.value = stockableItemFixture.yjCode;
      inputReceiptCode.value = stockableItemFixture.receiptCode;
      inputGenericId.value = stockableItemFixture.genericId ? String(stockableItemFixture.genericId) : null;
      selectGeneric.value = stockableItemFixture.generic;
      inputInterimPeriodOn.value = stockableItemFixture.interimPeriodOn
        ? stockableItemFixture.interimPeriodOn.toFormat("yyyy-MM-dd")
        : null;
      selectDisableStock.value = stockableItemFixture.disableStock;
      textareaNote.value = stockableItemFixture.note;
    })();

    async function update() {
      const stockableItemFixture = stockableItemFixturesStore.get(stockableItemFixtureId);
      if (!stockableItemFixture) {
        return;
      }
      const params: StockableItemFixturesPatchParams = {
        id: stockableItemFixtureId,
      };
      if (inputName.value !== stockableItemFixture.name) {
        params.name = inputName.value;
      }
      if (inputUnit.value !== stockableItemFixture.unit) {
        params.name = inputName.value;
      }
      if (inputPrice.value !== stockableItemFixture.price) {
        params.price = inputPrice.value === null ? null : parseInt(inputPrice.value, 10);
      }
      if (inputYjCode.value !== stockableItemFixture.yjCode) {
        params.yjCode = inputYjCode.value;
      }
      if (inputReceiptCode.value !== stockableItemFixture.receiptCode) {
        params.receiptCode = inputReceiptCode.value;
      }
      if (inputGenericId.value !== stockableItemFixture.genericId) {
        params.genericId = inputGenericId.value === null ? null : parseInt(inputGenericId.value, 10);
      }
      if (selectGeneric.value !== stockableItemFixture.generic) {
        params.generic = selectGeneric.value;
      }
      if (inputInterimPeriodOn.value !== stockableItemFixture.interimPeriodOn) {
        params.interimPeriodOn = inputInterimPeriodOn.value;
      }
      if (selectDisableStock.value !== stockableItemFixture.disableStock) {
        params.disableStock = selectDisableStock.value;
      }
      if (textareaNote.value) {
        params.note = textareaNote.value;
      }
      await stockableItemFixturesStore.patch(params);
      context.root.$router.push({ name: "在庫医薬品マスター手動登録" });
    }

    return {
      inputName,
      inputUnit,
      inputPrice,
      inputYjCode,
      inputReceiptCode,
      inputGenericId,
      selectGeneric,
      inputInterimPeriodOn,
      selectDisableStock,
      textareaNote,
      update,
    };
  },
});
