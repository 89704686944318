import { PaginatedResponse } from "@/api/paginatedResponse";
import { Response } from "@/api/response";
import { AxiosInstance } from "axios";
import { Interceptor } from "./interceptor";

const paginationHeaders = ["x-current-page", "x-total-pages", "x-total-count"] as const;

export const responseInterceptor: Interceptor = {
  apply: (axios: AxiosInstance) => {
    axios.interceptors.response.use((response) => {
      if (paginationHeaders.every((headerName) => Object.prototype.hasOwnProperty.call(response.headers, headerName)) && response.data) {
        const paginatedData: PaginatedResponse<unknown> = {
          data: response.data,
          currentPage: Number(response.headers["x-current-page"]),
          totalPages: Number(response.headers["x-total-pages"]),
          totalCount: Number(response.headers["x-total-count"]),
          statusCode: response.status,
        };
        response.data = paginatedData;
      } else {
        const data: Response<unknown> = {
          data: response.data,
          statusCode: response.status,
        };
        response.data = data;
      }

      return response;
    });
  },
};
