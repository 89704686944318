import { SetupContext, computed } from "@vue/composition-api";
import { AxiosResponse } from "axios";

import { StoreItem, toStoreItems } from "@/store/utils";
import { StoreActionFindListState, actionFindListCommitUpdateItems } from "@/store/actions/createActionFindList";

interface StoreActionPatchState {
  isPatching: boolean;
}

interface RequstFunc<P> {
  (context: SetupContext, params: P): Promise<AxiosResponse>;
}

interface Options<T extends StoreItem, P> {
  findListState?: StoreActionFindListState<T>;
  afterEndPatch?: (item: T, params: P) => void;
}

export function initStoreActionPatchState() {
  return {
    isPatching: false,
  };
}

function commitStartPatch<T extends StoreItem>(state: StoreActionPatchState) {
  state.isPatching = true;
}
function commitEndPatch<T extends StoreItem>(state: StoreActionPatchState) {
  state.isPatching = false;
}

async function patchBase<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionPatchState,
  params: P,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  // TODO: 二重リクエスト防止
  commitStartPatch(state);
  let response = null;
  try {
    response = await requestFunc(context, params);
  } finally {
    commitEndPatch(state);
  }
  const item: T = toStoreItems(response.data);

  if (options) {
    if (options.findListState) {
      actionFindListCommitUpdateItems(options.findListState, [item]);
    }
    if (options.afterEndPatch) {
      options.afterEndPatch(item, params);
    }
  }

  return item;
}

export function createActionPatch<T extends StoreItem, P>(
  context: SetupContext,
  state: StoreActionPatchState,
  requestFunc: RequstFunc<P>,
  options?: Options<T, P>
) {
  async function patch(params: P) {
    return patchBase(context, state, params, requestFunc, options);
  }

  const isPatching = computed(() => {
    return state.isPatching;
  });

  return {
    patch,
    isPatching,
  };
}
