









































































import { computed, defineComponent } from "@vue/composition-api";

import { useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";
import { useStockableItemImportsStore } from "@/store/stockableItemImportsStore";

import StockableItemImport from "@/components/masterSources/StockableItemImport.vue";

export default defineComponent({
  name: "StockableItemFixtureDetails",
  components: {
    StockableItemImport,
  },
  setup(props, context) {
    const stockableItemFixtureId = parseInt(context.root.$route.params.id, 10);

    const stockableItemFixturesStore = useStockableItemFixturesStore();
    const stockableItemImportsStore = useStockableItemImportsStore();

    stockableItemFixturesStore.fetch(stockableItemFixtureId);
    stockableItemImportsStore.fetchByStockableItemFixtureId(stockableItemFixtureId);

    const stockableItemFixture = computed(() => {
      stockableItemFixturesStore.isFetching.value;
      return stockableItemFixturesStore.get(stockableItemFixtureId);
    });

    const stockableItemImport = computed(() => {
      stockableItemImportsStore.isFetching.value;
      return stockableItemImportsStore.getByStockableItemFixtureId(stockableItemFixtureId);
    });

    async function importStockableItem() {
      stockableItemImportsStore.create({
        stockableItemFixtureId,
      });
      context.root.$router.push({ name: "発注医薬品マスター手動登録" });
    }

    const hasDiff = computed(() => {
      if (!stockableItemImport.value) {
        return false;
      }
      return stockableItemImport.value.stockableItemOperation !== "none";
    });

    return {
      stockableItemFixture,
      stockableItemImport,
      importStockableItem,
      hasDiff,
    };
  },
});
