var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{attrs:{"hover":"","bordered":"","fixed":"","responsive":"","loading":_vm.loading,"items":_vm.medisGs1Rows,"fields":_vm.fields},scopedSlots:_vm._u([{key:"hotCode",fn:function(ref){
var medisBase = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":{ name: 'HOTコード 詳細', params: { hotCode: medisBase.hotCode } }}},[_vm._v(_vm._s(medisBase.hotCode))])],1)]}},{key:"brandName",fn:function(ref){
var medisBase = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(medisBase.brandName))])]}},{key:"standard",fn:function(ref){
var medisBase = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(medisBase.standard))])]}},{key:"registeredOn",fn:function(ref){
var medisBase = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(medisBase.registeredOn.toFormat("yyyy年M月d日")))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }