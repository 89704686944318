








































































































import { computed, defineComponent, watch } from "@vue/composition-api";

import { useStockableItemImportsStore } from "@/store/stockableItemImportsStore";
import { useMedisBaseRowsStore } from "@/store/medisBaseRowsStore";
import { useMedisGs1RowsStore } from "@/store/medisGs1RowsStore";
import { useStockableItemFixturesStore } from "@/store/stockableItemFixturesStore";

import StockableItemImport from "@/components/masterSources/StockableItemImport.vue";
import IryohokenRowsDataTable from "@/components/masterSources/IryohokenRowsDataTable.vue";
import MedisBaseRowsDataTable from "@/components/masterSources/MedisBaseRowsDataTable.vue";
import MedisGs1RowsDataTable from "@/components/masterSources/MedisGs1RowsDataTable.vue";
import { useIryohokenRowsStore } from "@/store/iryohokenRowsStore";

export default defineComponent({
  name: "ReceiptCodeDetails",
  components: {
    StockableItemImport,
    IryohokenRowsDataTable,
    MedisBaseRowsDataTable,
    MedisGs1RowsDataTable,
  },
  setup(props, context) {
    const receiptCode = context.root.$route.params["receiptCode"];

    const stockableItemImportsStore = useStockableItemImportsStore();
    const iryohokenRowsStore = useIryohokenRowsStore();
    const medisBaseRowsStore = useMedisBaseRowsStore();
    const medisGs1RowsStore = useMedisGs1RowsStore();
    const stockableItemFixturesStore = useStockableItemFixturesStore();

    const stockableItemImport = computed(() => {
      stockableItemImportsStore.isFetching.value;
      return stockableItemImportsStore.getByReceiptCode(receiptCode);
    });

    const hasDiff = computed(() => {
      if (!stockableItemImport.value) {
        return false;
      }
      return stockableItemImport.value.stockableItemOperation !== "none";
    });

    stockableItemImportsStore.fetchByReceiptCode(receiptCode);
    iryohokenRowsStore.findList({
      receiptCode,
    });

    medisBaseRowsStore.findList({
      receiptCode,
    });
    const hotCodes = computed(() => {
      const hotCodeSet = new Set<string>();
      for (const medisBaseRow of medisBaseRowsStore.list.value) {
        hotCodeSet.add(medisBaseRow.hotCode);
      }
      return Array.from(hotCodeSet);
    });
    const yjCode = computed(() => {
      if (medisBaseRowsStore.list.value.length === 0) {
        return null;
      }
      const medisBaseRow = medisBaseRowsStore.list.value[0];
      return medisBaseRow.yjCode;
    });

    watch(hotCodes, (newHotCodes) => {
      medisGs1RowsStore.findList({
        hotCodes: newHotCodes,
      });
    });

    watch(yjCode, (newYjCode) => {
      if (newYjCode) {
        stockableItemFixturesStore.fetchByYjCode(newYjCode);
      }
    });

    const stockableItemFixture = computed(() => {
      stockableItemFixturesStore.isFetching.value;
      if (!yjCode.value) {
        return null;
      }
      return stockableItemFixturesStore.getByYjCode(yjCode.value);
    });

    async function importByReceiptCode() {
      await stockableItemImportsStore.create({
        receiptCode,
      });
      stockableItemImportsStore.fetchByReceiptCode(receiptCode, true);
    }

    return {
      receiptCode,
      stockableItemImport,
      iryohokenRows: iryohokenRowsStore.list,
      isFindingIryohokenRows: iryohokenRowsStore.isFindingList,
      medisBaseRows: medisBaseRowsStore.list,
      isFindingMedisBaseRows: medisBaseRowsStore.isFindingList,
      medisGs1Rows: medisGs1RowsStore.list,
      isFindingMedisGs1Rows: medisGs1RowsStore.isFindingList,
      yjCode,
      hotCodes,
      stockableItemFixture,
      importByReceiptCode,
      hasDiff,
    };
  },
});
