import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import {
  actionFetchItemCommitReplaceItem,
  initStoreActionFetchItemState,
  ActionFetchItemBuilder,
} from "@/store/actions/createActionFetchItem";
import { createActionPut, initStoreActionPutState } from "@/store/actions/createActionPut";

export interface BatchConfig {
  id: number;
  useSecondary: boolean;
  ttl?: number;
}

export interface BatchConfigPutParams {
  pharmacyId: number;
  ttl?: number;
}

function createBatchConfigStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<BatchConfig>(),
    ...initStoreActionPutState(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, (ids) => {
    return `/api/v2/admin/pharmacies/${ids}/batch_config`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionPut = createActionPut<BatchConfig, BatchConfigPutParams>(
    context,
    state,
    (context, params) => {
      const pharmacyId = params.pharmacyId;
      const url = `/api/v2/admin/pharmacies/${pharmacyId}/batch_config`;
      return context.root.$httpMedorder.put(url, {
        ttl: params.ttl,
      });
    },
    {
      afterEndPut: (item) => {
        actionFetchItemCommitReplaceItem(state, item);
      },
    }
  );

  return {
    ...actionFetch,
    ...actionPut,
  };
}

const provider = createStoreProvider(createBatchConfigStore, "batchConfigStore");

export const provideBatchConfigStore = provider.provideStore;
export const useBatchConfigStore = provider.useStore;

export function useBatchConfig(pharmacyId: number, withFetch?: boolean, noCache?: boolean) {
  const batchConfigStore = useBatchConfigStore();
  if (withFetch) {
    batchConfigStore.fetch(pharmacyId, noCache);
  }
  let batchConfig: BatchConfig | null = null;

  return computed(() => {
    if (batchConfig && !noCache) {
      return batchConfig;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    batchConfigStore.isFetching.value;
    batchConfig = batchConfigStore.get(pharmacyId);
    return batchConfig;
  });
}
