




import { defineComponent } from "@vue/composition-api";
import moment, { Moment } from "moment";
moment.locale("ja");

export default defineComponent({
  name: "FromNowSpan",
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const dateMoment = moment(props.date);
    const dateString = moment(props.date).format();

    function fromNow(date: Moment) {
      return date.fromNow();
    }

    return {
      dateMoment,
      dateString,

      fromNow,
    };
  },
});
