var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFirstLoading)?_c('LoadingAlert'):_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.total_count)+" 件の薬局を表示 "),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action btn-setting text-primary",attrs:{"to":{ name: '薬局新規作成' }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v("新規作成する ")],1)],1)]),_c('CCardBody',[_c('CForm',{on:{"submit":function($event){$event.preventDefault();return _vm.searchPharmacies($event)}}},[_c('CInput',{attrs:{"placeholder":"薬局名で検索"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CButton',{attrs:{"type":"submit","color":"info"}},[_vm._v("検索")])]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('CRow',{staticClass:"form-group mt-4"},[_c('CCol',[_c('label',{staticClass:"text-muted mb-1"},[_vm._v("退会済みも表示する")]),_c('div',{staticClass:"input-group mb-3"},[_c('CSwitch',{attrs:{"checked":_vm.addEndedPharmacies,"color":"danger","variant":"3d"},on:{"update:checked":[function($event){_vm.addEndedPharmacies=$event},_vm.searchPharmacies]}})],1)])],1),_c('CDataTable',{attrs:{"hover":"","bordered":"","fixed":"","responsive":"","loading":_vm.isLoading,"items":_vm.pharmacies,"fields":_vm.fields,"sorter":_vm.sorter,"sorter-value":_vm.sorterValue},on:{"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var pharmacy = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":{ name: '薬局情報', params: { id: pharmacy.id } }}},[_vm._v(_vm._s(pharmacy.name))])],1)]}},{key:"company_id",fn:function(ref){
var pharmacy = ref.item;
return [_c('td',[(pharmacy.company_id)?_c('CLink',{attrs:{"to":{ name: '薬局会社情報', params: { id: pharmacy.company_id } }}},[_vm._v(" "+_vm._s(pharmacy.company_id)+" ")]):_vm._e()],1)]}},{key:"actions",fn:function(ref){
var pharmacy = ref.item;
return [_c('td',[_c('CButton',{staticClass:"mr-1 mb-1",attrs:{"color":"secondary","size":"sm","to":{ name: '薬局情報', params: { id: pharmacy.id } }}},[_vm._v(" 詳細 ")]),_c('CButton',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","size":"sm","to":{ name: '薬局編集', params: { id: pharmacy.id } }}},[_vm._v(" 編集 ")]),_c('CButton',{staticClass:"mr-1 mb-1",attrs:{"color":"secondary","size":"sm","href":_vm.medorder_url + '/pharmacies/' + pharmacy.id}},[_vm._v(" メドオーダー ")]),_c('CButton',{staticClass:"mr-1 mb-1",attrs:{"color":"secondary","size":"sm","to":{ name: 'NSIPS一覧（薬局）', params: { id: pharmacy.id } }}},[_vm._v(" NSIPS ")])],1)]}},{key:"ended_on",fn:function(ref){
var pharmacy = ref.item;
return [_c('td',[(_vm.isEnded(pharmacy.ended_on))?_c('CBadge',{staticStyle:{"font-size":"100%","padding":"0.25rem 0.5rem"},attrs:{"color":"danger"}},[_vm._v(" 退会済み ")]):_vm._e()],1)]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.pharmacies.length),expression:"pharmacies.length"}],attrs:{"align":"center","responsive":"","active-page":_vm.current_page,"pages":_vm.total_pages,"limit":10},on:{"update:activePage":function($event){_vm.current_page=$event},"update:active-page":function($event){_vm.current_page=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }