




import { defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  name: "FromNowSpan",
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    let dateLuxon: DateTime;
    if (typeof props.date === "string") {
      dateLuxon = DateTime.fromISO(props.date);
    } else if (props.date.toString().length === 10) {
      dateLuxon = DateTime.fromSeconds(props.date);
    } else {
      dateLuxon = DateTime.fromMillis(props.date);
    }

    function display() {
      if (dateLuxon.diffNow().milliseconds > -23 * 60 * 60 * 1000) {
        return dateLuxon.toLocaleString(DateTime.TIME_24_WITH_SECONDS); // 13:37:58
      } else {
        return dateLuxon.toLocaleString(DateTime.DATE_SHORT); // 2021/2/25
      }
    }

    function tooltip() {
      return dateLuxon.toLocaleString(DateTime.DATETIME_FULL); // 2021年2月25日 0:37 JST
    }

    return {
      dateLuxon,
      display,
      tooltip,
    };
  },
});
