import { RootState } from "@/store";
import { Module } from "vuex";
import { UIMasterEthicalMedicineStore } from "./ethicalMedicine";

export type UIMasterStoreState = {};

export const UIMasterStoreState: Module<UIMasterStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    ethicalMedicine: UIMasterEthicalMedicineStore,
  },
};
