




















import { defineComponent } from "@vue/composition-api";
import BigQueries from "@/views/v2/core/embulkManager/BigQueries.vue";

export default defineComponent({
  name: "EmbulkManager",
  components: { BigQueries },
});
