


































import { defineComponent } from "@vue/composition-api";

import { MedisBaseRow } from "@/store/medisBaseRowsStore";

interface Props {
  medisBaseRows: MedisBaseRow[];
  loading: boolean;
}

export default defineComponent<Props>({
  name: "MedisBaseRowsDataTable",
  props: {
    medisBaseRows: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const fields = [
      { key: "id", label: "ID" },
      { key: "hotCode", label: "基準番号(HOTコード)" },
      { key: "janCode", label: "JANコード" },
      { key: "mhlwCode", label: "薬価基準収載医薬品コード" },
      { key: "yjCode", label: "個別医薬品コード" },
      { key: "receiptCode", label: "レセプト電算処理システムコード(1)" },
      { key: "receiptCodeSecondary", label: "レセプト電算処理システムコード(2)" },
      { key: "officialName", label: "告示名称" },
      { key: "brandName", label: "販売名" },
      { key: "receiptSystemName", label: "レセプト電算処理システム医薬品名" },
      { key: "standard", label: "規格単位" },
      { key: "packetForm", label: "包装形態" },
      { key: "packetVolume", label: "包装単位数" },
      { key: "packetUnit", label: "包装単位単位" },
      { key: "packageVolume", label: "包装総量数" },
      { key: "packageUnit", label: "包装総量単位" },
      { key: "dosageType", label: "区分" },
      { key: "maker", label: "製造会社" },
      { key: "seller", label: "販売会社" },
      { key: "registerType", label: "レコード区分" },
      { key: "registeredOn", label: "更新年月日" },
    ];

    return {
      fields,
    };
  },
});
