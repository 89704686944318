







































































import { defineComponent, ref } from "@vue/composition-api";

import { OrderableItemFixturesPatchParams, useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";

export default defineComponent({
  name: "OrderableItemFixtureEdit",
  setup(props, context) {
    const orderableItemFixtureId = parseInt(context.root.$route.params.id, 10);

    const orderableItemFixturesStore = useOrderableItemFixturesStore();

    const inputName = ref<string>(null);
    const inputNameAppend = ref<string>(null);
    const inputGs1Code = ref<string>(null);
    const inputJanCode = ref<string>(null);
    const inputStockableItemFixtureId = ref<string>(null);
    const inputQuantity = ref<string>(null);
    const inputMakerName = ref<string>(null);
    const inputSellerName = ref<string>(null);
    const inputPacketForm = ref<string>(null);
    const inputPacketVolume = ref<string>(null);
    const inputPackagePacketCount = ref<string>(null);
    const inputInactiveOn = ref<string>(null);
    const inputHotCode = ref<string>(null);
    const textareaNote = ref<string>(null);

    (async () => {
      await orderableItemFixturesStore.fetch(orderableItemFixtureId);
      const orderableItemFixture = orderableItemFixturesStore.get(orderableItemFixtureId);
      if (!orderableItemFixture) {
        return;
      }
      inputName.value = orderableItemFixture.name;
      inputNameAppend.value = orderableItemFixture.nameAppend;
      inputGs1Code.value = orderableItemFixture.gs1Code;
      inputJanCode.value = orderableItemFixture.janCode;
      inputStockableItemFixtureId.value = orderableItemFixture.stockableItemFixtureId
        ? String(orderableItemFixture.stockableItemFixtureId)
        : null;
      inputQuantity.value = orderableItemFixture.quantity ? String(orderableItemFixture.quantity) : null;
      inputMakerName.value = orderableItemFixture.makerName;
      inputSellerName.value = orderableItemFixture.sellerName;
      inputPacketForm.value = orderableItemFixture.packetForm;
      inputPacketVolume.value = orderableItemFixture.packetVolume ? String(orderableItemFixture.packetVolume) : null;
      inputPackagePacketCount.value = orderableItemFixture.packagePacketCount ? String(orderableItemFixture.packagePacketCount) : null;
      inputInactiveOn.value = orderableItemFixture.inactiveOn ? orderableItemFixture.inactiveOn.toFormat("yyyy-MM-dd") : null;
      inputHotCode.value = orderableItemFixture.hotCode;
      textareaNote.value = orderableItemFixture.note;
    })();

    async function update() {
      const orderableItemFixture = orderableItemFixturesStore.get(orderableItemFixtureId);
      if (!orderableItemFixture) {
        return;
      }
      const params: OrderableItemFixturesPatchParams = {
        id: orderableItemFixtureId,
      };
      if (inputName.value !== orderableItemFixture.name) {
        params.name = inputName.value || null;
      }
      if (inputNameAppend.value !== orderableItemFixture.nameAppend) {
        params.name = inputName.value || null;
      }
      if (inputGs1Code.value !== orderableItemFixture.gs1Code) {
        params.gs1Code = inputGs1Code.value || null;
      }
      if (inputJanCode.value !== orderableItemFixture.janCode) {
        params.janCode = inputJanCode.value || null;
      }
      const stockableItemFixtureId = inputStockableItemFixtureId.value === null ? null : parseInt(inputStockableItemFixtureId.value, 10);
      if (stockableItemFixtureId !== orderableItemFixture.stockableItemFixtureId) {
        params.stockableItemFixtureId = stockableItemFixtureId;
      }
      const quantity = inputQuantity.value === null ? null : parseInt(inputQuantity.value, 10);
      if (quantity !== orderableItemFixture.quantity) {
        params.quantity = quantity;
      }
      if (inputMakerName.value !== orderableItemFixture.makerName) {
        params.makerName = inputMakerName.value || null;
      }
      if (inputSellerName.value !== orderableItemFixture.sellerName) {
        params.sellerName = inputSellerName.value || null;
      }
      if (inputPacketForm.value !== orderableItemFixture.packetForm) {
        params.packetForm = inputPacketForm.value || null;
      }
      const packetVolume = inputPacketVolume.value === null ? null : parseInt(inputPacketVolume.value, 10);
      if (packetVolume !== orderableItemFixture.packetVolume) {
        params.packetVolume = packetVolume;
      }
      const packagePacketCount = inputPackagePacketCount.value === null ? null : parseInt(inputPackagePacketCount.value, 10);
      if (packagePacketCount !== orderableItemFixture.packagePacketCount) {
        params.packagePacketCount = packagePacketCount;
      }
      if (inputInactiveOn.value !== orderableItemFixture.inactiveOn) {
        params.inactiveOn = inputInactiveOn.value || null;
      }
      if (inputHotCode.value !== orderableItemFixture.hotCode) {
        params.hotCode = inputHotCode.value || null;
      }
      if (textareaNote.value !== orderableItemFixture.note) {
        params.note = textareaNote.value || null;
      }
      await orderableItemFixturesStore.patch(params);
      context.root.$router.push({ name: "発注医薬品マスター手動登録" });
    }

    return {
      inputName,
      inputNameAppend,
      inputGs1Code,
      inputJanCode,
      inputStockableItemFixtureId,
      inputQuantity,
      inputMakerName,
      inputSellerName,
      inputPacketForm,
      inputPacketVolume,
      inputPackagePacketCount,
      inputInactiveOn,
      inputHotCode,
      textareaNote,
      update,
    };
  },
});
