export class ManualCategory {
  id!: number;
  name!: string;
  depth!: number;
  has_child!: boolean;
  open!: boolean;
  ancestors!: ManualCategory[];
  children!: ManualCategory[];
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  public label() {
    if (this.depth > 0) {
      return "　".repeat(this.depth) + "└ " + this.name;
    } else {
      return this.name;
    }
  }

  public breadcrumbs() {
    const ancestors = this.ancestors.map((x: object) => new ManualCategory(x).breadcrumb());
    const breadcrumbs = [...ancestors, { text: "マニュアル", to: { name: "マニュアル一覧" } }].reverse();
    if (this.name) breadcrumbs.push(this.breadcrumb());
    return breadcrumbs;
  }

  public breadcrumb() {
    return {
      text: this.name,
      to: { name: "マニュアル一覧", query: { categoryId: this.id.toString() } },
    };
  }
}
