import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface MedisBaseRow {
  id: number;
  hotCode: string;
  yjCode: string | null;
  receiptCode: string | null;
  registerdOn: DateTime;
}

interface MedisBaseRowsFindListParams {
  medisBaseId?: number;
  hotCode?: string;
  receiptCode?: string;
  page?: number;
}

interface MedisBaseRowsFindListRequestParams {
  medis_base_id?: number;
  hot_code?: string;
  receipt_code?: string;
  page?: number;
}

function createMedisBaseRowsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisBaseRow>(),
    ...initStoreActionFindListState<MedisBaseRow>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_base_rows`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisBaseRow, MedisBaseRowsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_base_rows`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisBaseRowsFindListRequestParams = {};
        if (params.medisBaseId) {
          requestParams.medis_base_id = params.medisBaseId;
        }
        if (params.hotCode) {
          requestParams.hot_code = params.hotCode;
        }
        if (params.receiptCode) {
          requestParams.receipt_code = params.receiptCode;
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisBaseRowsStore, "medisBaseRowsStore");

export const provideMedisBaseRowsStore = provider.provideStore;
export const useMedisBaseRowsStore = provider.useStore;

export function useMedisBaseRow(medisBaseRowId: number, withFetch?: boolean, noCache?: boolean) {
  const medisBaseRowsStore = useMedisBaseRowsStore();
  if (withFetch) {
    medisBaseRowsStore.fetch(medisBaseRowId, noCache);
  }
  let medisBaseRow: MedisBaseRow | null = null;

  return computed(() => {
    if (medisBaseRow && !noCache) {
      return medisBaseRow;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisBaseRowsStore.isFetching.value;
    medisBaseRow = medisBaseRowsStore.get(medisBaseRowId);
    return medisBaseRow;
  });
}
