import { DateTime } from "luxon";
import { isArrayOrObject } from "@/utils";

const TO_CAMEL_CASE_REGEX = /_(.)/g;

function toCamelCaseReplacer(match: string, group: string) {
  return group.charAt(0).toUpperCase();
}

function toCamelCase(str: string) {
  return str.replace(TO_CAMEL_CASE_REGEX, toCamelCaseReplacer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function toStoreItems(obj: any | any[]): any | any[] {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(toStoreItems);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};
  Object.keys(obj).forEach((name) => {
    let value = obj[name];
    const cName = toCamelCase(name);

    if (value) {
      if (isArrayOrObject(value)) {
        value = toStoreItems(value);
      }

      // 末尾が At または On の場合は DateTime 型に変換する。
      const cNameLast2 = cName.substr(-2, 2);
      if (cNameLast2 === "At" || cNameLast2 === "On") {
        value = DateTime.fromISO(value);
      }

      if (cName === "quantity" || cName === "orderPoint") {
        value = parseFloat(value);
      }
      const cNameLast8 = cName.substr(-8, 8);
      if (cNameLast8 === "Quantity") {
        value = parseFloat(value);
      }
    }

    newObj[cName] = value;
  });
  return newObj;
}
