<template>
  <MenuLink :menus="['コア']" />
</template>

<script>
import MenuLink from "src/components/shared/menu/MenuLink";

export default {
  name: "Core",
  components: { MenuLink },
};
</script>
