<template>
  <div>
    <SearchPharmacy :selected.sync="selectedPharmacy" />

    <CCard v-if="selectedPharmacyId">
      <CCardHeader>{{ countForDisplay }} 件のNSIPSを表示</CCardHeader>
      <CCardBody>
        <CForm @submit.prevent="searchNsipses">
          <CInput v-model="query" placeholder="ファイル名で検索（前方一致）">
            <template #append><CButton type="submit" color="info">検索</CButton></template>
          </CInput>
        </CForm>
        <CDataTable hover bordered fixed responsive :loading="isLoading" :fields="fields" :items="nsipses">
          <template #last_modified="{ item : nsips }">
            <td>{{ displayIsoDate(nsips.last_modified) }}</td>
          </template>
          <template #actions="{ item : nsips }">
            <td>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" :to="{ name: 'NSIPS詳細', params: { filename: nsips.filename } }">
                詳細
              </CButton>
              <CButton color="secondary" class="mr-1 mb-1" size="sm" @click="openNsipsModal(nsips)">
                ダイアログ
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CButton v-show="next_continuation_token" color="secondary" block @click="loadNextPage">もっと読み込む</CButton>
      </CCardBody>
    </CCard>

    <CModal title="NSIPSファイル" size="lg" :show.sync="nsipsModal">
      <NsipsCard :filename.sync="selectingFileName" :pharmacy-id.sync="selectedPharmacyId" />
    </CModal>
  </div>
</template>

<script>
import SearchPharmacy from "src/components/shared/search/SearchPharmacy";
import NsipsCard from "@/views/v2/core/nsipses/NsipsCard";
import { DateTime } from "luxon";

export default {
  name: "Nsipses",
  components: { NsipsCard, SearchPharmacy },
  data() {
    return {
      isLoading: false,
      selectedPharmacy: null,
      selectedPharmacyId: null,
      query: null,
      nsipses: [],
      selectingNsips: {},
      nsipsModal: false,
      fields: ["key", "size", "last_modified", "actions"],
      next_continuation_token: null,
      total_count: 0,
    };
  },
  computed: {
    countForDisplay() {
      return this.next_continuation_token ? `${this.total_count} + N` : this.total_count;
    },
    selectingFileName() {
      return this.selectingNsips ? this.selectingNsips.filename : null;
    },
  },
  watch: {
    async selectedPharmacy() {
      if (this.selectedPharmacy && this.selectedPharmacy.id) {
        this.selectedPharmacyId = this.selectedPharmacy.id;
        await this.$router.push({ name: "NSIPS一覧（薬局）", params: { id: this.selectedPharmacyId } });
      }
      this.resetNsipsesData();
      this.query = null;
      await this.fetchNsipses();
    },
  },
  async created() {
    this.selectedPharmacyId = this.$route.params.id;
    await this.fetchNsipses();
  },
  methods: {
    async fetchNsipses() {
      if (this.isLoading) return;
      if (!this.selectedPharmacyId) return;

      this.isLoading = true;
      const req_params = { query: this.query, next_continuation_token: this.next_continuation_token };
      const url = `/api/v2/core/admin/pharmacies/${this.selectedPharmacyId}/s3_nsipses`;
      const response = await this.$axios.get(url, { params: req_params });

      this.nsipses = this.nsipses.concat(response.data.nsipses);
      this.total_count += response.data.length;
      this.next_continuation_token = response.data.next_continuation_token;
      this.isLoading = false;
    },
    searchNsipses() {
      this.resetNsipsesData();
      this.fetchNsipses();
    },
    async loadNextPage() {
      const y = window.scrollY;
      if (this.next_continuation_token) await this.fetchNsipses();
      window.scrollTo({ top: y });
    },
    resetNsipsesData() {
      this.nsipses = [];
      this.total_count = 0;
      this.next_continuation_token = null;
    },
    openNsipsModal(nsips) {
      this.selectingNsips = nsips;
      this.nsipsModal = true;
    },
    displayIsoDate(date) {
      return DateTime.fromISO(date).toISO();
    },
  },
};
</script>
