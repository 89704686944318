import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { DateTime } from "luxon";

export interface MedisGs1 {
  id: number;
  fileName: string;
  fileDate: string;
}

interface MedisGs1sFindListParams {
  fromDate?: DateTime;
  page?: number;
}

interface MedisGs1sFindListRequestParams {
  fromDate?: DateTime;
  page?: number;
}

function createMedisGs1sStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisGs1>(),
    ...initStoreActionFindListState<MedisGs1>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_gs1s`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisGs1, MedisGs1sFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_gs1s`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisGs1sFindListRequestParams = {};
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisGs1sStore, "medisGs1sStore");

export const provideMedisGs1sStore = provider.provideStore;
export const useMedisGs1sStore = provider.useStore;

export function useMedisGs1(medisGs1Id: number, withFetch?: boolean, noCache?: boolean) {
  const medisGs1sStore = useMedisGs1sStore();
  if (withFetch) {
    medisGs1sStore.fetch(medisGs1Id, noCache);
  }
  let medisGs1: MedisGs1 | null = null;

  return computed(() => {
    if (medisGs1 && !noCache) {
      return medisGs1;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisGs1sStore.isFetching.value;
    medisGs1 = medisGs1sStore.get(medisGs1Id);
    return medisGs1;
  });
}
