import { jsonConvertKeyCamelToSnake, jsonConvertKeySnakeToCamel } from "@/utility/jsonConvertKey";
import { AxiosInstance } from "axios";
import { Interceptor } from "./interceptor";

export const convertCaseInterceptor: Interceptor = {
  apply: (axios: AxiosInstance) => {
    axios.interceptors.request.use((request) => {
      if (request.params) {
        jsonConvertKeyCamelToSnake(request.params);
      }

      return request;
    });
    axios.interceptors.response.use((response) => {
      if (response.data) {
        jsonConvertKeySnakeToCamel(response.data);
      }

      return response;
    });
  },
};
