import {
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibGooglePay,
  cibStripe,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilBell,
  cilBuilding,
  cilCalendar,
  cilChatBubble,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCommentSquare,
  cilDollar,
  cilEnvelopeOpen,
  cilEyedropper,
  cilFile,
  cilFilter,
  cilFolder,
  cilHouse,
  cilInfo,
  cilList,
  cilLockLocked,
  cilLoopCircular,
  cilNotes,
  cilPencil,
  cilPeople,
  cilPlus,
  cilSettings,
  cilShieldAlt,
  cilTask,
  cilTrash,
  cilUser,
  cilXCircle,
  cilYen,
} from "@coreui/icons";

export const iconsSet = Object.assign(
  {},
  {
    cilBell,
    cilBuilding,
    cilCalendar,
    cilChatBubble,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCommentSquare,
    cilDollar,
    cilEnvelopeOpen,
    cilEyedropper,
    cilFile,
    cilFilter,
    cilFolder,
    cilHouse,
    cilInfo,
    cilList,
    cilLockLocked,
    cilLoopCircular,
    cilNotes,
    cilPencil,
    cilPeople,
    cilPlus,
    cilSettings,
    cilShieldAlt,
    cilTask,
    cilTrash,
    cilUser,
    cilXCircle,
    cilYen,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibCcAmex,
    cibCcMastercard,
    cibCcVisa,
    cibGooglePay,
    cibStripe,
  }
);
