import { MessageFile } from "vue-advanced-chat";

export class ChatFileClass implements MessageFile {
  file_name_s3?: string;
  name!: string; // "My File"
  size?: number; // 67351
  type!: string; // "png"
  file_type?: string; // "image/png"
  extension?: string; // "png"
  audio?: boolean;
  duration?: number; // 14.4
  url!: string;
  localUrl?: string;
  blob?: Blob;

  static create(file: ChatFileClass): ChatFileClass {
    file.type = "." + file.extension;
    return file;
  }
}
