export default function envNameJp(env: string): string {
  switch (env) {
    case "demo":
      return "デモ環境";
    case "production":
      return "本番環境";
    default:
      return "不明";
  }
}
