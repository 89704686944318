<template>
  <div>
    <h1>在庫数修正</h1>

    <CInput v-model="pharmacyId" label="薬局ID" placeholder="Enter your name" />
    <CButton color="primary" class="mr-1" :disabled="!pharmacyId" @click="openModal">実行ダイアログを開く</CButton>
    <hr />
    <h2>薬局ID チートシート</h2>
    <CDataTable hover bordered fixed :items="pharmacies" :fields="pharmaciesFields" />

    <CModal title="在庫数修正" size="lg" :show.sync="executeModal">
      <div v-if="!isStockLoaded">
        <v-icon name="sync" spin />
        在庫薬をリストアップ中（{{ stockYjCodes.length }}件）
      </div>
      <div v-else>在庫医薬品数：{{ stockYjCodes.length }} 件</div>
      <div v-if="shouldShowProgress">
        <CProgressBar :value="executingFixIndex" :max="stockYjCodes.length" color="primary" show-value animated />
      </div>
      <template #footer>
        <CButton color="danger" :disabled="!isStockLoaded" @click="executeFix">実行する</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

export default {
  name: "FixStocks",
  data() {
    return {
      executeModal: false,
      pharmacyId: null,
      pharmacies: [{ env: "production", name: "かすみ薬局", id: "eec06bac-f565-40ab-9030-c5855071217e" }],
      pharmaciesFields: ["env", "name", "id"],
      stockYjCodes: [],
      isStockLoaded: false,
      isExecutingFix: false,
      executingFixIndex: 0,
    };
  },
  computed: {
    shouldShowProgress() {
      return this.isStockLoaded && this.isExecutingFix;
    },
  },
  created() {
    // まずは、かすみ薬局のIDを初期値で設定。
    // いずれは薬局を検索するUIにしたい。
    this.pharmacyId = "eec06bac-f565-40ab-9030-c5855071217e";
  },
  methods: {
    async openModal() {
      this.executeModal = true;
      this.listAllStocks();
    },
    async listAllStocks() {
      this.isStockLoaded = false;
      this.stockYjCodes = []; // reset list
      let nextPageToken = null;

      while (nextPageToken !== undefined) {
        const req_params = { pharmacyId: this.pharmacyId, pageToken: nextPageToken };
        const response = await this.$axios.get("/api/v1/medorder/stocks", { params: req_params });
        const stocks = response.data["stocks"];
        nextPageToken = response.data["nextPageToken"];
        this.stockYjCodes = this.stockYjCodes.concat(stocks.map((stock) => stock.yjCode));
        if (nextPageToken === undefined) {
          this.isStockLoaded = true;
        }
      }
    },
    async executeFix() {
      this.executingFixIndex = 0;
      this.isExecutingFix = true;
      let errorYjCodes = [];
      for (const yjCode of this.stockYjCodes) {
        try {
          await sleep(1000);
          const req_params = {
            pharmacyId: this.pharmacyId,
            yjCode: yjCode,
          };
          await this.$axios.post(`/api/v1/medorder/stocks/${yjCode}/fix`, req_params);
          this.executingFixIndex += 1;
        } catch (error) {
          errorYjCodes = errorYjCodes.concat(yjCode);
          await sleep(10000);
        }
      }
      throw errorYjCodes;
    },
  },
};
</script>
