import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface MedisGs1Row {
  id: number;
  hotCode: string;
  packageGs1Code: string;
  fileDate: DateTime;
}

interface MedisGs1RowsFindListParams {
  medisGs1Id?: number;
  hotCode?: string;
  hotCodes?: readonly string[];
  page?: number;
}

interface MedisGs1RowsFindListRequestParams {
  medis_gs1_id?: number;
  hot_code?: string;
  hot_codes?: string;
  page?: number;
}

function createMedisGs1RowsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<MedisGs1Row>(),
    ...initStoreActionFindListState<MedisGs1Row>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/medis_gs1_rows`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<MedisGs1Row, MedisGs1RowsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/medis_gs1_rows`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: MedisGs1RowsFindListRequestParams = {};
        if (params.medisGs1Id) {
          requestParams.medis_gs1_id = params.medisGs1Id;
        }
        if (params.hotCode) {
          requestParams.hot_code = params.hotCode;
        }
        if (params.hotCodes) {
          requestParams.hot_codes = params.hotCodes.join(",");
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createMedisGs1RowsStore, "medisGs1RowsStore");

export const provideMedisGs1RowsStore = provider.provideStore;
export const useMedisGs1RowsStore = provider.useStore;

export function useMedisBaseRow(medisGs1RowId: number, withFetch?: boolean, noCache?: boolean) {
  const medisGs1RowsStore = useMedisGs1RowsStore();
  if (withFetch) {
    medisGs1RowsStore.fetch(medisGs1RowId, noCache);
  }
  let medisGs1Row: MedisGs1Row | null = null;

  return computed(() => {
    if (medisGs1Row && !noCache) {
      return medisGs1Row;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    medisGs1RowsStore.isFetching.value;
    medisGs1Row = medisGs1RowsStore.get(medisGs1RowId);
    return medisGs1Row;
  });
}
