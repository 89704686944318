

























import { ref, defineComponent, watch } from "@vue/composition-api";

import { useMedisGs1sStore } from "@/store/medisGs1sStore";

export default defineComponent({
  name: "MedisGs1s",
  setup() {
    const medisGs1sStore = useMedisGs1sStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "fileName", label: "ファイル名" },
      { key: "fileDate", label: "ファイル年月日" },
    ];

    async function fetchMedisGs1s(page: number) {
      medisGs1sStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchMedisGs1s(page);
    });

    return {
      fields,
      medisGs1s: medisGs1sStore.list,
      isFindingList: medisGs1sStore.isFindingList,
      totalCount: medisGs1sStore.totalCount,
      totalPages: medisGs1sStore.totalPages,
      currentPage,
    };
  },
});
