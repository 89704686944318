export default class PromiseObserver {
  private resolves: (() => void)[] = [];
  private processingCount = 0;

  public createWaitPromise() {
    return new Promise((resolve) => {
      this.resolves.push(resolve);
    });
  }

  public notify() {
    const resolve = this.resolves.shift();
    if (!resolve) {
      return;
    }
    resolve();
  }

  public notifyAll() {
    for (const resolve of this.resolves) {
      resolve();
    }
    this.resolves = [];
  }

  // 実行中の処理がある場合は null を返し、
  // 実行中の処理がある場合は前の処理が終わるまで wait する Promise を返す
  // startProcess() を呼んだ場合は必ず endProcess() を呼ぶこと。
  public startProcess() {
    ++this.processingCount;
    if (this.processingCount === 1) {
      return null;
    }
    return this.createWaitPromise();
  }

  public endProcess() {
    --this.processingCount;
    if (this.processingCount === 0) {
      return;
    }
    this.notify();
  }
}
