







































































import { defineComponent, reactive } from "@vue/composition-api";

import { OrderableItemFixturesCreateParams, useOrderableItemFixturesStore } from "@/store/orderableItemFixturesStore";

export default defineComponent({
  name: "OrderableItemImportRequests",
  setup(props, context) {
    const orderableItemFixturesStore = useOrderableItemFixturesStore();

    const params = reactive<OrderableItemFixturesCreateParams>({});
    if (context.root.$route.query.gs1Code) {
      params.gs1Code = context.root.$route.query.gs1Code as string;
    }
    if (context.root.$route.query.hotCode) {
      params.hotCode = context.root.$route.query.hotCode as string;
    }

    async function create() {
      await orderableItemFixturesStore.create(params);
      context.root.$router.push({ name: "発注医薬品マスター手動登録" });
    }

    return {
      params,
      create,
    };
  },
});
