<template>
  <div>
    <CTabs :active-tab="activeTab">
      <CTab title="薬局ユーザ" :to="{ name: '薬局ユーザ新規作成' }">
        <router-view />
      </CTab>
      <CTab title="管理者ユーザ" :to="{ name: '管理者ユーザ新規作成' }">
        <router-view />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
export default {
  name: "CreateEditUserTabs",
  computed: {
    activeTab() {
      return ["薬局ユーザ新規作成", "管理者ユーザ新規作成"].indexOf(this.$route.name);
    },
  },
};
</script>
