import Vue from "vue";
import { SetupContext } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider } from "@/store/utils";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface IryohokenRow {
  id: number;
  receiptCode: string;
  registeredOn: DateTime;
}

interface IryohokenRowsFindListParams {
  iryohokenId?: number;
  receiptCode?: string;
  page?: number;
}

interface IryohokenRowsFindListRequestParams {
  iryohoken_id?: number;
  receipt_code?: string;
  page?: number;
}

function createIryohokenRowsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<IryohokenRow>(),
    ...initStoreActionFindListState<IryohokenRow>(),
  });

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return `/api/v2/admin/iryohoken_rows`;
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFind = createActionFindList<IryohokenRow, IryohokenRowsFindListParams>(
    state,
    context.root.$httpCore,
    () => {
      return `/api/v2/admin/iryohoken_rows`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: IryohokenRowsFindListRequestParams = {};
        if (params.iryohokenId) {
          requestParams.iryohoken_id = params.iryohokenId;
        }
        if (params.receiptCode) {
          requestParams.receipt_code = params.receiptCode;
        }
        if (params.page) {
          requestParams.page = params.page;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    ...actionFind,
  };
}

const provider = createStoreProvider(createIryohokenRowsStore, "iryohokenRowsStore");

export const provideIryohokenRowsStore = provider.provideStore;
export const useIryohokenRowsStore = provider.useStore;
