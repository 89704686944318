import Vue from "vue";
import { SetupContext, computed } from "@vue/composition-api";

import { DateTime } from "luxon";

import { createStoreProvider, collectIds } from "@/store/utils";
import { fetchBase } from "@/store/actions/createActionFetchBase";
import { initStoreActionFetchItemState, ActionFetchItemBuilder } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";

export interface OrderableItem {
  id: number;
  stockableItemId: number;
  name: string;
  gs1Code: string;
  janCode: string;
  quantity: number;
  makerName: string;
  sellerName: string;
  packetForm: string;
  packetVolume: number;
  packagePacketCount: number;
  inactiveOn?: DateTime;
  order: number;
}

export interface StockableItem {
  id: number;
  name: string;
  price: number;
  unit: string;
  yjCode: string;
  interimPeriodOn: Date | null;
  orderableItems: OrderableItem[];
  isRegular?: boolean;
}

export interface StockableItemsFindListParams {
  query: string;
  pharmacyId?: number;
  existsStock?: boolean;
}

export interface StockableItemsFindListRequstParams {
  query: string;
  pharmacy_id?: number;
  exists_stock?: boolean;
}

export interface ItemHavingStockableItemId {
  stockableItemId: number;
}

interface StoreActionFetchByYjCodeRequestParams {
  paramIds: string;
}

export function compareOrderableItem(a: OrderableItem, b: OrderableItem) {
  if (a.inactiveOn !== b.inactiveOn) {
    if (!b.inactiveOn) {
      return 1;
    }
    if (!a.inactiveOn) {
      return -1;
    }
    return b.inactiveOn.toMillis() - a.inactiveOn.toMillis();
  }
  if (a.sellerName !== b.sellerName) {
    return a.sellerName > b.sellerName ? 1 : -1;
  }
  if (a.packetForm !== b.packetForm) {
    if (a.packetForm === "バラ") {
      return 1;
    }
    if (b.packetForm === "バラ") {
      return -1;
    }
    return a.packetForm > b.packetForm ? 1 : -1;
  }
  if (a.packetVolume !== b.packetVolume) {
    return a.packetVolume - b.packetVolume;
  }
  if (a.packagePacketCount !== b.packagePacketCount) {
    return a.packagePacketCount - b.packagePacketCount;
  }
  if (a.name !== b.name) {
    return a.name > b.name ? 1 : -1;
  }
  return a.id - b.id;
}

function createStockableItemsStore(context: SetupContext) {
  const state = Vue.observable({
    ...initStoreActionFetchItemState<StockableItem>(),
    ...initStoreActionFindListState<StockableItem>(),
    yjCodeItemMap: new Map<string, StockableItem>(),
    gs1CodeItemMap: new Map<string, StockableItem>(),
    janCodeItemMap: new Map<string, StockableItem>(),
  });

  async function afterReciveItems(items: StockableItem[]) {
    items.map((item) => {
      item.orderableItems.sort(compareOrderableItem);
      for (let i = 0; i < item.orderableItems.length; ++i) {
        item.orderableItems[i].order = i;
      }
    });
  }

  const actionFetchBuilder = new ActionFetchItemBuilder(state, context.root.$httpMedorder, () => {
    return "/api/v2/master/stockable_items";
  });
  actionFetchBuilder.addAfterReceiveItems(afterReciveItems);
  const actionFetch = actionFetchBuilder.build();

  // stockableItemId をもつオブジェクトのリストを受け取って、必要な StockableItem を fetch する。
  async function fetchByItemHavingStockableItemId<T extends ItemHavingStockableItemId>(list: T[]) {
    const stockableItemIds = collectIds(list, (item) => {
      return item.stockableItemId;
    });
    await actionFetch.fetch(stockableItemIds);
  }

  async function fetchByYjCode(yjCodes: string | string[], force?: boolean) {
    return fetchBase(
      yjCodes,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/master/stockable_items";
      },
      "yj_codes",
      () => {
        return state.yjCodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          map.set(item.yjCode, item);
          state.itemMap.set(item.id, item);
        }
      },
      afterReciveItems,
      null
    );
  }

  function getByYjCode(yjCode: string) {
    return state.yjCodeItemMap.get(yjCode) || null;
  }

  async function fetchByGs1Code(gs1Code: string | string[], force?: boolean) {
    return fetchBase(
      gs1Code,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/master/stockable_items";
      },
      "gs1_codes",
      () => {
        return state.gs1CodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          for (const orderableItem of item.orderableItems) {
            map.set(orderableItem.gs1Code, item);
          }
          state.itemMap.set(item.id, item);
        }
      },
      afterReciveItems,
      null
    );
  }

  function getByGs1Code(gs1Code: string) {
    return state.gs1CodeItemMap.get(gs1Code) || null;
  }

  async function fetchByJanCode(janCode: string | string[], force?: boolean) {
    return fetchBase<StockableItem, string, StockableItem>(
      janCode,
      force || false,
      state,
      context.root.$httpMedorder,
      () => {
        return "/api/v2/master/stockable_items";
      },
      "jan_codes",
      () => {
        return state.janCodeItemMap;
      },
      (map, items) => {
        for (const item of items) {
          for (const orderableItem of item.orderableItems) {
            map.set(orderableItem.janCode, item);
          }
          state.itemMap.set(item.id, item);
        }
      },
      afterReciveItems,
      null
    );
  }
  function getByJanCode(janCode: string) {
    return state.janCodeItemMap.get(janCode) || null;
  }

  const actionFindList = createActionFindList<StockableItem, StockableItemsFindListParams>(
    state,
    context.root.$httpMedorder,
    () => {
      return `/api/v2/master/stockable_items`;
    },
    {
      createRequestParams: (params) => {
        const requestParams: StockableItemsFindListRequstParams = {
          query: params.query,
        };
        if (params.pharmacyId) {
          requestParams.pharmacy_id = params.pharmacyId;
        }
        if (params.existsStock) {
          requestParams.exists_stock = params.existsStock;
        }
        return requestParams;
      },
    }
  );

  return {
    ...actionFetch,
    fetchByYjCode,
    getByYjCode,
    fetchByGs1Code,
    getByGs1Code,
    fetchByJanCode,
    getByJanCode,
    fetchByItemHavingStockableItemId,
    ...actionFindList,
  };
}

const provider = createStoreProvider(createStockableItemsStore, "StockableItemsStore");

export const provideStockableItemsStore = provider.provideStore;
export const useStockableItemsStore = provider.useStore;

export function useStockableItem(stockableItemId: number, withFetch?: boolean) {
  const stockableItemsStore = useStockableItemsStore();

  if (withFetch) {
    stockableItemsStore.fetch([stockableItemId]);
  }

  let stockableItem: StockableItem | null = null;
  return computed(() => {
    if (stockableItem) {
      return stockableItem;
    }
    // fetch が終わったタイミングで取得し直すために isFetching.value の参照を残しておく
    stockableItemsStore.isFetching.value;
    stockableItem = stockableItemsStore.get(stockableItemId);
    return stockableItem;
  });
}

export function findOrderableItem(stockableItem: StockableItem, orderableItemId: number | null) {
  if (!orderableItemId) {
    return;
  }
  return stockableItem.orderableItems.find((item) => {
    return item.id === orderableItemId;
  });
}

export function getOrderableItemOrder(stockableItem: StockableItem, orderableItemId: number) {
  const orderableItem = stockableItem.orderableItems.find((item) => {
    return item.id === orderableItemId;
  });
  if (!orderableItem) {
    return 999;
  }
  return orderableItem.order;
}
