<template>
  <div>
    <CCard>
      <CCardBody>
        <SearchGroups :selected.sync="selectedGroup" :api-fields="apiFields" :filter="filterOutBelongingGroup" />

        <CDataTable hover bordered fixed :items="listingGroups" :fields="fields" :loading="isLoading">
          <template #name="{item : group}">
            <td>
              <CLink :to="{ name: '近隣グループ情報', params: { id: group.id } }">{{ group.name }}</CLink>
            </td>
          </template>
          <template #status="{item : group}">
            <td><span v-if="isBelongingGroup(group.id)">所属中</span></td>
          </template>
          <template #actions="{item : group}">
            <td>
              <CButton v-if="isBelongingGroup(group.id)" color="danger" class="mr-1 mb-1" size="sm" @click="leaveGroup(group.id)">
                離脱
              </CButton>
              <CButton v-else color="primary" class="mr-1 mb-1" size="sm" @click="belongGroup(group.id)">
                所属
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SearchGroups from "src/components/shared/search/SearchGroups";

export default {
  name: "PharmacyGroups",
  components: { SearchGroups },
  data() {
    return {
      id: null,
      pharmacy: {},
      listingGroups: [], // テーブル表示用の一時的なリスト
      selectedGroup: null,
      apiFields: ["id", "name", "created_at"],
      isLoading: false,
    };
  },
  computed: {
    fields() {
      return this.apiFields.concat(["status", "actions"]);
    },
    groupIds() {
      return this.pharmacy.groups ? this.pharmacy.groups.map((g) => g.id) : [];
    },
  },
  watch: {
    selectedGroup() {
      this.listingGroups = [this.selectedGroup].concat(this.listingGroups);
      this.listingGroups = this.listingGroups.filter((p, i, array) => array.map((x) => x.id).indexOf(p.id) === i);
    },
  },
  async created() {
    this.id = this.$route.params.id;
    await this.fetchPharmacy();
    this.listingGroups = this.pharmacy.groups;
  },
  methods: {
    async fetchPharmacy() {
      if (this.isLoading) return;

      this.isLoading = true;
      if (this.id) this.pharmacy = (await this.$axios.get(`/api/v2/core/admin/pharmacies/${this.id}`)).data;
      this.isLoading = false;
    },
    async belongGroup(groupId) {
      const req_params = { pharmacy_id: this.id };
      await this.$axios.post(`/api/v2/core/admin/groups/${groupId}/pharmacy`, req_params);
      await this.fetchPharmacy();
    },
    async leaveGroup(groupId) {
      const req_params = { pharmacy_id: this.id };
      await this.$axios.delete(`/api/v2/core/admin/groups/${groupId}/pharmacy`, { data: req_params });
      await this.fetchPharmacy();
    },
    isBelongingGroup(groupId) {
      return this.groupIds.includes(groupId);
    },
    filterOutBelongingGroup(group) {
      return !this.groupIds.includes(group.id);
    },
  },
};
</script>
