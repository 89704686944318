// ref. https://medium.com/js-dojo/watch-vue-route-params-query-with-composition-api-97b3c8c402e

import Vue from "vue";
import VueRouter from "vue-router";
import { computed } from "@vue/composition-api";
import { Dictionary } from "vue-router/types/router";

const routeData = Vue.observable({ params: {}, query: {} });

export function provideRouteData(router: VueRouter) {
  router.afterEach((route) => {
    routeData.params = route.params;
    routeData.query = route.query;
  });
}

export function useParams() {
  return computed<Dictionary<string | (string | null)[] | null | undefined>>(() => routeData.params);
}

export function useQuery() {
  return computed<Dictionary<string | (string | null)[] | null | undefined>>(() => routeData.query);
}
