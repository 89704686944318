<template>
  <div>
    <NsipsCard :filename.sync="filename" :pharmacy-id.sync="pharmacyId" />
  </div>
</template>

<script>
import NsipsCard from "src/views/v2/core/nsipses/NsipsCard";

export default {
  name: "NsipsDetail",
  components: { NsipsCard },
  data() {
    return {
      pharmacyId: null,
      filename: null,
    };
  },
  async created() {
    this.pharmacyId = this.$route.params.id;
    this.filename = this.$route.params.filename;
  },
};
</script>
