export class InquiryFilter {
  pharmacyId: number | undefined;
  status: string | undefined;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  public countValid(): number {
    let count = 0;
    Object.keys(this).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (this[key]) count++;
    });
    return count;
  }
}
