























import { ref, defineComponent, watch } from "@vue/composition-api";

import { useIryohokensStore } from "@/store/iryohokensStore";

export default defineComponent({
  name: "Iryohokens",
  setup() {
    const iryohokensStore = useIryohokensStore();
    const currentPage = ref<number>(1);

    const fields = [
      { key: "id", label: "ID" },
      { key: "fileName", label: "ファイル名" },
      { key: "fileDate", label: "ファイル年月日" },
    ];

    async function fetchIryohokens(page: number) {
      iryohokensStore.findList({
        page,
      });
      currentPage.value = page;
    }

    watch(currentPage, (page) => {
      fetchIryohokens(page);
    });

    return {
      fields,
      iryohokens: iryohokensStore.list,
      isFindingList: iryohokensStore.isFindingList,
      totalCount: iryohokensStore.totalCount,
      totalPages: iryohokensStore.totalPages,
      currentPage,
    };
  },
});
