import { RootState } from "@/store";
import { Module } from "vuex";
import { DomainMasterEthicalMedicineStore } from "./ethicalMedicine";

export type DomainMasterStoreState = {};

export const DomainMasterStore: Module<DomainMasterStoreState, RootState> = {
  namespaced: true,
  state: {},
  modules: {
    ethicalMedicine: DomainMasterEthicalMedicineStore,
  },
};
