var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v("発注医薬品マスター手動登録")]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","bordered":"","fixed":"","responsive":"","loading":_vm.isFindingUnknownSdcvanDeliveryDRecords,"items":_vm.rows,"fields":_vm.fields},scopedSlots:_vm._u([{key:"orderableItem",fn:function(ref){
var row = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[(row.stockableItem)?[_vm._v(" "+_vm._s(row.stockableItem.name)+" "+_vm._s(row.orderableItem.name)+" ")]:_c('CBadge',{attrs:{"color":"secondary"}},[_vm._v("不明")])],2)]}},{key:"name",fn:function(ref){
var row = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(row.name || "null"))])]}},{key:"notFoundAt",fn:function(ref){
var row = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(row.notFoundAt.toFormat("yyyy年M月d日 H時m分s秒"))+" ")])]}},{key:"ignored",fn:function(ref){
var row = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[(row.ignored)?_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.toggleIgnore(row)}}},[_vm._v("無視中")]):_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.toggleIgnore(row)}}},[_vm._v("無視する")])],1)]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.rows.length),expression:"rows.length"}],attrs:{"align":"center","responsive":"","active-page":_vm.currentPage,"pages":_vm.totalPages,"limit":10},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }